import React, {useState, useEffect, CSSProperties, useCallback } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './MyClients.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Tooltip, Popover} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const ClientReviews = () =>{

const [reviews, setReviews] = useState([]); 
const [offset, setOffset] = useState(0);  
const [taskLoader, setTaskLoader] = useState(false); 
const [basicLoader, setBasicLoader] = useState(false);
const [hasMore, setHasMore] = useState(true);

const fetchReviews = async () => {
    if (!hasMore || basicLoader) return;  
    setBasicLoader(true);
    const sp_unique = localStorage.getItem('sp_unique');
    try {
        const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/62_fetchReviews.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sp_unique: sp_unique, offset }) 
        });

        const data = await response.json();

        if (data.success == 1) {
            setReviews(prevReviews => [...prevReviews, ...data.data]); 
            setOffset(data.next_offset ?? offset);  
            setHasMore(data.next_offset !== null);  
        } else {
            setHasMore(false); 
        }
    } catch (error) {
        console.error("Error fetching reviews:", error);
    } finally {
        setBasicLoader(false);
    }
};


useEffect(() => {
    fetchReviews();
}, []);

const StarRating = ({ rating }) => {
        const maxStars = 5;
        const fullStars = Math.floor(rating); // Full stars
        const hasHalfStar = rating % 1 !== 0; // Half star condition
        const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars
    
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {/* Render Full Stars */}
                {[...Array(fullStars)].map((_, index) => (
                    <MdStar key={index} style={{ color: '#FFD700', fontSize: 20 }} />
                ))}
    
                {/* Render Half Star if applicable */}
                {hasHalfStar && <MdStarHalf style={{ color: '#FFD700', fontSize: 20 }} />}
    
                {/* Render Empty Stars */}
                {[...Array(emptyStars)].map((_, index) => (
                    <MdStarOutline key={index} style={{ color: '#FFD700', fontSize: 20 }} />
                ))}
            </div>
        );
    };

    const formatDate = (dateString) => {
                if (!dateString) return "Invalid date"; // Handle empty input
            
                const inputDate = moment(dateString, "YYYY-MM-DD HH:mm:ss", true); // Parse correctly with strict mode
                const now = moment();
            
                if (!inputDate.isValid()) return "Invalid date format"; // Handle invalid dates
            
                // Format the date to "Mar 20, 2025"
                const formattedDate = inputDate.format("MMM DD, YYYY");
            
                // Calculate difference
                const diffInSeconds = now.diff(inputDate, "seconds");
                const diffInMinutes = now.diff(inputDate, "minutes");
                const diffInHours = now.diff(inputDate, "hours");
                const diffInDays = now.diff(inputDate, "days");
                const diffInWeeks = now.diff(inputDate, "weeks");
                const diffInMonths = now.diff(inputDate, "months");
                const diffInYears = now.diff(inputDate, "years");
            
                let timeDepth;
                if (Math.abs(diffInSeconds) < 60) {
                    timeDepth = `${Math.abs(diffInSeconds)} sec${Math.abs(diffInSeconds) !== 1 ? 's' : ''}`;
                } else if (Math.abs(diffInMinutes) < 60) {
                    timeDepth = `${Math.abs(diffInMinutes)} min${Math.abs(diffInMinutes) !== 1 ? 's' : ''}`;
                } else if (Math.abs(diffInHours) < 24) {
                    timeDepth = `${Math.abs(diffInHours)} hour${Math.abs(diffInHours) !== 1 ? 's' : ''}`;
                } else if (Math.abs(diffInDays) < 7) {
                    timeDepth = `${Math.abs(diffInDays)} day${Math.abs(diffInDays) !== 1 ? 's' : ''}`;
                } else if (Math.abs(diffInWeeks) < 4) {
                    timeDepth = `${Math.abs(diffInWeeks)} week${Math.abs(diffInWeeks) !== 1 ? 's' : ''}`;
                } else if (Math.abs(diffInMonths) < 12) {
                    timeDepth = `${Math.abs(diffInMonths)} month${Math.abs(diffInMonths) !== 1 ? 's' : ''}`;
                } else {
                    timeDepth = `${Math.abs(diffInYears)} year${Math.abs(diffInYears) !== 1 ? 's' : ''}`;
                }
            
                return `${formattedDate} (${now.isAfter(inputDate) ? `${timeDepth} ago` : `${timeDepth} left`})`;
            };

    return(
        <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
               <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Clients : Client Reviews'} />                  
                  <div style={{display:'flex',textAlign:'center',fontSize:10}}>More features will be unlocked once you have 500+ reviews</div>
              </div>
                {taskLoader ?
                    <>
                    <div style={{margin: 'auto',width:'100%',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
                            <BounceLoader size={20} color='#a032a8' />
                    </div>
                        
                    </>
                    :
                    <>
                    <div style={{ width: '90%', margin: 'auto', padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px', justifyContent: 'center',alignItems: 'stretch'}}>
                        {reviews && reviews.map((item, index) => (
                            <div key={index} style={{display: 'flex',flexDirection: 'column',justifyContent: 'space-between',alignItems:'center',padding: '12px',borderRadius: '10px',boxShadow: '0px 2px 5px rgba(0,0,0,0.15)',backgroundColor: '#f9f9f9',textAlign: 'center',minHeight: '250px'}}>
                                <StarRating rating={item.rating} />
                                
                                {item.review && item.review !== "null" && item.review.trim() !== "" ? (
                                    <h5 style={{ fontWeight: 100, fontStyle: 'italic', marginTop: '8px', fontSize: 12 }}>"{item.review}"</h5>
                                ) : (
                                    <h5 style={{ fontWeight: 100, marginTop: '8px', fontSize: 12 }}>No review posted</h5>
                                )}

                                <div style={{width: '100%',backgroundColor: '#fff',padding: 5,borderRadius: 10,textAlign: 'center',fontSize: 10,boxShadow: '0px 1px 2px rgba(0,0,0,0.1)'}}>
                                    Posted against the complaint / request No. <strong>{item.complaint.req_unique}</strong>
                                </div>

                                <div style={{width: '100%',backgroundColor: '#fff',padding: 5,borderRadius: 10,textAlign: 'center',fontSize: 10,marginTop: 10,boxShadow: '0px 1px 2px rgba(0,0,0,0.1)'}}>
                                    <strong>Details</strong>
                                </div>

                                <div style={{ width: '100%', textAlign: 'left', marginTop: 10 }}>
                                    <div style={{fontSize: 10,backgroundColor: '#fff',padding: 5,borderRadius: 10,boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.25)',marginBottom: 5}}>
                                        Complaint / Request registered on <strong>{formatDate(item.complaint.req_timestamp)}</strong>
                                    </div>

                                    <div style={{fontSize: 10,display: 'flex',alignItems: 'center',backgroundColor: '#fff',padding: 5,borderRadius: 10,boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.25)',marginBottom: 5}}>
                                        <img src={item.agent.profile_pic} alt="Agent" style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 8 }} />
                                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                            your agent <strong>&nbsp;{item.agent.name}&nbsp;</strong> attended it on <strong>{formatDate(item.complaint.auto_update)}</strong>
                                        </div>
                                    </div>

                                    <div style={{fontSize: 10,display: 'flex',alignItems: 'center',backgroundColor: '#fff',padding: 5,borderRadius: 10,boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.25)'}}>
                                        <strong>Complaint / Request</strong> &nbsp;<i>{item.complaint.description}</i>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>




                    </>
                    
                    
                }
                {hasMore &&

                    <div style={{width:'100%',justifyContent:'center',alignItems:'center',textAlign:'center',padding:10}}>

                        <Button style={{backgroundColor:'#FCC100',color:'#fff',borderColor:'#FCC100'}} onClick={fetchReviews}>Load More</Button>
                    </div>
                }
            </div>
            {basicLoader &&
                <div className='loaderBox'>
                    <BounceLoader color='#FCC100' size={30} />
                </div>                                             
            }
        </div>
    )
}

export default ClientReviews;