import React, {useState, useEffect, CSSProperties, useCallback, useRef } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './ComplaintReports.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider, StepsTitle } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Popover, Tablist, Tab} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline, MdOutlinePhoneIphone,MdCalendarMonth    } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const themeColors = ["#993366", "#F4C430", "#2A52BE", "#DA4167", "#FCC100", "#49bd06", "#5e06bd", "#bd0667"];

const ComplaintReports = () =>{

  const [taskLoader, setTaskLoader] = useState(false);
  const [allData, setAllData] = useState(null);
  const [organic, setOrganic] = useState(null);
  const [complaintsArray, setComplaintsArray] = useState([]);
  const [offset,setOffset] = useState(0);

  const [complaintDetails,setComplaintDetails] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const {
    total_complaints = 0,
    active_complaints = 0,
    resolved_complaints = 0,
    cancelled_complaints = 0,
    disputed_complaints = 0,
    total_complaints_only = 0,
    total_service_requests = 0,
    paid_complaints = 0,
    unpaid_complaints = 0,
    avg_resolution_time = 0,
} = allData?.dashboard || {};


const formatMonth = (monthString) => {
  const [year, month] = monthString.split('-');
  return new Date(year, month - 1).toLocaleString('default', { month: 'long', year: 'numeric' });
};


const getPastDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  return formatDate(date);
};

const getPreviousMonthStart = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setDate(1);
  return formatDate(date);
};

const getPreviousMonthEnd = () => {
  const date = new Date();
  date.setDate(0);
  return formatDate(date);
};

const formatDate = (date) => {
  return date.toISOString().split("T")[0];
};

  const complaintsByMonth = {
    labels: allData?.monthly_trends?.map((data) => formatMonth(data.month)) || [],
    datasets: [
      {
        label: "Total Requests",
        data: allData?.monthly_trends?.map((data) => data.total_complaints) || [],
        backgroundColor: themeColors[0],
      },
    ],
};

const statusLabels = {
  1: "Awaiting Agent",
  2: "Agent Allotted",
  3: "Agent En-route",
  4: "Agent at Site",
  5: "Product Picked Up",
  6: "Service Rendered",
  7: "Service Complete",
  8: "Under Dispute",
  9: "Cancelled by Customer",
  10: "Cancelled by You" 
};

const complaintsDistribution = {
  labels: allData?.complaint_distribution?.map((d) => statusLabels[d.status]) || [],
  datasets: [
    {
      data: allData?.complaint_distribution?.map((d) => d.count) || [],
      backgroundColor: themeColors,
    },
  ],
};


  const paidVsUnpaid = {
    labels: ["Paid Requests", "Unpaid Requests"],
    datasets: [
      {
        data: [paid_complaints, unpaid_complaints],
        backgroundColor: [themeColors[1], themeColors[2]],
      },
    ],
  };


  const fetchAllData = async (start_date = null, end_date = null) =>{
    const sp_unique = localStorage.getItem('sp_unique');
    setTaskLoader(true);
    try{
      const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/00_complaintReport_1.php',{
        method:'POST',
        headers:{
          'Content-Type' : 'application/json'
        },
        body : JSON.stringify({ sp_unique, start_date, end_date})
      });

      const responseData = await response.json();

      if(responseData.success == 1){
          setAllData(responseData);  // Store full response
      }else{
          toaster.danger(responseData.error);
          
      }
    

    }catch(error){
        console.log(error);
    }finally{
      setTaskLoader(false);
    }
  }

  const fetchOrganic = async (start_date = null, end_date = null) =>{
    const sp_unique = localStorage.getItem('sp_unique');    
    setTaskLoader(true);

    try{
      const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/00_complaintReport_2.php',{
        method:'POST',
        headers:{
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify({sp_unique,start_date,end_date})
      });

      const responseData = await response.json();

      if(responseData.success == 1){
        setOrganic(responseData);
      }else{
        toaster.danger(responseData.error);
       
      }
    }catch(error){
      console.log(error);
    }finally{
      setTaskLoader(false);
    }
  }


  const fetchComplaints = async() =>{
    const sp_unique = localStorage.getItem('sp_unique');
    setTaskLoader(true);
    try{
      const dataString = {
        sp_unique,
        offset
      }
      const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/18_complaintMaster.php',{
        method :'POST',
        headers:{
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(dataString)
      });

      const responseData = await response.json();
      const newComplaints = responseData.complaints || [];
  
      if (newComplaints.length > 0) {
        setComplaintsArray((prevComplaints) => [...prevComplaints, ...newComplaints]);
        setOffset(offset + newComplaints.length);
      } else {
        toaster.warning("That's all we have in our records");
        setHasMore(false);
      }
      
    }catch(error){
      console.log(error);
    }finally{
      setTaskLoader(false);
    }
  }

  useEffect(()=>{
    fetchAllData();
    fetchOrganic();
    fetchComplaints();
  },[]);

  const formatUtcDate = (utcDate) => {
    
    const formattedDate = moment.utc(utcDate).local().format('MMMM Do, YYYY');
  
    
    const relativeTime = moment.utc(utcDate).local().fromNow();
  
    
    const result = `${formattedDate} (${relativeTime})`;
  
    return result;
  };

  const getSingleComp = (req_unique) =>{
    const singleRow = complaintsArray.find(comp=> comp.req_unique === req_unique);
    setComplaintDetails(singleRow);
  }

  const formatDateReadable = (dateString) => {
    const date = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(',', '').replace(/(\d+)(st|nd|rd|th)/, '$1');
};

const formatTimeReadable = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  const formattedTime = new Date();
  formattedTime.setHours(hours, minutes);
  return formattedTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
};



  return(
    <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
        <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
            <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Reports > Complaints'} />                                    
            </div>

            <div style={{width:'100%',justifyContent:'flex-start',alignItems:'center',textAlign:'center'}}>
            {allData && 
            
              <>

              <div style={{ display: "flex", padding: 5, justifyContent: "center", alignItems: "center", gap: 10, flexDirection: "row" }}>
                                     
                  <Button intent="primary" onClick={() => {fetchAllData();fetchOrganic()}}>
                    All Time
                  </Button>
                  <Button intent="primary" onClick={() => {fetchAllData(getPastDate(7), getPastDate(0));fetchOrganic(getPastDate(7), getPastDate(0))}}>
                    Last 7 Days
                  </Button>
                  <Button intent="primary" onClick={() => {fetchAllData(getPastDate(30), getPastDate(0));fetchOrganic(getPastDate(30), getPastDate(0))}}>
                    Last 30 Days
                  </Button>
                  <Button intent="primary" onClick={() => {fetchAllData(getPreviousMonthStart(), getPreviousMonthEnd());fetchOrganic(getPreviousMonthStart(), getPreviousMonthEnd())}}>
                    Previous Month
                  </Button>
                    <span style={{ fontSize: 12, fontWeight: "bold" }}>Custom Date Range</span>
                      <div className="date-picker-container">
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => setDateRange(update)}
                          isClearable={true}
                          placeholderText="📅 Select date range"
                          className="custom-input"
                          calendarClassName="custom-calendar"
                          />
                      </div>
                      <Button 
                        style={{marginRight:-25}}
                        intent="success" 
                        onClick={() => {startDate && endDate ? fetchAllData(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range");startDate && endDate ? fetchOrganic(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range")}}>
                          Fetch Report
                        </Button>
              </div>
                                    <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 20, padding: 20, backgroundColor: "#e6e6e6"}}>
                                  
                                      <div style={{backgroundColor: "#fff",padding: 20,borderRadius: 10,textAlign: "center",boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)",minHeight: "250px",  
                                      }}>

                                        <h3>Total Requests</h3>
                                        <h1 style={{ color: themeColors[0] }}>{total_complaints}</h1>
                                      </div>

                                      
                                      <div style={{backgroundColor: "#fff",padding: 20,borderRadius: 10,textAlign: "center",boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)",minHeight: "250px", 
                                      }}>

                                        <h3>Requests Breakdown</h3>
                                        <Pie data={complaintsDistribution} />
                                      </div>

                                      
                                      <div style={{backgroundColor: "#fff",padding: 20,borderRadius: 10,textAlign: "center",boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)",minHeight: "250px", 
                                      }}>
                                        <h3>Paid vs Unpaid</h3>
                                        <Pie data={paidVsUnpaid} />
                                      </div>

                                      
                                      <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, textAlign: "center", boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)", gridColumn: "span 2" }}>
                                        <h3>Requests Per Month</h3>
                                        <Bar data={complaintsByMonth} />
                                      </div>
                                    </div>
                                    <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 20, padding: 20, backgroundColor: "#e6e6e6"}}>

                                    {organic && (
                                    <>
                                      {/* Complaints by Hour - Bar Chart */}
                                      <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, textAlign: "center", boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)", minHeight: "250px" }}>
                                        <h3>Complaints by Hour</h3>
                                        <Bar
                                          data={{
                                            labels: Object.keys(organic.complaints_by_hour),
                                            datasets: [
                                              {
                                                label: "Total Complaints",
                                                data: Object.values(organic.complaints_by_hour),
                                                backgroundColor: themeColors[0],
                                              },
                                            ],
                                          }}
                                        />
                                      </div>

                                      {/* Complaints by Location - Pie Chart */}
                                      <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, textAlign: "center", boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)", minHeight: "250px" }}>
                                        <h3>Complaints by Location</h3>
                                        <Pie
                                          data={{
                                            labels: Object.keys(organic.complaints_by_location).filter((key) => organic.complaints_by_location[key] > 0),
                                            datasets: [
                                              {
                                                data: Object.values(organic.complaints_by_location).filter((val) => val > 0),
                                                backgroundColor: themeColors.slice(0, 4),
                                              },
                                            ],
                                          }}
                                        />
                                      </div>

                                      {/* Complaints by Product Type - Bar Chart */}
                                      <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, textAlign: "center", boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.15)", minHeight: "250px", gridColumn: "span 2" }}>
                                        <h3>Complaints by Product Type</h3>
                                        <Bar
                                          data={{
                                            labels: organic.complaints_by_product.map((item) => item.name),
                                            datasets: [
                                              {
                                                label: "Total Complaints",
                                                data: organic.complaints_by_product.map((item) => item.total_complaints),
                                                backgroundColor: themeColors.slice(0, organic.complaints_by_product.length),
                                              },
                                            ],
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}

                            </div>
                            </>
                            }

                          </div>
                          <div style={{width:'90%',padding:10,marginTop:20,justifyContent:'center',alignItems:'center',fontSize:25,fontWeight:100,textAlign:'center'}}>
                            All Complaints/Requests details
                          </div>
                          <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 20, padding: 20, backgroundColor: "#e6e6e6"}}>
                            {complaintsArray && complaintsArray.length>0 ?
                              <>
                                {complaintsArray.map((item,index)=>(
                                  <div key={index} style={{width: '250px',padding: 10,borderRadius: 10,backgroundColor: '#fff',boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.38)',
                                    display: 'flex',alignItems: 'center', gap: 10, cursor:'pointer', transition: 'transform 0.2s ease-in-out'
                                }} className="complaint-card" onClick={()=>getSingleComp(item.req_unique)} >
                                    
                                    <div style={{width: 50,height: 50,borderRadius: "50%",backgroundColor: "#FCC100",padding: 2,display: "flex",justifyContent: "center",
                                        alignItems: "center"}}>
                                        <img style={{width: "100%",height: "100%",borderRadius: "50%",objectFit: "cover"}} src={item.cus_data.profile_pic && item.cus_data.profile_pic !== '' ? item.cus_data.profile_pic : 'https://cdn.myservicecircle.app/icon.jpg'} />
                                    </div>                               
                                    
                                    <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center', alignItems: 'flex-start', textAlign: 'left'}}>
                                        
                                        <div style={{fontWeight: 'bold',fontSize: 12,color: '#333'}}>{item.cus_data.name}</div>                                
                                        <div style={{fontSize: 10,color: '#666',marginTop: 2}}>{item.req_unique}</div>
                                        <div style={{fontSize: 10,color: '#666',marginTop: 2}}>{formatUtcDate(item.req_timestamp)}</div>
                                        <div style={{fontSize: 10,color: '#666',marginTop: 2}}>{item.product.name}, {item.product.locality}</div>
                                    </div>
                                </div>
                                
                                ))}
                              </>
                              :
                              <>
                                <p>No data exists.</p>
                              </>
                              
                            }
                            
                          </div>
                          {hasMore && 
                            <div style={{width:'90%',display:'flex',justifyContent:'center',alignItems:'center',padding:10,marginTop:10,textAlign:'center'}} onClick={fetchComplaints}>
                                <Button style={{backgroundColor:'#720c91',color:'#fff',width:200}}>Load More</Button>
                            </div>
                          }
                      </div>
        {taskLoader &&
          <div className='loaderBox'>
            <BounceLoader color='#FCC100' size={30} />
          </div>          
                                                   
        }
             <Dialog
                  isShown={complaintDetails}
                  title={`Complaint Details > ${complaintDetails?.req_unique}`}
                  intent="danger"
                  onCloseComplete={() => {
                      setComplaintDetails(null);
                  }}
                  width="80vw"
                  maxWidth="none"
                  hasFooter={false}
              >
                  {complaintDetails && (
                      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", gap: 20, padding: 20, backgroundColor: "#f8f9fa", borderRadius: 10 }}>

                          {/* Customer Info */}
                          <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                              <h3 style={{ color: "#993366", textAlign: "center" }}>Customer</h3>
                              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                  <img src={complaintDetails.cus_data.profile_pic} alt="Customer" style={{ width: 50, height: 50, borderRadius: "50%" }} />
                                  <div>
                                      <strong>{complaintDetails.cus_data.name}</strong>
                                      <p style={{ fontSize: 12, color: "#777" }}>{complaintDetails.cus_data.phone}</p>
                                  </div>
                              </div>
                          </div>

                          {/* Agent Info */}
                          {complaintDetails.status>2 && 
                          <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                              <h3 style={{ color: "#F4C430", textAlign: "center" }}>Service Agent</h3>
                              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                  <img src={complaintDetails.sa_data.profile_pic} alt="Agent" style={{ width: 50, height: 50, borderRadius: "50%" }} />
                                  <div>
                                      <strong>{complaintDetails.sa_data.name}</strong>
                                      <p style={{ fontSize: 12, color: "#777" }}>{complaintDetails.sa_data.phone}</p>
                                  </div>
                              </div>
                          </div>
                          }

                          {/* Product Info */}
                          <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                              <h3 style={{ color: "#2A52BE", textAlign: "center" }}>Product</h3>
                              <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                  <img src={complaintDetails.product.icon_url} alt="Product" style={{ width: 50, height: 50, borderRadius: 10 }} />
                                  <div>
                                      <strong>{complaintDetails.product.name}</strong>
                                      <p style={{ fontSize: 12, color: "#777" }}>{complaintDetails.product.description}</p>
                                  </div>
                              </div>

                              {/* Product Images */}
                              <div style={{ display: "flex", justifyContent: "center", gap: 10, marginTop: 10, flexWrap: "wrap" }}>
                                  {complaintDetails.product.album.map((img, index) => (
                                      <img key={index} src={img.prd_pic} alt={`Product ${index + 1}`} style={{ width: 60, height: 60, borderRadius: 5, objectFit: "cover", boxShadow: "0px 3px 5px rgba(0,0,0,0.1)" }} />
                                  ))}
                              </div>
                          </div>

                          
                          <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                              <h3 style={{ color: "#DA4167", textAlign: "center" }}>Complaint Details</h3>
                              <p><strong>Status:</strong> {complaintDetails.status == 7 ? "Resolved" : "In Progress"}</p>
                              <p><strong>Type:</strong> {complaintDetails.comp_type == 1 ? "Complaint" : "Service Request"}</p>
                              <p><strong>Description:</strong> {complaintDetails.description}</p>
                                  {complaintDetails.status>2 && 
                              <p><strong>Scheduled:</strong> {formatDateReadable(complaintDetails.all_date)} ({formatTimeReadable(complaintDetails.all_time1)} - {formatTimeReadable(complaintDetails.all_time2)})</p>
                                }

                          </div>

                          {/* Service Notes */}
                          <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                              <h3 style={{ color: "#FCC100", textAlign: "center" }}>Service Notes</h3>
                              <p><strong>Service Provider:</strong> {complaintDetails.spcl_note || "No notes"}</p>
                              <p><strong>Agent:</strong> {complaintDetails.sa_note || "No actions recorded"}</p>
                          </div>

                          {/* Paid/Free Service */}
                          <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                              <h3 style={{ color: "#49bd06", textAlign: "center" }}>Paid/Free</h3>
                              <p><strong>Status:</strong> {complaintDetails.paid_flag === 1 ? "Paid Service" : "Free Service"}</p>
                              {complaintDetails.paid_flag === 1 && <p><strong>Amount:</strong> ₹{complaintDetails.amount}</p>}
                          </div>

                          {/* Service Contract Info */}
                          {complaintDetails.cont_unique && (
                              <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, boxShadow: "0px 3px 8px rgba(0,0,0,0.1)" }}>
                                  <h3 style={{ color: "#5e06bd", textAlign: "center" }}>Service Contract</h3>
                                  <p><strong>Contract ID:</strong> {complaintDetails.cont_unique}</p>
                                  <p>This service was covered under a valid contract.</p>
                              </div>
                          )}

                      </div>
                  )}
              </Dialog>


    </div>
  )
}

export default ComplaintReports;