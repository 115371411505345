import React, {useState, useEffect, CSSProperties, useCallback, useRef } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './ComplaintReports.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider, StepsTitle } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Popover, Tablist, Tab} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose, MdLocalShipping } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline, MdOutlinePhoneIphone,MdCalendarMonth    } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdCalendarToday, MdInfo, MdDevices, MdAssignment, MdDownload } from "react-icons/md";
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const themeColors = ["#993366", "#F4C430", "#2A52BE", "#DA4167", "#FCC100", "#49bd06", "#5e06bd", "#bd0667"];

const ContractReports = () =>{

  const [taskLoader, setTaskLoader] = useState(false);
  const [contractData, setContractData] = useState(null);
  const [contractsArray, setContractsArray] = useState([]);
  const [selfProfile, setSelfProfie] = useState(null);
  const [singleContract, setSingleContract] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [showPrintPreview, setShowPrintPreview] = useState(false);

  
  
  const getPastDate = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return formatDate(date);
  };
  
  const getPreviousMonthStart = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    date.setDate(1);
    return formatDate(date);
  };
  
  const getPreviousMonthEnd = () => {
    const date = new Date();
    date.setDate(0);
    return formatDate(date);
  };
  
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };
  // Fetch Contract Data
  const fetchContractData = async (start_date = null, end_date = null) => {
    const sp_unique = localStorage.getItem("sp_unique");
    setTaskLoader(true);

    try {
      const response = await fetch("https://access.myservicecircle.app/wcapi/sp_data/00_serviceContracts_1.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sp_unique, start_date, end_date }),
      });

      const responseData = await response.json();
      if (responseData.success == 1) {
        setContractData(responseData);
      } else {
        toaster.danger(responseData.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTaskLoader(false);
    }
  };

  const fetchAllContracts = async () =>{
    const sp_unique = localStorage.getItem('sp_unique');
    setTaskLoader(true);
    try{
        const dataString = {
            sp_unique,
            offset
        }

        const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/32_serviceContracts.php',{
            method:'POST',
            headers:{
                'Content-Type' : 'application/json'
            },
            body : JSON.stringify(dataString)
        });

        const responseData = await response.json();

        if(responseData.success == 1){
            if(responseData.contracts.length>0){
                setContractsArray(prevContracts => [...prevContracts, ...responseData.contracts]);
                setOffset(offset + responseData.contracts.length);
            }else{
                setHasMore(false);
                toaster.warning("That's all we have in our records");
            }
        }else{
            toaster.danger("Something went wrong...");
        }
    }catch(error){

    }finally{
        setTaskLoader(false);
    }
  }

  const fetchSelfProfile = async() =>{
    const sp_unique = localStorage.getItem('sp_unique');
    try{
        const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/1_selfData.php',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({sp_unique})
        });

        const responseData = await response.json();

        setSelfProfie(responseData.details);
    }catch(error){

    }
  }

  useEffect(() => {
    fetchContractData();
    fetchAllContracts();
    fetchSelfProfile();
  }, []);

  // Format month for display
  const formatMonth = (monthString) => {
    const [year, month] = monthString.split("-");
    return new Date(year, month - 1).toLocaleString("default", { month: "long", year: "numeric" });
  };

    const contractsByMonthChart = {
        labels: contractData?.contracts_by_month?.map((item) => formatMonth(item.month)) || [],
        datasets: [
          {
            label: "Total Contracts",
            data: contractData?.contracts_by_month?.map((item) => item.total_contracts) || [],
            backgroundColor: themeColors[0],
          },
        ],
      };
    
      // Bar chart for Services Allotted vs Delivered
      const servicesComparisonChart = {
        labels: contractData?.services_comparison?.map((item) => item.cont_unique) || [],
        datasets: [
          {
            label: "Services Allotted",
            data: contractData?.services_comparison?.map((item) => item.services_allotted) || [],
            backgroundColor: themeColors[1],
          },
          {
            label: "Services Delivered against the contract",
            data: contractData?.services_comparison?.map((item) => item.services_covered) || [],
            backgroundColor: themeColors[2],
          },
        ],
      };

      
        const formatUtcDate = (utcDate) => {
          
          const formattedDate = moment.utc(utcDate).local().format('MMMM Do, YYYY');
        
          
          const relativeTime = moment.utc(utcDate).local().fromNow();
        
          
          const result = `${formattedDate} (${relativeTime})`;
        
          return result;
        };

        const isRenewable = (endDate) => {
            const today = new Date();
            const contractEndDate = new Date(endDate);
            const differenceInDays = Math.ceil((contractEndDate - today) / (1000 * 60 * 60 * 24));
            return differenceInDays <= 15;
          };


          const generatePDF = () => {
            const contractElement = document.getElementById("printable-contract");
          
            // Save original styles before modifying them
            const originalHeight = contractElement.style.height;
            const originalOverflow = contractElement.style.overflow;
          
            // Expand the content to fit everything for PDF capture
            contractElement.style.height = "auto";
            contractElement.style.maxHeight = "none";
            contractElement.style.overflow = "visible";
          
            html2canvas(contractElement, { 
              scale: 2, 
              useCORS: true, 
              allowTaint: true
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/png");
              const pdf = new jsPDF("p", "mm", "a4");
              const imgWidth = 190;
              const imgHeight = (canvas.height * imgWidth) / canvas.width;
          
              pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
              pdf.save(`Contract_${singleContract?.cont_unique}.pdf`);
          
              // Restore original styles after capturing
              contractElement.style.height = originalHeight;
              contractElement.style.overflow = originalOverflow;
            });
          };
          
          
          const formatDateWithSuffix = (dateString) => {
            if (!dateString) return "";
          
            const date = new Date(dateString);
            const day = date.getDate();
            const month = date.toLocaleString("default", { month: "long" });
            const year = date.getFullYear();
          
            // Function to get ordinal suffix (st, nd, rd, th)
            const getOrdinalSuffix = (day) => {
              if (day > 3 && day < 21) return "th"; // Covers 11th to 20th
              switch (day % 10) {
                case 1: return "st";
                case 2: return "nd";
                case 3: return "rd";
                default: return "th";
              }
            };
          
            return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
          };
          
          // Example Usage
          const formatLocation = (loc) => {
            if (!loc) return "";
          
            const locationFields = [
              loc.apartment,
              loc.apt_name,
              loc.locality,
              loc.city,
              loc.pin_zip,
              loc.comp_name,
              loc.tax_no
            ];
          
            // Filter out null, empty, or undefined values and join with commas
            return locationFields.filter(field => field && field.trim() !== "").join(", ");
          };
          

    return(
        <div style={{ display: 'flex', height: '100vh'}}>
        <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
                <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                    <HeaderBar page_name={'Reports > Contracts'} />                                    
                </div>

                <div style={{width:'100%',justifyContent:'flex-start',alignItems:'center',textAlign:'center'}}>
                  <div style={{ display: "flex", padding: 5, justifyContent: "center", alignItems: "center", gap: 10, flexDirection: "row" }}>
                                                       
                                    <Button intent="primary" onClick={() => {fetchContractData();}}>
                                      All Time
                                    </Button>
                                    <Button intent="primary" onClick={() => {fetchContractData(getPastDate(7), getPastDate(0));}}>
                                      Last 7 Days
                                    </Button>
                                    <Button intent="primary" onClick={() => {fetchContractData(getPastDate(30), getPastDate(0));}}>
                                      Last 30 Days
                                    </Button>
                                    <Button intent="primary" onClick={() => {fetchContractData(getPreviousMonthStart(), getPreviousMonthEnd())}}>
                                      Previous Month
                                    </Button>
                                      <span style={{ fontSize: 12, fontWeight: "bold" }}>Custom Date Range</span>
                                        <div className="date-picker-container">
                                          <DatePicker
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => setDateRange(update)}
                                            isClearable={true}
                                            placeholderText="📅 Select date range"
                                            className="custom-input"
                                            calendarClassName="custom-calendar"
                                            />
                                        </div>
                                        <Button 
                                          style={{marginRight:-25}}
                                          intent="success" 
                                          onClick={() => {startDate && endDate ? fetchContractData(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range");startDate && endDate ? fetchContractData(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range")}}>
                                            Fetch Report
                                          </Button>
                                </div>
                    <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 20, padding: 20, backgroundColor: "#e6e6e6"}}>
                    <Card elevation={2} padding={20} backgroundColor="white" textAlign="center">
                        <Heading size={600} marginBottom={10}>Contracts by Month</Heading>
                        <div style={{ height: "300px" }}> 
                        {contractData?.contracts_by_month?.length > 0 ? (
                            <Bar data={contractsByMonthChart} options={{ responsive: true, maintainAspectRatio: false }} />
                        ) : (
                            <Text>No data available</Text>
                        )}
                        </div>
                    </Card>

                    
                    <Card elevation={2} padding={20} backgroundColor="white" textAlign="center">
                        <Heading size={600} marginBottom={10}>Services Comparison</Heading>
                        <div style={{ height: "300px" }}> 
                        {contractData?.services_comparison?.length > 0 ? (
                            <Bar data={servicesComparisonChart} options={{ responsive: true, maintainAspectRatio: false }} />
                        ) : (
                            <Text>No data available</Text>
                        )}
                        </div>
                    </Card>
                    </div>
                </div>
                <div style={{width:'90%',display:'flex',justifyContent:'center',alignItems:'center',padding:10,marginTop:10,fontSize:20,fontWeight:100}}>All contracts and details</div>
                <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 20, padding: 20, backgroundColor: "#e6e6e6"}}>
                        {contractsArray && contractsArray.length>0 ?
                            <>
                            {contractsArray.map((item,index)=>(
                            <>
                                <div key={index} style={{width: '250px',padding: 10,borderRadius: 10,backgroundColor: '#fff',boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.38)',
                                    display: 'flex',alignItems: 'center', gap: 10, cursor:'pointer', transition: 'transform 0.2s ease-in-out'
                                }} className="complaint-card" onClick={()=>setSingleContract(item)} >
                                    
                                    <div style={{width: 50,height: 50,borderRadius: "50%",backgroundColor: "#FCC100",padding: 2,display: "flex",justifyContent: "center",
                                        alignItems: "center"}}>
                                        <img style={{width: "100%",height: "100%",borderRadius: "50%",objectFit: "cover"}} src={item.cus.profile_pic && item.cus.profile_pic !== '' ? item.cus.profile_pic : 'https://cdn.myservicecircle.app/icon.jpg'} />
                                    </div>                               
                                    
                                    <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center', alignItems: 'flex-start', textAlign: 'left'}}>
                                        
                                        <div style={{fontWeight: 'bold',fontSize: 12,color: '#333'}}>{item.cus.name}</div>                                
                                        <div style={{fontSize: 10,color: '#666',marginTop: 2}}>{item.cont_unique}</div>
                                        <div style={{fontSize: 10,color: '#666',marginTop: 2}}>{formatUtcDate(item.created_at)}</div>
                                        <div style={{fontSize: 10,color: '#666',marginTop: 2}}>{item.cus.products?.length} products</div>
                                    </div>
                                </div>
                            </>
                            ))}
                            </>
                            :
                            <>
                            <div style={{width:'90%',display:'flex',justifyContent:'center',alignItems:'center',padding:10,marginTop:10,fontSize:15}}>No data found</div>
                            </>
                        }
                </div>
                {hasMore && 
                    <div style={{width:'90%',display:'flex',justifyContent:'center',alignItems:'center',padding:10,marginTop:10,textAlign:'center'}} onClick={fetchAllContracts}>
                        <Button style={{backgroundColor:'#720c91',color:'#fff',width:200}}>Load More</Button>
                    </div>
                }
            </div>

            <Dialog
            isShown={singleContract}
            title={`Contract Details > ${singleContract?.cont_unique}`}
            intent="danger"
            onCloseComplete={() => {setShowPrintPreview(false);setSingleContract(null)}}
            width="80vw"
            maxWidth="none"
            hasFooter={false}
            >
            {singleContract && (
                <div style={{ padding: 20, backgroundColor: "#f8f9fa", borderRadius: 10, fontFamily: "Arial, sans-serif" }}>

                {/* Header with Status */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: 10, borderBottom: "2px solid #ddd" }}>
                    <div>
                    <h3 style={{ margin: 0, color: "#333", fontSize: "16px", fontWeight: "bold" }}>{singleContract.details}</h3>
                    <p style={{ color: "#666", fontSize: "12px", display: "flex", alignItems: "center" }}>
                        <MdCalendarToday style={{ marginRight: 5 }} /> {singleContract.start_date} - {singleContract.end_date}
                    </p>
                    </div>
                    <div style={{ backgroundColor: singleContract.status === 1 ? "#4CAF50" : "#DA4167", padding: "5px 15px", borderRadius: 5, color: "white", fontWeight: "bold", display: "flex", alignItems: "center" }}>
                    {singleContract.status === 1 ? <MdCheckCircle style={{ marginRight: 5 }} /> : <MdCancel style={{ marginRight: 5 }} />}
                    {singleContract.status === 1 ? "Active" : "Inactive"}
                    </div>
                </div>

                {/* Free Services Info */}
                <div style={{ marginTop: 15, padding: 10, backgroundColor: "#fff", borderRadius: 8, boxShadow: "0px 3px 6px rgba(0,0,0,0.1)" }}>
                    <h4 style={{ fontSize: "14px", color: "#2A52BE", display: "flex", alignItems: "center" }}>
                    <MdAssignment style={{ marginRight: 5 }} /> Free Services
                    </h4>
                    <p style={{ fontSize: "13px", color: "#333" }}>
                    <strong>Allotted:</strong> {singleContract.count} | <strong>Used:</strong> {singleContract.services_used}
                    </p>
                </div>

                {/* Products Covered Under Contract */}
                <h4 style={{ marginTop: 20, fontSize: "14px", color: "#993366", display: "flex", alignItems: "center" }}>
                    <MdDevices style={{ marginRight: 5 }} /> Products Covered
                </h4>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 10 }}>

                    {singleContract.cus.products.map((product, index) => (
                    <div key={index} style={{
                        backgroundColor: "#fff",
                        padding: 15,
                        borderRadius: 10,
                        boxShadow: "0px 3px 6px rgba(0,0,0,0.15)"
                    }}>
                        <img src={product.genericdata.icon_url} alt="Product" style={{ width: 40, height: 40, marginBottom: 10 }} />
                        <h5 style={{ margin: 0, fontSize: "13px", color: "#333", fontWeight: "bold" }}>
                        {product.nick_name || product.genericdata.name}
                        </h5>
                        <p style={{ fontSize: "12px", color: "#666" }}>{product.description}</p>

                        {/* Product Location */}
                        <div style={{ fontSize: "12px", color: "#444", display: "flex", alignItems: "center", marginTop: 8 }}>
                        <MdLocationOn style={{ marginRight: 5, color: "#DA4167" }} />
                        {product.loc.loc_name}, {product.loc.city}
                        </div>

                        {/* Product Images */}
                        <div style={{ display: "flex", gap: 5, marginTop: 8 }}>
                        {product.album.map((img, i) => (
                            <img key={i} src={img.prd_pic} alt={`Product ${i + 1}`} style={{ width: 40, height: 40, borderRadius: 5, objectFit: "cover", boxShadow: "0px 3px 5px rgba(0,0,0,0.1)" }} />
                        ))}
                        </div>
                    </div>
                    ))}
                </div>

                {/* Service Location */}
                <h4 style={{ marginTop: 20, fontSize: "14px", color: "#2A52BE", display: "flex", alignItems: "center" }}>
                    <MdEventNote style={{ marginRight: 5 }} /> Contract Details
                </h4>
                <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10, boxShadow: "0px 3px 6px rgba(0,0,0,0.15)" }}>
                    <p style={{ fontSize: "12px", color: "#333" }}>
                    {singleContract.details}
                    </p>
                </div>

                {/* Action Buttons */}
                <div style={{ display: "flex", justifyContent: "center", gap: 15, marginTop: 20 }}>
                    <Button intent="default" style={{color:'green'}} onClick={()=>setShowPrintPreview(true)}>Download</Button>
                    {isRenewable(singleContract.end_date) && (
                    <Button appearance="primary" intent="success" onClick={() => alert("Extending Contract...")}>Renew Contract</Button>
                    )}
                    <Button appearance="primary" intent="danger" onClick={() => alert("Canceling Contract...")}>Cancel Contract</Button>
                </div>

                </div>
            )}
            </Dialog>


            {taskLoader &&
                <div className='loaderBox'>
                    <BounceLoader color='#FCC100' size={30} />
                </div>          
                                                               
            }
            {showPrintPreview && (
            <div className="print-preview-overlay">
              <div className="print-preview-container">        
                
              <div id="printable-contract" className="print-content">
  
                {/* Fixed Header for Logo & Company Name */}
                <div className="print-header">
                    <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '10px'
                    }}>
                    <img 
                        src={selfProfile.logo} 
                        alt="Company Logo" 
                        style={{
                        width: '75px',
                        height: '75px',
                        borderRadius: '50%',
                        objectFit: 'cover'
                        }} 
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <span style={{ fontSize: '20px', fontWeight: 'bold', color: '#333' }}>
                        {selfProfile.entity_name}
                        </span>
                        <span style={{ fontSize: '15px', fontWeight: 'normal', color: '#000' }}>
                        Service contract {singleContract.cont_unique}
                        </span>
                    </div>
                    </div>
                </div>

                
                <div className="print-body">
                
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '10%' }}>
                    <span style={{ fontSize: '12px', fontWeight: 100, color: '#000', fontWeight: 'bold' }}>Contract details</span>
                    <span style={{ fontSize: '12px', fontWeight: 100, color: '#000' }}>Start date : {formatDateWithSuffix(singleContract.start_date)}</span>
                    <span style={{ fontSize: '12px', fontWeight: 100, color: '#000' }}>End date : {formatDateWithSuffix(singleContract.end_date)}</span>                
                    </div>

                
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                    <span style={{ fontSize: '12px', fontWeight: 100, color: '#000', fontWeight: 'bold' }}>Client details</span>
                    <span style={{ fontSize: '12px', fontWeight: 100, color: '#000' }}>Name : {singleContract.cus.name}</span>
                    {singleContract.cus.block_no && singleContract.cus.address && (
                        <>
                        <span style={{ fontSize: '12px', fontWeight: 100, color: '#000' }}>{singleContract.cus.block_no}, {singleContract.cus.address}</span>
                        <span style={{ fontSize: '12px', fontWeight: 100, color: '#000' }}>{singleContract.cus.locality}, {singleContract.cus.city} - {singleContract.cus.pin_zip}</span>
                        </>
                    )}                
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                        <span style={{ fontSize: '12px', fontWeight: 100, color: '#000', fontWeight: 'bold' }}>Contract note.</span>
                        <span style={{ fontSize: '12px', fontWeight: 100, color: '#000',textAlign:'left' }}>This service contract is made between <strong>{selfProfile.entity_name}</strong> and <strong>{singleContract.cus.name}</strong>, having the address {singleContract.cus.block_no}, {singleContract.cus.address}, {singleContract.cus.locality}, {singleContract.cus.city} - {singleContract.cus.pin_zip}.<br />{singleContract.details}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginTop: 10 }}>
                        
                        <div style={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: "10px",
                          width: "100%",
                          marginTop: "10px"
                        }}>
                          {/* Free Services Covered */}
                          <div style={{
                            flex: 1,
                            padding: "8px 12px",
                            border: "1px solid #b8b8b8",
                            borderRadius: "6px",
                            fontSize: "12px",
                            backgroundColor: "#f9f9f9", // Light background for contrast
                            textAlign: "left",
                            fontWeight: "bold"
                          }}>
                            Free services covered: {singleContract.count}
                          </div>

                          {/* Total Services Used */}
                          <div style={{
                            flex: 1,
                            padding: "8px 12px",
                            border: "1px solid #b8b8b8",
                            borderRadius: "6px",
                            fontSize: "12px",
                            backgroundColor: "#f9f9f9",
                            textAlign: "left",
                            fontWeight: "bold"
                          }}>
                            Total services used: {singleContract.services_used}
                          </div>
                        </div>

                        
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 10 }}>
                    
                    <span style={{ fontSize: '12px', fontWeight: 100, color: '#000', fontWeight: 'bold' }}>Products covered</span>
                    <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                        <thead>
                        <tr style={{ background: "#f2f2f2" }}>
                            <th style={{ padding: "8px", textAlign: "left", verticalAlign: "top", borderBottom: "2px solid #ddd", fontSize: '12px', fontWeight: 'bold' }}>Sr. No.</th>
                            <th style={{ padding: "8px", textAlign: "left", verticalAlign: "top", borderBottom: "2px solid #ddd", fontSize: '12px', fontWeight: 'bold' }}>Product Name</th>
                            <th style={{ padding: "8px", textAlign: "left", verticalAlign: "top", borderBottom: "2px solid #ddd", fontSize: '12px', fontWeight: 'bold' }}>Product Description</th>
                            <th style={{ padding: "8px", textAlign: "left", verticalAlign: "top", borderBottom: "2px solid #ddd", fontSize: '12px', fontWeight: 'bold' }}>Location</th>
                        </tr>
                        </thead>
                        <tbody>
                        {singleContract.cus.products.map((product, index) => (
                            <tr key={index} style={{ borderBottom: "1px solid #ddd" }}>
                            <td style={{ padding: "8px", textAlign: "left", verticalAlign: "top", fontSize: '12px' }}>{index + 1}</td>
                            <td style={{ padding: "8px", textAlign: "left", verticalAlign: "top", fontSize: '12px' }}>{product.genericdata.name} ({product.nick_name})</td>
                            <td style={{ padding: "8px", textAlign: "left", verticalAlign: "top", fontSize: '12px' }}>{product.description}</td>
                            <td style={{ 
                            padding: "8px", 
                            textAlign: "left", 
                            verticalAlign: "top", 
                            display: "flex", 
                            alignItems: "center", 
                            gap: "5px", 
                            fontSize: "12px"
                            }}>
                            
                            <div style={{ width: "24px", height: "24px", display: "flex", alignItems: "center", justifyContent: "center", flexShrink: 0 }}>
                                <MdLocationOn style={{ color: "#b8b88b", width: "18px", height: "18px" }} />
                            </div>

                            
                            <span style={{ flex: 1 }}>{formatLocation(product.loc)}</span>
                            </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: 20,fontSize:'12px',fontWeight:'bold',textAlign:'left',padding:20 }}>
                    This document is a legally binding contract between the service provider and the client. Both parties are bound by the terms and
                    conditions set forth in this agreement.
                    </div>
                </div>

                </div>

      
                <div className="print-buttons">
                  <button className="download-btn" onClick={generatePDF}>
                    <MdDownload /> Download PDF
                  </button>
                  <button className="close-btn" onClick={() => setShowPrintPreview(false)}>
                    <MdClose /> Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        
    )
}

export default ContractReports;