import React, {useState, useEffect, CSSProperties, useCallback } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';

import './Profile.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";

const Profile = ()=>{

    const navigate = useNavigate();
    const [myid, setMyid] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [taskLoader, setTaskLoader] = useState(false);
    const [selfProfile, setSelfProfile] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [editLogoDialog, setEditLogoDialog] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropAreaPixels, setCropAreaPixels] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        // File Size Check
        if (file.size > 500 * 1024) {
            toaster.danger("File size must be below 500KB");
            return;
        }

        if(file.size < 10 * 1024){
            toaster.danger("Please upload a proper image");
            return;
        }

        // Read File
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setSelectedImage(reader.result);
        };
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: "image/*",
        maxFiles: 1
    });

    // Save Cropped Area
    const onCropComplete = (_, croppedAreaPixels) => {
        setCropAreaPixels(croppedAreaPixels);
    };

    // Generate Cropped Image
    const getCroppedImage = async () => {
        if (!selectedImage) return;

        try {
            const image = await createCroppedImage(selectedImage, cropAreaPixels);
            setCroppedImage(image);
            toaster.success("Image cropped successfully!");
            uploadPicture(image);
        } catch (error) {
            toaster.danger("Failed to crop image");
        }
    };

    // Function to Crop Image
    const createCroppedImage = async (imageSrc, cropAreaPixels) => {
        const image = new Image();
        image.src = imageSrc;
        await new Promise((resolve) => (image.onload = resolve));

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = cropAreaPixels.width;
        canvas.height = cropAreaPixels.height;

        ctx.drawImage(
            image,
            cropAreaPixels.x,
            cropAreaPixels.y,
            cropAreaPixels.width,
            cropAreaPixels.height,
            0,
            0,
            cropAreaPixels.width,
            cropAreaPixels.height
        );
        //alert(canvas.toDataURL("image/jpeg"));
        return canvas.toDataURL("image/jpeg");
    };

    const uploadPicture = async (base64) =>{
        const sp_unique = myid;
        setTaskLoader(true);
        try{
          const dataString = {
            sp_unique,
            base64
          }
          const response = await fetch('https://access.myservicecircle.app/sp/55_newLogo.php',{
            method : 'POST',
            headers:{
              'Content-Type':'application/json'
            },
            body: JSON.stringify(dataString)
          });
    
          const responseData = await response.json();
          console.log(JSON.stringify(responseData));
          if(responseData.success == 1){
            const filepath = `https://cdn.myservicecircle.app/sp_logos/${responseData.filename}`;
            setSelfProfile((prevData) => ({
              ...prevData,
              logo : filepath,
            }));
            setEditLogoDialog(false);
            setSelectedImage(null);
            uploadFilePath(filepath);
          }else{
            toaster.danger("Oops! Something went wrong. Please try again later");
            return;
          }
        }catch(error){  
          //console.log(error);
        }finally{
            setTaskLoader(false);
        }
      }    

      const uploadFilePath = async (filepath) =>{
        setTaskLoader(true);
        try{
            const dataString = {
                sp_unique:myid,
                filepath
            }
            console.log(dataString);

            const response = await fetch('https://access.myservicecircle.app/wcapi/6_updateLogo.php',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(dataString)
            });
            const responseData = await response.json();

            if(responseData.success == 1){

            }else{
                toaster.danger("Something went wrong!");
            }
        }catch(error){
            console.log(error);
        }finally{
            setTaskLoader(false);
        }
      }
    useEffect(()=>{
        const sp_unique = localStorage.getItem('sp_unique');
        if(sp_unique){
            setMyid(sp_unique);
        }else{
            navigate('./Login');
        }
    },[]);

    useEffect(()=>{
        if(myid){
            fetchSelf(myid);
        }
    },[myid]);

    const fetchSelf = async(sp_unique) =>{
        setTaskLoader(true);

        try{
            const response = await fetch('https://access.myservicecircle.app/sp/1_selfData.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({sp_unique})
            });

            const responseData = await response.json();

            setSelfProfile(responseData.details);
            
        }catch(error){
            console.log(error);
        }finally{   
            setTaskLoader(false);
        }

    }

    const StarRating = ({ rating }) => {
        const maxStars = 5;
        const fullStars = Math.floor(rating); // Full stars
        const hasHalfStar = rating % 1 !== 0; // Half star condition
        const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars
    
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {/* Render Full Stars */}
                {[...Array(fullStars)].map((_, index) => (
                    <MdStar key={index} style={{ color: '#FFD700', fontSize: 20 }} />
                ))}
    
                {/* Render Half Star if applicable */}
                {hasHalfStar && <MdStarHalf style={{ color: '#FFD700', fontSize: 20 }} />}
    
                {/* Render Empty Stars */}
                {[...Array(emptyStars)].map((_, index) => (
                    <MdStarOutline key={index} style={{ color: '#FFD700', fontSize: 20 }} />
                ))}
            </div>
        );
    };

    return(
        <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
               <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Profile'} />                  
              </div>
                {selfProfile && 
               <div style={{ width: '90%', margin: 'auto',padding: 20, backgroundColor: '#f5f5f5',borderRadius: 10,boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',display: 'flex',flexDirection: 'column',alignItems: 'center',}}>
                
                <div style={{width: 150,height: 150,borderRadius: '50%',display: 'flex',justifyContent: 'center',alignItems: 'center',backgroundColor: '#fff',overflow: 'hidden',position: 'relative',cursor: 'pointer'}} onMouseEnter={() => setShowEdit(true)} onMouseLeave={() => setShowEdit(false)}>
                            <img src={selfProfile.logo} alt="Profile" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                            
                            {showEdit && (
                                <div onClick={()=>setEditLogoDialog(true)}style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',backgroundColor: 'rgba(0, 0, 0, 0.5)',display: 'flex',justifyContent: 'center',alignItems: 'center',color: '#fff',fontSize: 24,borderRadius: '50%',transition: 'opacity 0.3s ease',}}>
                                    <MdEdit />
                                </div>
                            )}
                    </div>


                <div style={{width: '90%',marginTop: 20,backgroundColor: '#fff',borderRadius: 10,padding: 20,position: 'relative',boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'}}>
                    <h2 style={{ textAlign: 'center', color: '#333', marginBottom: 10 }}>{selfProfile.entity_name}</h2>
                    {selfProfile.tax_reg_number && 
                        <p style={{ textAlign: 'center', color: '#666', fontSize: 14 }}>{selfProfile.tax_reg_name}: {selfProfile.tax_reg_number}</p>                    
                    }
                    
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 15 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdEmail style={{ color: '#b8b8b8' }} /> <span>{selfProfile.email}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdPhone style={{ color: '#b8b8b8' }} /> <span>{selfProfile.phone}</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdLocationOn style={{ color: '#b8b8b8' }} /> <span>{selfProfile.address}, {selfProfile.city}, {selfProfile.state}, {selfProfile.country} - {selfProfile.postal_code}</span>
                        </div>
                    </div>

                    
                </div>
                
                <div style={{width:250,justifyContent:'center',alignItems:'center',boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',padding:2,marginTop:20,backgroundColor:'#ffffff',borderRadius:50,textAlign:'center'}}>                
                    <h3 style={{color: '#000',fontWeight:100}}>Your Service agents</h3>
                </div>
                <div style={{width: '100%',display: 'grid',gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',gap: 15,marginTop: 10}}>
                    {selfProfile.agents.map((agent, index) => (
                        <div key={index} style={{backgroundColor: '#fff',borderRadius: 12,padding: 15,boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',display: 'flex',flexDirection: 'column',alignItems: 'center',transition: 'transform 0.2s ease-in-out',cursor: 'pointer',textAlign: 'center'}} onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.03)'} onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}>
                            
                            <img src={agent.profile_pic} alt={agent.name} style={{width: 70, height: 70, borderRadius: '50%', objectFit: 'cover', marginBottom: 8,border: '3px solid #eee'}}/>

                            <h4 style={{ color: '#333', fontSize: 16, fontWeight: 600,marginBottom: 3 }}>{agent.name}</h4>

                            <p style={{ color: '#666', fontSize: 13, marginBottom: 2 }}>{agent.email}</p>
                            <p style={{ color: '#28a745', fontSize: 13, fontWeight: 500, marginBottom: 4 }}>{agent.phone}</p>

                            <p style={{ color: '#007bff', fontSize: 13, fontWeight: 500, marginBottom: 6}}>Services: {agent.service_count}</p>

                            <StarRating rating={agent.av_rating} />
                        </div>
                    ))}
                </div>

                
                <h3 style={{ marginTop: 20, color: '#333' }}>Company Info</h3>
                <div style={{width: '90%',backgroundColor: '#fff',borderRadius: 10,padding: 20,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div style={{ width: '48%', display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdBusiness style={{ color: '#007bff' }} /> 
                            <span>{selfProfile.essentials.company}</span>
                        </div>
                        <div style={{ width: '48%', display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdWork style={{ color: '#28a745' }} /> 
                            <span>Industry ID: {selfProfile.ind_id}</span>
                        </div>
                        <div style={{ width: '48%', display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdLocationOn style={{ color: '#dc3545' }} /> 
                            <span>{selfProfile.essentials.city}, {selfProfile.essentials.state}, {selfProfile.essentials.country}</span>
                        </div>
                        <div style={{ width: '48%', display: 'flex', alignItems: 'center', gap: 10 }}>
                            <MdWork style={{ color: '#007bff' }} /> 
                            <span>GST: {selfProfile.essentials.gst_number}</span>
                        </div>
                    </div>
                </div>
            </div>
                }


            </div>
            
            {taskLoader &&
                <div className='loaderBox'>
                    <BounceLoader color='#FCC100' size={30} />
                </div>          
                       
            }
            <Dialog 
                 isShown={editLogoDialog}
                 title= {'Update company logo'}
                 onCloseComplete={()=>setEditLogoDialog(false)}
                 hasFooter={false} 
                 width={'50%'}
            >
                <Pane display="flex" flexDirection="column" alignItems="center" gap={20}>
                {/* Dropzone Area */}
                {!selectedImage && (
                <Pane
                        {...getRootProps()}
                        width="100%"
                        height={150}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        border="2px dashed #ccc"
                        borderRadius={10}
                        backgroundColor="#f8f9fa"
                        cursor="pointer"
                        textAlign="center"
                        padding={20}
                    >
                        <input {...getInputProps()} />
                        <Text color="#555">Drag & drop your company logo here or click to select. Once selected, use your mouse wheel to zoom in/out.</Text>
                    </Pane>
                )}

                {/* Cropping Area */}
                {selectedImage && (
                    <Pane width="100%" height={300} position="relative">
                        <Cropper
                            image={selectedImage}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                    </Pane>
                )}

                {/* Buttons */}
                {selectedImage && (
                    <Pane display="flex" gap={10}>
                        <Button onClick={() => setSelectedImage(null)}>Reset</Button>
                        <Button appearance="primary" style={{backgroundColor:'#5b07b5',borderColor:'#5b07b5'}} onClick={getCroppedImage}>
                            Crop & Save
                        </Button>
                    </Pane>
                )}

               
            </Pane>
            </Dialog>
        </div>
        
    )

}

export default Profile;