import React, {useState, useEffect, CSSProperties, useCallback } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './MyAgents.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider, StepsTitle } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Tooltip, Popover, Tablist, Tab} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline, MdOutlinePhoneIphone,MdAlternateEmail   } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const MyAgents = () =>{

    const [taskLoader, setTaskLoader] = useState(false);
    
    const [selfProfile, setSelfProfile] = useState(null);

    const [activeAgents, setActiveAgents] = useState(null);

    const [pendingAgents, setPendingAgents] = useState(null);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const [isDialogShown, setIsDialogShown] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);

    const [selectedRevoke, setSelectedRevoke] = useState(null);
    const [revokeConfirm, setRevokeConfirm] = useState(false);

    const fetchProfile = async () =>{
        const sp_unique = localStorage.getItem('sp_unique');
        setTaskLoader(true);
        try{
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/1_selfData.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body : JSON.stringify({sp_unique})
            });

            const responseData = await response.json();
                        
            setSelfProfile(responseData.details);
        }catch(error){  
            console.log(error);
        }finally{
            setTaskLoader(false);
        }
    }

    const fetchAgents = async() =>{
        const sp_unique = localStorage.getItem('sp_unique');
        try{
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/63_fetchAgents.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body:JSON.stringify({sp_unique})
            });

            const responseData = await response.json();

            if (responseData.existing){
                setActiveAgents(responseData.existing);
            }

            if(responseData.pending_approval){
                setPendingAgents(responseData.pending_approval);
            }
        }catch(error){
            console.log(error);
        }
    }
    
    useEffect(() => {
        fetchProfile();
        fetchAgents();
    
        const interval = setInterval(fetchAgents, 20000);
    
        return () => clearInterval(interval); 
    
    }, []);
    

    const StarRating = ({ rating }) => {
            const maxStars = 5;
            const fullStars = Math.floor(rating); 
            const hasHalfStar = rating % 1 !== 0; 
            const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0); 
        
            return (
                <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            
                    {[...Array(fullStars)].map((_, index) => (
                        <MdStar key={index} style={{ color: '#FFD700', fontSize: 20 }} />
                    ))}
        
            
                    {hasHalfStar && <MdStarHalf style={{ color: '#FFD700', fontSize: 20 }} />}
        
            
                    {[...Array(emptyStars)].map((_, index) => (
                        <MdStarOutline key={index} style={{ color: '#FFD700', fontSize: 20 }} />
                    ))}
                </div>
            );
        };


        const confirmRemoveAgent = (sa_unique) => {
            setSelectedAgent(sa_unique);
            setIsDialogShown(true);
        };

        const removeAgent = async () => {
            if (!selectedAgent) return;
            
            const sp_unique = localStorage.getItem('sp_unique');
            setTaskLoader(true);
        
            try {
                const dataString = { sp_unique, sa_unique: selectedAgent };
        
                const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/11_removeAgent.php', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(dataString)
                });
        
                const responseData = await response.json();
        
                if (responseData.success && responseData.success == 1) {
                    toaster.danger("Your association with the service agent has been removed");
                }
            } catch (error) {
                console.log(error);
            } finally {
                setTaskLoader(false);
                setIsDialogShown(false);
                setSelectedAgent(null);
                fetchAgents();
            }
        };

        const confirmRevoke = (sa_unique) =>{
            setRevokeConfirm(true);
            setSelectedRevoke(sa_unique);
        }
        const revokeAgentInvitation = async () =>{
            if (!selectedRevoke) return;

            const sp_unique = localStorage.getItem('sp_unique');
            setTaskLoader(true);
            try{
              const dataString = {
                sp_unique,
                sa_unique:selectedRevoke
              }
      
              const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/63b_agentRevokeInvitation.php',{
                method:'POST',
                headers:{
                  'Content-Type':'application/json'
                },
                body:JSON.stringify(dataString)
              });
      
              const responseData = await response.json();
      
              if(responseData.success == 1){
                toaster.success("Invitation has been revoked", 2000, "#fff", "#000");
                
                fetchAgents();
                setSelectedRevoke(null);
                setRevokeConfirm(false);
              }else{
                toaster.danger(`${responseData.message}`, 2000, "#fff", "#000");
              }
            }catch(error){
      
            }finally{
                setTaskLoader(false);
            }
          }
      
          const resendAgentOtp = async (sa_unique) =>{
            const sp_unique = localStorage.getItem('sp_unique');
            setTaskLoader(true);
            try{
              const dataString = {
                sp_unique,
                sa_unique
              }
              
              console.log(dataString);
              const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/63a_agentOtpResend.php',{
                method:'POST',
                headers:{
                  'Content-Type':'application/json'
                },
                body:JSON.stringify(dataString)
              });
      
              const responseData = await response.json();
              
              if(responseData.success == 1){
                toaster.success("OTP has been sent again", 2000, "#fff", "#000");
              }else{
                toaster.danger(`${responseData.message}`, 2000, "#fff", "#000");
              }
            }catch(error){
      
            }finally{
              setTaskLoader(false);
            }
          }

    return(
        <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
               <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'My Agents'} />                                    
              </div>
              <div style={{ width: '90%', margin: 'auto', padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>

                     <Tablist style={{ display: 'flex', justifyContent: 'center', gap: '15px', marginBottom: 20,backgroundColor: '#f9f9f9',padding: '8px',borderRadius: '10px',boxShadow: '0px 4px 12px rgba(0,0,0,0.1)'}}>
                        <Tab id="active" isSelected={selectedIndex === 0} onSelect={() => setSelectedIndex(0)}style={{backgroundColor: selectedIndex === 0 ? '#FCC100' : 'transparent',color: selectedIndex === 0 ? '#fff' : '#3f3d56',padding: '10px 20px',borderRadius: '8px',fontWeight: 'bold',transition: '0.3s ease',cursor: 'pointer'}}>
                            Active Agents
                        </Tab>
                        
                        <Tab id="pending" isSelected={selectedIndex === 1} onSelect={() => setSelectedIndex(1)}style={{backgroundColor: selectedIndex === 1 ? '#54167a' : 'transparent',color: selectedIndex === 1 ? '#fff' : '#3f3d56',padding: '10px 20px',borderRadius: '8px',fontWeight: 'bold',transition: '0.3s ease',cursor: 'pointer',display: 'flex',alignItems: 'center',gap: '5px'}}>
                            Pending Agents 
                            <span style={{display: 'flex',justifyContent: 'center',alignItems: 'center',padding: '5px',borderRadius: '50%',backgroundColor: '#e84855',color: '#fff',fontSize: '12px',fontWeight: 'bold',width: '15px',height: '15px'}}>
                                {pendingAgents && pendingAgents.length > 0 ? pendingAgents.length : 0}
                            </span>
                        </Tab>
                    </Tablist>


        <Pane>
            {selectedIndex === 0 && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px', justifyContent: 'center', alignItems: 'stretch' }}>
                    {activeAgents && activeAgents.map((item, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '12px', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.15)', backgroundColor: '#f9f9f9', textAlign: 'center', maxHeight: '270px' }}>
                            <div style={{ width: 70, height: 70, borderRadius: 50, backgroundColor: '#FCC100', padding: 2 }}>
                                <img style={{ width: '100%', height: '100%', borderRadius: 50, objectFit: 'cover' }} src={item.profile_pic} />
                            </div>
                            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding: 5, textAlign: 'center', fontSize: 12, backgroundColor: '#fff', borderRadius: 20, boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.29)', fontWeight: 'bold', marginTop: 10 }}>
                                {item.name}
                            </div>
                            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                                {item.sa_unique}
                            </div>
                            <StarRating rating={item.av_rating} />
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                                <MdOutlinePhoneIphone size={20} color="#3f70b0" style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 12 }}>{item.phone ? item.phone : '---'}</span>
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                                <MdAlternateEmail size={20} color="#54167a" style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 12 }}>{item.email ? item.email : '---'}</span>
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                            <Button intent="danger" onClick={() => confirmRemoveAgent(item.sa_unique)}>
                                Remove Agent
                            </Button>
                              
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {selectedIndex === 1 && (
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px', justifyContent: 'center', alignItems: 'stretch' }}>
                    {pendingAgents && pendingAgents.map((item, index) => (
                        <div key={index} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '12px', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0,0,0,0.15)', backgroundColor: '#f9f9f9', textAlign: 'center', maxHeight: '300px',maxWidth:'250px' }}>
                            <div style={{ width: 70, height: 70, borderRadius: 50, backgroundColor: '#FCC100', padding: 2 }}>
                                <img style={{ width: '100%', height: '100%', borderRadius: 50, objectFit: 'cover' }} src={item.profile_pic} />
                            </div>
                            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding: 5, textAlign: 'center', fontSize: 12, backgroundColor: '#fff', borderRadius: 20, boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.29)', fontWeight: 'bold', marginTop: 10 }}>
                                {item.name}
                            </div>
                            <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                                {item.sa_unique}
                            </div>
                            <StarRating rating={item.av_rating} />
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                                <MdOutlinePhoneIphone size={20} color="#3f70b0" style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 12 }}>{item.phone ? item.phone : '---'}</span>
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20 }}>
                                <MdAlternateEmail size={20} color="#54167a" style={{ marginRight: 5 }} />
                                <span style={{ fontSize: 12 }}>{item.email ? item.email : '---'}</span>
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 10, borderRadius: 20 }}>
                                The agent hasn't responded to your previous invitation                              
                            </div>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 5, textAlign: 'center', fontSize: 12, borderRadius: 20,gap:10 }}>
                            <Button intent="danger" onClick={() => confirmRevoke(item.sa_unique)}>
                                Delete
                            </Button>
                            <Button intent="success" onClick={() => resendAgentOtp(item.sa_unique)}>
                                Resend OTP
                            </Button>
                              
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </Pane>

              </div>
            </div>

            {taskLoader &&
                <div className='loaderBox'>
                    <BounceLoader color='#FCC100' size={30} />
                </div>          
                                   
            }

<Dialog
    isShown={isDialogShown}
    title="Remove Agent"
    intent="danger"
    onCloseComplete={() => setIsDialogShown(false)}
    confirmLabel="Remove"
    cancelLabel="Cancel"
    onConfirm={removeAgent}
>
    Are you sure you want to remove this agent? This action cannot be undone.
</Dialog>
<Dialog
    isShown={revokeConfirm}
    title="Revoke invitation"
    intent="danger"
    onCloseComplete={() => setRevokeConfirm(false)}
    confirmLabel="Remove"
    cancelLabel="Cancel"
    onConfirm={revokeAgentInvitation}
>
    Are you sure you want to revoke this invitation?
</Dialog>

        </div>
    )
}

export default MyAgents;