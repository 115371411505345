import React, {useState, useEffect, CSSProperties, useCallback } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";

import './MyClients.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Tooltip, Popover} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
const MyClients = () =>{

    const [taskLoader, setTaskLoader] = useState(false);
    const [basicLoader, setBasicLoader] = useState(false);
    const [selfProfile, setSelfProfile] = useState(null);
    const [offset, setOffset] = useState(0);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [confirmDialog, setConfirmDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [showLinked, setShowLinked] = useState(false);
    const [showLinkable, setShowLinkable] = useState(false);

    
    const [selectedImage, setSelectedImage] = useState(null);
    useEffect(()=>{
        fetchSelfProfile();        
    },[]);

    const fetchSelfProfile = async ()=>{
        const sp_unique = localStorage.getItem('sp_unique');
        setTaskLoader(true);

        try{
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/1_selfData.php',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({sp_unique})
            });

            const responseData = await response.json();

            setSelfProfile(responseData.details);
        }catch(error){
            console.log(error)
        }finally{
            setTaskLoader(false);
        }
    }

    useEffect(()=>{
        if(selfProfile){
            fetchClients();
        }
    },[selfProfile]);

    const fetchClients = async ()=>{

        setTaskLoader(true);
        const sp_unique = localStorage.getItem('sp_unique');
        const ind_id = selfProfile.ind_id;
        try{
            const dataString = {
                sp_unique,
                ind_id,
                offset
            }
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/12_fetchClients.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body:JSON.stringify(dataString)
            });

            const responseData = await response.json();
            if (responseData.customers && responseData.customers.length > 0) {
                setClients((prevClients) => [...prevClients, ...responseData.customers]);
                setOffset(parseInt(offset) + 10); 
              }else{
                toaster.warning("That's all we have in our system");
              }
        }catch(error){
            console.log(error);
        }finally{
            setTaskLoader(false);
        }

    }

    function abbreviateNumber(value) {
        let newValue = value;
        if (value >= 1000 && value < 1000000) {
          newValue = Math.floor(value / 1000) + 'K';
        } else if (value >= 1000000 && value < 1000000000) {
          newValue = Math.floor(value / 1000000) + 'M';
        } else if (value >= 1000000000) {
          newValue = Math.floor(value / 1000000000) + 'B';
        }
        return newValue;
      }
    
const formatDate = (dateString) => {
            if (!dateString) return "Invalid date"; // Handle empty input
        
            const inputDate = moment(dateString, "YYYY-MM-DD HH:mm:ss", true); // Parse correctly with strict mode
            const now = moment();
        
            if (!inputDate.isValid()) return "Invalid date format"; // Handle invalid dates
        
            // Format the date to "Mar 20, 2025"
            const formattedDate = inputDate.format("MMM DD, YYYY");
        
            // Calculate difference
            const diffInSeconds = now.diff(inputDate, "seconds");
            const diffInMinutes = now.diff(inputDate, "minutes");
            const diffInHours = now.diff(inputDate, "hours");
            const diffInDays = now.diff(inputDate, "days");
            const diffInWeeks = now.diff(inputDate, "weeks");
            const diffInMonths = now.diff(inputDate, "months");
            const diffInYears = now.diff(inputDate, "years");
        
            let timeDepth;
            if (Math.abs(diffInSeconds) < 60) {
                timeDepth = `${Math.abs(diffInSeconds)} sec${Math.abs(diffInSeconds) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInMinutes) < 60) {
                timeDepth = `${Math.abs(diffInMinutes)} min${Math.abs(diffInMinutes) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInHours) < 24) {
                timeDepth = `${Math.abs(diffInHours)} hour${Math.abs(diffInHours) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInDays) < 7) {
                timeDepth = `${Math.abs(diffInDays)} day${Math.abs(diffInDays) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInWeeks) < 4) {
                timeDepth = `${Math.abs(diffInWeeks)} week${Math.abs(diffInWeeks) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInMonths) < 12) {
                timeDepth = `${Math.abs(diffInMonths)} month${Math.abs(diffInMonths) !== 1 ? 's' : ''}`;
            } else {
                timeDepth = `${Math.abs(diffInYears)} year${Math.abs(diffInYears) !== 1 ? 's' : ''}`;
            }
        
            return `${formattedDate} (${now.isAfter(inputDate) ? `${timeDepth} ago` : `${timeDepth} left`})`;
        };
        
        const renderLocation = (location) =>{      

            return (
                <span style={{padding:3,justifyContent:'center',alignItems:'center'}}>
                    {location.loc_name? `${location.loc_name}, ` :''}
                    {location.apartment? `${location.apartment}, ` :''}
                    {location.apt_name? `${location.apt_name}, ` :''}
                    {location.locality? `${location.locality}, ` :''}
                    {location.city? `${location.city}, ` :''}
                    {location.pin_zip? `${location.pin_zip}, ` :''}
                </span>
            )
        }

const deleteLink = (link_id) => {
    // Find the product in clients based on link_id
    const client = clients.find(client => client.products.some(product => product.details.link_id === link_id));
    if (!client) return;

    const product = client.products.find(product => product.details.link_id === link_id);
    
    if (product) {
        setSelectedProduct(product); // Store product details
        setConfirmDialog(true); // Show confirmation dialog
    }
};

const confirmDeleteLink = async () => {
    if (!selectedProduct) return;

    const sp_unique = localStorage.getItem('sp_unique');
    setTaskLoader(true);

    try {
        const dataString = { sp_unique, link_id: selectedProduct.details.link_id };
        const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/22_deleteLink.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataString)
        });

        const responseData = await response.json();

        if (responseData.success === 1) {
            executeDeleteLink(selectedProduct.details.link_id);
            setConfirmDialog(false);
            setSelectedProduct(null);
        }
    } catch (error) {
        console.log(error);
    } finally {
        setTaskLoader(false);
    }
};

const executeDeleteLink = (link_id) => {
    setClients(prevClients => {
        return prevClients.map(client => {
            const productToMove = client.products.find(product => product.details.link_id === link_id);
            if (!productToMove) return client;

            const updatedClient = {
                ...client,
                products: client.products.filter(product => product.details.link_id !== link_id),
                linkable: [...client.linkable, productToMove]
            };

            if (selectedClient?.cus_unique === client.cus_unique) {
                setSelectedClient(updatedClient);
            }

            return updatedClient;
        });
    });
};

const requestInvite = async (pid) =>{
    
    const sp_unique = localStorage.getItem('sp_unique');
    const cus_unique = selectedClient.cus_unique;

    setTaskLoader(true);

    try{
        const dataString = {
            pid,
            sp_unique,
            cus_unique
        }

        const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/21_updateAddInvite.php',{
            method:'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(dataString)
        });

        const responseData = await response.json();
        
        if(responseData.success == 1){
            toaster.success("Invitation has been sent successfully");
        }else{  
            toaster.danger(responseData.message);
        }
    }catch(error){
        console.log(error);
    }finally{
        setBasicLoader(false);
    }   

}
        

    return(
        <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
               <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Clients : My Clients'} />                  
              </div>
              <div style={{ width: '90%', margin: 'auto', padding: 20, backgroundColor: '#ffffff', borderRadius: 10, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 20, alignItems: 'stretch'}}>
                    {clients && clients.map((item, index) => (
                        <div className='client-card' style={{marginLeft:10}} onClick={()=>setSelectedClient(item)}>
                            
                            <div style={{position: 'relative',width: 60, height: 60, borderRadius: '50%', overflow: 'hidden', marginBottom: 10,border: '2px solid #ddd'}}>
                                <img src={item.profile_pic ? item.profile_pic : 'https://cdn.myservicecircle.app/icon.jpg'} alt={item.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} className='Client-card-img'/>
                            </div>

                            <h3 style={{color: '#333', fontSize: '16px', fontWeight: 600, marginBottom: 5}}><MdPerson style={{ marginRight: 5, color: "#5b1cba" }} />{item.name} </h3>
                            <p style={{color: '#777', fontSize: '12px', marginBottom: 5}}>
                                <strong>ID:</strong> {item.cus_unique}
                            </p>

                            <p style={{color: '#444', fontSize: '14px', marginBottom: 5}}><MdBuild style={{ marginRight: 5, color: "#a21cba" }} />                            {item.srv_count} Services</p>

                            <p style={{color: '#444', fontSize: '14px', marginBottom: 5}}><MdShoppingCart style={{ marginRight: 5, color: "#1c56ba" }} />{item.products.length} Linked Products</p>

                            <p style={{color: '#444', fontSize: '14px'}}><MdLinkOff style={{ marginRight: 5, color: "#e37910" }} />{item.linkable.length} Linkable Products</p>
                        </div>
                    ))}
                </div>

                <div style={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    marginTop: 20
                }}>
                    <button style={{backgroundColor: '#007bff',color: '#fff',padding: '10px 20px',fontSize: '14px',border: 'none',borderRadius: 5,cursor: 'pointer',transition: 'all 0.2s ease-in-out',boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.15)',display: 'flex',alignItems: 'center',gap: 5}} 
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FCC100'} 
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007bff'}
                    onClick={fetchClients}
                    >
                        <MdExpandMore size={20} /> Load More
                    </button>
                </div>




            </div>
           

            <Dialog
            isShown={selectedClient}
            title={'Client Details'}
            onCloseComplete={() => setSelectedClient(null)}
            hasFooter={false}
            width={'65%'}
            height={'70%'}
            backgroundColor='#FCC100'
        >
            {selectedClient && (
                <div style={{ padding: 20 }}>
                    {/* Profile Picture & Basic Info */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center',gap:-20 }}>
                        <div style={{
                            width: 100, height: 100, borderRadius: 50, padding: 2, 
                            backgroundColor: '#FCC100', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
                        }}>
                            <img src={selectedClient.profile_pic || 'https://cdn.myservicecircle.app/icon.jpg'} 
                                alt="Client" 
                                style={{ width: '100%', height: '100%', borderRadius: '50%' }} 
                            />
                        </div>
                        <h2 style={{ marginTop: 10, color: '#333', fontSize: 20, margin: '2px 0', lineHeight: '1.2' }}>{selectedClient.name}</h2>
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:10, flexDirection:'row'}}>
                            <div className='countsBar' style={{display:'flex',padding:5,backgroundColor:'#ffffff',borderRadius:20}}>ID: {selectedClient.cus_unique}</div>
                            <div className='countsBar' style={{display:'flex',padding:5,backgroundColor:'#ffffff',borderRadius:20}}>Total Services: <span style={{padding:5,borderRadius:20,backgroundColor:'#4b0699',color:'#fff'}}>{abbreviateNumber(selectedClient.srv_count)}</span></div>
                            <div className='countsBar' style={{display:'flex',padding:5,backgroundColor:'#ffffff',borderRadius:20}}>Contracts: <span style={{padding:5,borderRadius:20,backgroundColor:'#8a0699',color:'#fff'}}>{abbreviateNumber(selectedClient.contracts)}</span></div>
                            <div className='countsBar' style={{display:'flex',padding:5,backgroundColor:'#ffffff',borderRadius:20}}>Last service: <span style={{padding:5,borderRadius:20,backgroundColor:'#508a06',color:'#fff'}}>{selectedClient.last_serv.req_timestamp ? formatDate(selectedClient.last_serv.req_timestamp) : 'Never'}</span></div>
                        </div>
                        
                    </div>

                    
                    <div style={{ marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', backgroundColor: '#f5f5f5', padding: 10, borderRadius: 5 }}
                            onClick={() => setShowLinked(!showLinked)}
                        >
                            <h3 style={{ margin: 0, fontSize: 16, color: '#333' }}>Linked Products ({selectedClient.products.length})</h3>
                            {showLinked ? <MdExpandLess size={20} color="#007bff" /> : <MdExpandMore size={20} color="#007bff" />}
                        </div>
                        
                            <Carousel
                                
                                showArrows={true}
                                showThumbs={true}
                                showIndicators={false}
                                centerMode={true}
                                centerSlidePercentage={selectedClient.products.length > 2 ? 33.33 : 100} 
                                infiniteLoop={selectedClient.products.length > 2} 
                                emulateTouch={true}
                                autoPlay={false}
                            >
                                {showLinked &&
                                    selectedClient.products.map((product, index) => (
                                        <>
                                        <div key={index} style={{ padding: 15, backgroundColor: '#fff', borderRadius: 8, boxShadow: '0 2px 5px rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center', margin: '0 5px', height: 'auto' }} className='prdcard'>
                                            <img src={product.details.icon_url} alt={product.details.name} style={{ width: 50, height: 50, objectFit: 'contain' }} />
                                            <h4 style={{ margin: '10px 0 5px', fontSize: 14, color: '#333', fontWeight: 600 }}>{product.details.nick_name || product.details.name}</h4>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#5f3182',padding:5,borderRadius:5}}>                                           
                                                <p style={{ margin: 0, fontSize: 10, color: '#fff' }}>{product.details.description}</p>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#433182',padding:5,borderRadius:5,marginTop:10}}>
                                            <p style={{ margin: 0, fontSize: 10, color: '#fff' }}>{renderLocation(product.location)}</p>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#f2f2f2',padding:5,borderRadius:5,marginTop:10,flexDirection:'row',gap:10,overflow:'hidden',flexWrap:'wrap'}}>
                                                {product.album && product.album.map((album,prdIndex)=>(
                                                    <>
                                                    <Popover
                                                        key={prdIndex}
                                                        content={
                                                            <Pane padding={10} display="flex" alignItems="center" justifyContent="center">
                                                                <img src={album.prd_pic} style={{ width: 200, height: 200, borderRadius: 10 }} />
                                                            </Pane>
                                                        }
                                                    >
                                                        <img
                                                            src={album.prd_pic}
                                                            style={{ width: 50, height: 50, borderRadius: 5, objectFit: "cover", cursor: "pointer" }}
                                                           
                                                        />
                                                    </Popover>
                                                    </>
                                                ))}
                                            </div>
                                            <Button iconBefore={MdLinkOff} intent="danger" appearance="minimal" style={{ marginTop: '10px', fontSize: 12 }} onClick={() => deleteLink(product.details.link_id)}>
                                                Unlink Association
                                            </Button>
                                        </div>
                                        </>
                                    ))
                                }
                            </Carousel>
                        


                    </div>

                    <div style={{ marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', backgroundColor: '#f5f5f5', padding: 10, borderRadius: 5 }}
                            onClick={() => setShowLinkable(!showLinkable)}
                        >
                            <h3 style={{ margin: 0, fontSize: 16, color: '#333' }}>Linkable Products ({selectedClient.linkable.length})</h3>
                            {showLinkable ? <MdExpandLess size={20} color="#007bff" /> : <MdExpandMore size={20} color="#007bff" />}
                        </div>
                        {selectedClient && (
                        <Carousel
                            showArrows={true}
                            showThumbs={false}
                            showIndicators={false}
                            centerMode={selectedClient.linkable.length > 2} 
                            centerSlidePercentage={selectedClient.linkable.length > 2 ? 33.33 : 100} 
                            infiniteLoop={selectedClient.linkable.length > 2} 
                            emulateTouch={true}
                            autoPlay={false}
                        >
                            {showLinkable &&
                                selectedClient.linkable.map((product, index) => (
                                    <div key={index} style={{ padding: 15, backgroundColor: '#fff', borderRadius: 8,boxShadow: '0 2px 5px rgba(0,0,0,0.1)',display: 'flex', alignItems: 'center',flexDirection: 'column',textAlign: 'center',margin: '0 5px', height: '180px'}}>
                                        <img src={product.details.icon_url} alt={product.details.name} style={{ width: 50, height: 50, objectFit: 'contain' }} />
                                        <h4 style={{ margin: '10px 0 5px', fontSize: 14, color: '#333', fontWeight: 600 }}>{product.details.nick_name || product.details.name}</h4>
                                        <p style={{ margin: 0, fontSize: 12, color: '#666', height: '40px', overflow: 'hidden' }}>{product.details.description}</p>
                                        <Button iconBefore={MdLink} intent="success" appearance="minimal" style={{ marginTop: '10px', fontSize: 12 }} onClick={()=>requestInvite(product.details.prdmainid)}>Request to associate</Button>
                                    </div>
                                ))
                            }
                        </Carousel>
                        )}

                    </div>
                </div>
            )}
        </Dialog>
        
       

        <Dialog
        isShown={confirmDialog}
        title="Confirm Unlink"
        onCloseComplete={() => setConfirmDialog(false)}
        hasFooter={false}
        width={'40%'}
    >
        {selectedProduct && (
            <div style={{ textAlign: 'center', padding: 10 }}>
                <img src={selectedProduct.details.icon_url} 
                    alt={selectedProduct.details.name} 
                    style={{ width: 50, height: 50, objectFit: 'contain', marginBottom: 10 }} 
                />
                <h3 style={{ fontSize: 16, color: '#333' }}>{selectedProduct.details.nick_name || selectedProduct.details.name}</h3>
                <p style={{ fontSize: 14, color: '#666' }}>{selectedProduct.details.description}</p>

                <div style={{ display: 'flex', justifyContent: 'center', gap: 10, marginTop: 20 }}>
                    <Button intent="danger" onClick={confirmDeleteLink}>Unlink</Button>
                    <Button intent="none" onClick={() => setConfirmDialog(false)}>Cancel</Button>
                </div>
            </div>
        )}
    </Dialog>
            {taskLoader &&
                <div className='loaderBox'>
                    <BounceLoader color='#FCC100' size={30} />
                </div>                                             
            }
        </div>
    )
}

export default MyClients;