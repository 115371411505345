import React, {useState, useEffect, CSSProperties, useCallback, useRef } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './ComplaintReports.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider, StepsTitle } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Popover, Tablist, Tab} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose, MdLocalShipping } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline, MdOutlinePhoneIphone,MdCalendarMonth    } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdCalendarToday, MdInfo, MdDevices, MdAssignment, MdDownload } from "react-icons/md";
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const themeColors = ["#993366", "#F4C430", "#2A52BE", "#DA4167", "#FCC100", "#49bd06", "#5e06bd", "#bd0667"];

const RevenueReport = () =>{
    const [taskLoader, setTaskLoader] = useState(false);
    const [allData, setAllData] = useState(null);
    const [allInvoices, setAllInvoices] = useState([]);
    const [offset,setOffset] = useState(0);
    const [singleInvoice, setSingleInvoice] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [showTemplate, setShowTemplate] = useState(false);
    const [templateSelected, setTemplateSelected] = useState(null)
    const [invoicePreview, setShowInvoicePreview] = useState(false);
    const [templateContent, setTemplateContent] = useState(null);
      const [dateRange, setDateRange] = useState([null, null]);
      const [startDate, endDate] = dateRange;


      const generatePDF = () => {
        setTimeout(() => { // Ensure DOM is fully updated before running html2canvas
          const contractElement = document.getElementById("printable-contract");
          
          if (!contractElement) {
            console.error("Element #printable-contract not found");
            return;
          }
      
          // Save original styles before modifying them
          const originalHeight = contractElement.style.height;
          const originalOverflow = contractElement.style.overflow;
      
          // Expand content to capture everything for PDF
          contractElement.style.height = "auto";
          contractElement.style.maxHeight = "none";
          contractElement.style.overflow = "visible";
      
          html2canvas(contractElement, {
            scale: 2,
            useCORS: true,
            allowTaint: true
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
      
            pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
            pdf.save(`Invoice_${singleInvoice?.invoice_unique}.pdf`);
      
            // Restore original styles
            contractElement.style.height = originalHeight;
            contractElement.style.overflow = originalOverflow;
          });
        }, 500); // Small delay to ensure rendering is complete
      };
      
      
     
      const fetchAllData = async (start_date = null, end_date = null) => {
        const sp_unique = localStorage.getItem('sp_unique');
        setTaskLoader(true);
    
        try {
            const dataString = {
                sp_unique,
                start_date,
                end_date
            }
            console.log(dataString);
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/00_revenueReports_1.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ sp_unique, start_date, end_date })
            });
    
            const responseData = await response.json();  
    
            if (responseData.success == 1) {  
                setAllData(responseData);
                //console.log("API Data:", JSON.stringify(responseData));
            } else {
                toaster.danger("No records found");
            }
        } catch (error) {
            console.log("Error fetching revenue data:", error);
        } finally {
            setTaskLoader(false);
        }
    };
    

    const fetchAllInvoices = async ()=>{
        const sp_unique = localStorage.getItem('sp_unique');
        setTaskLoader(true);

        try{

          const dataString = {
            sp_unique,
            offset
          }
          console.log(dataString);
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/31_fetchAllInvoices.php',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                  sp_unique,
                  offset:offset
                })
            });
            const responseData = await response.json();

            if(responseData.success == 1){
                if(responseData.invoices.length>0){
                    setAllInvoices(prevInv =>[...prevInv,...responseData.invoices]);
                    setOffset(prevOffset => prevOffset + responseData.invoices.length);
                }else{
                    setHasMore(false);
                    toaster.warning("That's all the data we have in our system");
                }
            }else{
                toaster.danger("Something went wrong...");
            }
            
        }catch(error){
            console.log(error);
        }finally{
            setTaskLoader(false);
        }
    }
    
    useEffect(() => {
        fetchAllData();
        fetchAllInvoices();
    }, []);
    
    const getPastDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() - days);
        return formatDate(date);
      };
      
      const getPreviousMonthStart = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        date.setDate(1);
        return formatDate(date);
      };
      
      const getPreviousMonthEnd = () => {
        const date = new Date();
        date.setDate(0);
        return formatDate(date);
      };
      
      const formatDate = (date) => {
        return date.toISOString().split("T")[0];
      };

      const formatMonth = (monthString) => {
        const [year, month] = monthString.split("-");
        return new Date(year, month - 1).toLocaleString("default", { month: "long", year: "numeric" });
      };

      const formatDay = (dayString) => {
        const date = new Date(dayString);
        return date.toLocaleDateString("en-GB", { day: "2-digit", month: "short" }); 
    };
    
    const pieChartData = {
        labels: ["Net Revenue", "Discounts", "Taxes"],
        datasets: [{
            data: [
                parseFloat(allData?.net_revenue) || 0, 
                parseFloat(allData?.discounts_applied) || 0, 
                parseFloat(allData?.taxes_applied) || 0
            ],
            backgroundColor: ["#2A52BE", "#DA4167", "#F4C430"]
        }]
    };
    
    const lineChartData = {
        labels: allData?.data_type === "monthly" 
            ? allData?.monthly_revenue?.map(item => formatMonth(item.month)) || []
            : allData?.daily_revenue?.map(item => formatDay(item.day)) || [],
            
        datasets: [{
            label: "Revenue",
            data: allData?.data_type === "monthly" 
                ? allData?.monthly_revenue?.map(item => parseFloat(item.monthly_revenue)) || []
                : allData?.daily_revenue?.map(item => parseFloat(item.daily_revenue)) || [],
            borderColor: "#993366",
            backgroundColor: "rgba(153, 51, 102, 0.2)",
            fill: true
        }]
    };
    
    useEffect(() => {
      if (templateSelected && singleInvoice) {
        setShowTemplate(false);
        setShowInvoicePreview(true);
        generateTemplate(); 
      }
    }, [templateSelected, singleInvoice]);

    function numToWords(n) {
      const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
      const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
      const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  
      if (n === 0) return "Zero";
      
      let words = "";
  
      if (n >= 10000000) {
          words += numToWords(Math.floor(n / 10000000)) + " Crore ";
          n %= 10000000;
      }
      if (n >= 100000) {
          words += numToWords(Math.floor(n / 100000)) + " Lakh ";
          n %= 100000;
      }
      if (n >= 1000) {
          words += numToWords(Math.floor(n / 1000)) + " Thousand ";
          n %= 1000;
      }
      if (n >= 100) {
          words += ones[Math.floor(n / 100)] + " Hundred ";
          n %= 100;
      }
      if (n >= 20) {
          words += tens[Math.floor(n / 10)] + " ";
          n %= 10;
      } else if (n >= 10) {
          words += teens[n - 10] + " ";
          n = 0;
      }
      if (n > 0) {
          words += ones[n] + " ";
      }
  
      return words.trim();
  }
  
  // Function to convert full Rupees + Paise
  function convertRupeesToWords(amount) {
      let rupees = Math.floor(amount);
      let paise = Math.round((amount - rupees) * 100);
  
      let rupeesWords = rupees > 0 ? numToWords(rupees) + " Rupees" : "";
      let paiseWords = paise > 0 ? numToWords(paise) + " Paise" : "";
  
      return (rupeesWords + (paiseWords ? " and " + paiseWords : "") + " Only").trim();
  }
    const templates = [
        { id: 'defaultTemplate', description:'Common basic template. Rows for discounts and taxes will be displayed below the other invoice items in the same table. Ideal for many countries and their tax invoice laws.', url: 'https://cdn.myservicecircle.app/invoice_templates/template_1.png', name: 'Default Template'  },
        { id: 'ukTemplate', description:'Common basic template. Discount and taxes will be displayed separately above the Total. Ideal for many countries and their tax invoice laws.', url: 'https://cdn.myservicecircle.app/invoice_templates/template_2.png', name: 'UK, US, EU & Other' },
        { id: 'indiaTemplate', description:'Ideal template for Indian tax system (GST) with a separate column for SAC/HSN code. Discounts and tax rows will be displayed below the invoice items', url: 'https://cdn.myservicecircle.app/invoice_templates/template_ind_3.png', name: 'Indian GST' }
      ];
      const formatMySQLDate = (mysqlDate) => {
        const date = new Date(mysqlDate);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
      
        const getOrdinalSuffix = (n) => {
          const suffixes = ["th", "st", "nd", "rd"];
          const remainder = n % 10;
          return (n > 3 && n < 21) || remainder > 3 ? "th" : suffixes[remainder];
        };
      
        return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
      };
    
      const generateTemplate = () => {
        let templateJSX;
        if (templateSelected === "defaultTemplate") {
          templateJSX = (
            <div className="print-preview-overlay">
              <div className='print-preview-container' style={{width:'80%',height:'90%',padding:'10px'}}>
                <div className='print-content ' style={{overflowY:'auto'}}>
                <div id='printable-contract' style={{ width: "90%", padding: "20px", margin: "auto", border: "1px solid #000", fontFamily: "Arial, sans-serif" }}>
      
                {/* Header */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "2px solid #000", paddingBottom: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "80px", height: "80px", borderRadius: "50%", border: "1px solid #b8b8b8", textAlign: "center", lineHeight: "80px" }}>
                      <img src={singleInvoice.sp_data.logo} style={{width:'100%',height:'100%',borderRadius:'50%'}} /> 
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column'}}>
                      <span style={{ marginLeft: "10px",fontSize:18,fontWeight:'bold' }}>{singleInvoice.sp_data.entity_name}</span>
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.address}</span>
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.city} - {singleInvoice.sp_data.postal_code}, {singleInvoice.sp_data.state}</span>
                      {singleInvoice.sp_data.tax_reg_name &&                       
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.tax_reg_name} : {singleInvoice.sp_data.tax_reg_number}</span>
                      }
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-end", flexDirection:'column'}}>
                  <h2 style={{ fontWeight: "bold" }}>INVOICE</h2>
                  <div style={{ border: "1px solid #000", padding: "10px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Invoice #</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.invoice_unique}</td>
                      </tr>
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Date</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{formatMySQLDate(singleInvoice.invoice_date)}</td>
                      </tr>  
                      {singleInvoice.req_unique &&
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Ref No.</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.req_unique}</td>
                      </tr>
                      }                   
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Client ID</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.cus_data.cus_unique}</td>
                      </tr>  
                     
                    </tbody>
                  </table>
                </div>
                  </div>
                  
                </div>

                
                <div style={{ marginTop: "20px",display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: "bold" }}>Bill To</h3>
                  {singleInvoice.bill_to ?
                  <>
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.bill_to}</span>
                  </>
                  :
                  <>
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.cus_data.name}</span>
                  </>
                  
                  }
                                    
                  <span style={{fontSize:12}}>{singleInvoice.cus_data.location.apartment}, {singleInvoice.cus_data.location.apt_name}, {singleInvoice.cus_data.location.locality}</span>   
                  <span style={{fontSize:12}}>{singleInvoice.cus_data.location.city}, {singleInvoice.cus_data.location.pin_zip}</span>  
                  {singleInvoice.cus_data.location.tax_no &&                  
                    <span style={{fontSize:12}}>TAX No.:{singleInvoice.cus_data.location.tax_no}</span>                  
                  }                
                  
                </div>

               
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                  <thead>
                    <tr style={{ background: "#2A52BE", color: "#fff", textAlign: "left" }}>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Item Description</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Unit Price</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Qty/Hrs</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {singleInvoice.invoice_items.map((item, index) => (
                      <tr key={index} style={{ borderBottom: "1px solid #000" }}>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>{item.item_name}</td>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "right" }}>{singleInvoice.sp_data.currency}{item.amount}</td>
                        <td style={{ padding: "8px", border: "1px solid #000" }}>{item.quantity || "-"}</td>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "right" }}>{singleInvoice.sp_data.currency}{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Total Amount */}
                <div style={{ textAlign: "right", fontWeight: "bold", marginTop: "10px" }}>
                  <p>Total: {singleInvoice.sp_data.currency}{singleInvoice.total_amount}</p>
                </div>

                {/* Declaration */}
                <div style={{ marginTop: "20px", border: "1px solid #000", padding: "10px" }}>
                  <h4 style={{ background: "#2A52BE", color: "#fff", padding: "5px" }}>Declaration</h4>
                  <p>{singleInvoice.declaration || "No declaration provided."}</p>
                </div>

                {/* Footer */}
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <p>Thank you for your business!</p>
                  <div style={{width:'100%',display:'flex',fontSize:12,alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                  <span style={{fontSize:12,fontWeight:'bold'}}>{singleInvoice.sp_data.entity_name}</span>
                  <span style={{fontSize:12}}>{singleInvoice.sp_data.address}</span>
                  </div>
                </div>
              </div>
                </div>
                <div style={{width:'90%',justifyContent:'center',alignItems:'center',display:'flex',gap:20}}>
                    <Button appearance='primary' intent='primary' onClick={generatePDF}>Download</Button>
                    
                </div>
              </div>
              <div style={{width:40,height:40,justifyContent:'center',alignItems:'center',display:'flex',top:'5%',right:'5%',zIndex:999999,position:'fixed',cursor:'pointer'}} onClick={()=>{setTemplateContent(null);setTemplateSelected(null);setSingleInvoice(null);setShowTemplate(false)}}>
                  <MdClose size={50} color='#fff' />
              </div>
            </div>
          );
        } else if (templateSelected === "ukTemplate") {
          templateJSX = (
            <div className="print-preview-overlay">
              <div className='print-preview-container' style={{width:'80%',height:'90%',padding:'10px'}}>
                <div className='print-content ' style={{overflowY:'auto'}}>
                <div id='printable-contract' style={{ width: "90%", padding: "20px", margin: "auto", border: "1px solid #000", fontFamily: "Arial, sans-serif" }}>
      
                {/* Header */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "2px solid #000", paddingBottom: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "80px", height: "80px", borderRadius: "50%", border: "1px solid #b8b8b8", textAlign: "center", lineHeight: "80px" }}>
                      <img src={singleInvoice.sp_data.logo} style={{width:'100%',height:'100%',borderRadius:'50%'}} /> 
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column'}}>
                      <span style={{ marginLeft: "10px",fontSize:18,fontWeight:'bold' }}>{singleInvoice.sp_data.entity_name}</span>
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.address}</span>
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.city} - {singleInvoice.sp_data.postal_code}, {singleInvoice.sp_data.state}</span>
                      {singleInvoice.sp_data.tax_reg_name &&                       
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.tax_reg_name} : {singleInvoice.sp_data.tax_reg_number}</span>
                      }
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-end", flexDirection:'column'}}>
                  <h2 style={{ fontWeight: "bold" }}>INVOICE</h2>
                  <div style={{ border: "1px solid #000", padding: "10px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Invoice #</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.invoice_unique}</td>
                      </tr>
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Date</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{formatMySQLDate(singleInvoice.invoice_date)}</td>
                      </tr>  
                      {singleInvoice.req_unique &&
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Ref No.</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.req_unique}</td>
                      </tr>
                      }                   
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Client ID</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.cus_data.cus_unique}</td>
                      </tr>  
                     
                    </tbody>
                  </table>
                </div>
                  </div>
                  
                </div>

                
                <div style={{ marginTop: "20px",display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: "bold" }}>Bill To</h3>
                  {singleInvoice.bill_to ?
                  <>
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.bill_to}</span>
                  </>
                  :
                  <>
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.cus_data.name}</span>
                  </>
                  
                  }
                                    
                  <span style={{fontSize:12}}>{singleInvoice.cus_data.location.apartment}, {singleInvoice.cus_data.location.apt_name}, {singleInvoice.cus_data.location.locality}</span>   
                  <span style={{fontSize:12}}>{singleInvoice.cus_data.location.city}, {singleInvoice.cus_data.location.pin_zip}</span>  
                  {singleInvoice.cus_data.location.tax_no &&                  
                    <span style={{fontSize:12}}>TAX No.:{singleInvoice.cus_data.location.tax_no}</span>                  
                  }                
                  
                </div>

               
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                  <thead>
                    <tr style={{ background: "#2A52BE", color: "#fff", textAlign: "left" }}>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Item Description</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Unit Price</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Qty/Hrs</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                  {singleInvoice.invoice_items
                  .filter(item => item.item_code !== null) 
                  .map((item, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #000" }}>
                      <td style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>{item.item_name}</td>
                      <td style={{ padding: "8px", border: "1px solid #000", textAlign: "right" }}>
                        {singleInvoice.sp_data.currency}{item.amount}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>{item.quantity || "-"}</td>
                      <td style={{ padding: "8px", border: "1px solid #000", textAlign: "right" }}>
                        {singleInvoice.sp_data.currency}{item.total}
                      </td>
                    </tr>
                  ))}

                  </tbody>
                </table>

                {/* Total Amount */}
                <div style={{width:'100%',display:'flex',alignItems:'flex-end',flexDirection:'column'}}>
                {singleInvoice.invoice_items
                  .filter(item => item.item_code == null) 
                  .map((item, index) => (
                    <span style={{ textAlign: "right", fontWeight: "bold", fontSize:15,padding:5}}>
                      {item.item_name}: {singleInvoice.sp_data.currency}{item.total}
                    </span>
                  ))}
                  
                <div style={{ textAlign: "right", fontWeight: "bold" }}>
                  <p>Total: {singleInvoice.sp_data.currency}{singleInvoice.total_amount}</p>
                </div>
                </div>
                  
                {/* Declaration */}
                <div style={{ marginTop: "20px", border: "1px solid #000", padding: "10px" }}>
                  <h4 style={{ background: "#2A52BE", color: "#fff", padding: "5px" }}>Declaration</h4>
                  <p>{singleInvoice.declaration || "No declaration provided."}</p>
                </div>

                {/* Footer */}
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <p>Thank you for your business!</p>
                  <div style={{width:'100%',display:'flex',fontSize:12,alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                  <span style={{fontSize:12,fontWeight:'bold'}}>{singleInvoice.sp_data.entity_name}</span>
                  <span style={{fontSize:12}}>{singleInvoice.sp_data.address}</span>
                  </div>
                </div>
              </div>
                </div>
                <div style={{width:'90%',justifyContent:'center',alignItems:'center',display:'flex',gap:20}}>
                    <Button appearance='primary' intent='primary' onClick={generatePDF}>Download</Button>
                    
                </div>
              </div>
              <div style={{width:40,height:40,justifyContent:'center',alignItems:'center',display:'flex',top:'5%',right:'5%',zIndex:999999,position:'fixed',cursor:'pointer'}} onClick={()=>{setTemplateContent(null);setTemplateSelected(null);setSingleInvoice(null);setShowTemplate(false)}}>
                  <MdClose size={50} color='#fff' />
              </div>
            </div>
          );
        } else {
          templateJSX = (
            <div className="print-preview-overlay">
              <div className='print-preview-container' style={{width:'80%',height:'90%',padding:'10px'}}>
                <div className='print-content ' style={{overflowY:'auto'}}>
                <div id='printable-contract' style={{ width: "90%", padding: "20px", margin: "auto", border: "1px solid #000", fontFamily: "Arial, sans-serif" }}>
      
                {/* Header */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "2px solid #000", paddingBottom: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ width: "80px", height: "80px", borderRadius: "50%", border: "1px solid #b8b8b8", textAlign: "center", lineHeight: "80px" }}>
                      <img src={singleInvoice.sp_data.logo} style={{width:'100%',height:'100%',borderRadius:'50%'}} /> 
                    </div>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column'}}>
                      <span style={{ marginLeft: "10px",fontSize:18,fontWeight:'bold' }}>{singleInvoice.sp_data.entity_name}</span>
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.address}</span>
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.city} - {singleInvoice.sp_data.postal_code}, {singleInvoice.sp_data.state}</span>
                      {singleInvoice.sp_data.tax_reg_name &&                       
                      <span style={{ marginLeft: "10px",fontSize:15,}}>{singleInvoice.sp_data.tax_reg_name} : {singleInvoice.sp_data.tax_reg_number}</span>
                      }
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "flex-end", flexDirection:'column'}}>
                  <h2 style={{ fontWeight: "bold" }}>INVOICE</h2>
                  <div style={{ border: "1px solid #000", padding: "10px" }}>
                  <table style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Invoice #</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.invoice_unique}</td>
                      </tr>
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Date</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{formatMySQLDate(singleInvoice.invoice_date)}</td>
                      </tr>  
                      {singleInvoice.req_unique &&
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Ref No.</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.req_unique}</td>
                      </tr>
                      }                   
                      <tr>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>Client ID</td>
                        <td style={{ borderBottom: "1px solid #000",fontSize:12,fontWeight:'bold',textAlign:'left' }}>{singleInvoice.cus_data.cus_unique}</td>
                      </tr>  
                     
                    </tbody>
                  </table>
                </div>
                  </div>
                  
                </div>

                
                <div style={{ marginTop: "20px",display:'flex',alignItems:'flex-start',flexDirection:'row'}}>
                <div style={{ display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: "bold" }}>Bill from</h3>
                  
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.sp_data.entity_name}</span>               
                                    
                  <span style={{fontSize:12,maxWidth:'75%',textAlign:'left'}}>{singleInvoice.sp_data.address}, {singleInvoice.sp_data.city}, {singleInvoice.sp_data.postal_code}</span>   
                  
                  {singleInvoice.sp_data.tax_reg_name &&                  
                    <span style={{fontSize:12}}>{singleInvoice.sp_data.tax_reg_name} - {singleInvoice.sp_data.tax_reg_number}</span>                  
                  }                
                  
                  </div>
                  <div style={{ display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                  <h3 style={{ fontWeight: "bold" }}>Bill To</h3>
                  {singleInvoice.bill_to ?
                  <>
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.bill_to}</span>
                  </>
                  :
                  <>
                  <span style={{fontSize:15,fontWeight:'bold'}}>{singleInvoice.cus_data.name}</span>
                  </>
                  
                  }
                                    
                  <span style={{fontSize:12}}>{singleInvoice.cus_data.location.apartment}, {singleInvoice.cus_data.location.apt_name}, {singleInvoice.cus_data.location.locality}</span>   
                  <span style={{fontSize:12}}>{singleInvoice.cus_data.location.city}, {singleInvoice.cus_data.location.pin_zip}</span>  
                  {singleInvoice.cus_data.location.tax_no &&                  
                    <span style={{fontSize:12}}>TAX No.:{singleInvoice.cus_data.location.tax_no}</span>                  
                  }                
                  
                  </div>
                </div>

               
                <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                  <thead>
                    <tr style={{ background: "#2A52BE", color: "#fff", textAlign: "left" }}>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Item Description</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>HSN/SAC</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Unit Price</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Qty/Hrs</th>
                      <th style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {singleInvoice.invoice_items.map((item, index) => (
                      <tr key={index} style={{ borderBottom: "1px solid #000" }}>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>{item.item_name}</td>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "left" }}>{item.item_code ? item.item_code : 'N/A'}</td>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "right" }}>{singleInvoice.sp_data.currency}{item.amount}</td>
                        <td style={{ padding: "8px", border: "1px solid #000" }}>{item.quantity || "-"}</td>
                        <td style={{ padding: "8px", border: "1px solid #000", textAlign: "right" }}>{singleInvoice.sp_data.currency}{item.total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {/* Total Amount */}
                <div style={{ textAlign: "right", fontWeight: "bold", marginTop: "10px" }}>
                  <p>Total: {singleInvoice.sp_data.currency}{singleInvoice.total_amount}</p>
                </div>

                <div style={{ marginTop: "20px", border: "1px solid #000", padding: "10px" }}>
                  <h4 style={{ background: "#2A52BE", color: "#fff", padding: "5px" }}>Amount in words</h4>
                  <p>{convertRupeesToWords(singleInvoice.total_amount)}</p>
                </div>


                {/* Declaration */}
                <div style={{ marginTop: "20px", border: "1px solid #000", padding: "10px" }}>
                  <h4 style={{ background: "#2A52BE", color: "#fff", padding: "5px" }}>Declaration</h4>
                  <p>{singleInvoice.declaration || "No declaration provided."}</p>
                </div>

                {/* Footer */}
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  <p>Thank you for your business!</p>
                  <div style={{width:'100%',display:'flex',fontSize:12,alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
                  <span style={{fontSize:12,fontWeight:'bold'}}>{singleInvoice.sp_data.entity_name}</span>
                  <span style={{fontSize:12}}>{singleInvoice.sp_data.address}</span>
                  </div>
                </div>
              </div>
                </div>
                <div style={{width:'90%',justifyContent:'center',alignItems:'center',display:'flex',gap:20}}>
                    <Button appearance='primary' intent='primary' onClick={generatePDF}>Download</Button>
                    
                </div>
              </div>
              <div style={{width:40,height:40,justifyContent:'center',alignItems:'center',display:'flex',top:'5%',right:'5%',zIndex:999999,position:'fixed',cursor:'pointer'}} onClick={()=>{setTemplateContent(null);setTemplateSelected(null);setSingleInvoice(null);setShowTemplate(false)}}>
                  <MdClose size={50} color='#fff' />
              </div>
            </div>
          );
        }
    
        setTemplateContent(templateJSX); // Store JSX in state
      };

      
      useEffect(()=>{
        if(singleInvoice){
            setShowTemplate(true);
        }else{
            setShowTemplate(false);
        }
      },[singleInvoice]);

      const formatUtcDate = (utcDate) => {
          
          const formattedDate = moment.utc(utcDate).local().format('MMMM Do, YYYY');
        
          
          const relativeTime = moment.utc(utcDate).local().fromNow();
        
          
          const result = `${formattedDate} (${relativeTime})`;
        
          return result;
        };
      
       
    return(
        <div style={{ display: 'flex', height: '100vh'}}>
        <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
                <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                    <HeaderBar page_name={'Reports > Revenue'} />                                    
                </div>

                    <div style={{ display: "flex", padding: 5, justifyContent: "center", alignItems: "center", gap: 10, flexDirection: "row" }}>
                                                                           
                      <Button intent="primary" onClick={() => {fetchAllData();}}>All Time</Button>
                      <Button intent="primary" onClick={() => {fetchAllData(getPastDate(7), getPastDate(0));}}>Last 7 Days</Button>
                      <Button intent="primary" onClick={() => {fetchAllData(getPastDate(30), getPastDate(0));}}>Last 30 Days</Button>
                      <Button intent="primary" onClick={() => {fetchAllData(getPreviousMonthStart(), getPreviousMonthEnd())}}>Previous Month</Button>
                      <span style={{ fontSize: 12, fontWeight: "bold" }}>Custom Date Range</span>
                      <div className="date-picker-container">
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => setDateRange(update)}
                            isClearable={true}
                            placeholderText="📅 Select date range"
                            className="custom-input"
                            calendarClassName="custom-calendar"
                          />
                      </div>
                          <Button style={{marginRight:-25}}intent="success" onClick={() => {startDate && endDate ? fetchAllData(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range");startDate && endDate ? fetchAllData(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range")}}>Fetch Report</Button>
              </div>
                    <div style={{width:'100%',justifyContent:'flex-start',alignItems:'center',textAlign:'center'}}>
                    {allData && 
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr", gap: 20, padding: 20, backgroundColor: "#e6e6e6" }}>
                            <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, textAlign: "center", boxShadow: "0px 3px 6px rgba(0,0,0,0.15)", height: "350px", 
                                display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center"}}>
                                <h3 style={{ fontSize: "16px", fontWeight: "bold", color: "#333", marginBottom: 10 }}>Revenue Breakdown</h3>

                            <div style={{ width: "90%", height: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Pie data={pieChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                            </div>   
                        </div>

            
                        <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10, textAlign: "center", boxShadow: "0px 3px 6px rgba(0,0,0,0.15)", height: "350px"}}>
                            <h3 style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>Monthly Revenue Trends</h3>

                            <div style={{ width: "100%", height: "250px" }}>
                                <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false, scales: { y: { beginAtZero: true } } }} />
                            </div>
                        </div>

                        </div>
                    }
                    </div>

                    <div style={{width:'90%',display:'flex',justifyContent:'center',alignItems:'center',padding:10,marginTop:10,fontSize:20,fontWeight:100}}>All Invoices</div>
                    <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: 20, padding: 20, backgroundColor: "#e6e6e6"}}>
                        {allInvoices && allInvoices.map((item,index)=>(
                            <div key={index} style={{width: '250px',padding: 10,borderRadius: 10,backgroundColor: '#fff',boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.38)',
                                display: 'flex',alignItems: 'center', gap: 10, cursor:'pointer', transition: 'transform 0.2s ease-in-out'
                            }} className="complaint-card" onClick={()=>setSingleInvoice(item)} >
                              <img src={item.cus_data.profile_pic} style={{width:50,height:50,borderRadius:50}} />
                              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                                  <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',textAlign:'left', fontSize:12,fontWeight:'bold'}}>
                                      {item.invoice_unique}
                                  </div>
                                  <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',textAlign:'left', fontSize:12,fontWeight:100}}>
                                      {item.cus_data.name}, {item.sp_data.currency}{item.total_amount}
                                  </div>
                                  <div style={{display:'flex',flexWrap:'wrap',justifyContent:'center',alignItems:'center',textAlign:'left', fontSize:10,fontWeight:100}}>
                                      {formatUtcDate(item.invoice_date)}
                                  </div>
                              </div>
                            </div>
                        ))}
                    </div>
                    {hasMore &&                     
                      <div style={{width:'90%',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center'}} onClick={fetchAllInvoices}> 
                          <Button appearance='primary' intent='primary'>Load More</Button>
                      </div>
                    }
                </div>
                {invoicePreview && templateContent}
            {taskLoader &&
                <div className='loaderBox'>
                    <BounceLoader color='#FCC100' size={30} />
                </div>          
                                                                           
            }

        <Dialog
          isShown={showTemplate}
          title="Select an Invoice Template"
          intent="danger"
          onCloseComplete={() => { setShowTemplate(false); setSingleInvoice(null); }}
          width="80vw"
          maxWidth="none"
          hasFooter={false}
        >
          <div style={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', gap: '20px', padding: '10px'}}>
            {templates && templates.length > 0 ? (
              templates.map((item) => (
                <div key={item.id} style={{width: '250px', padding: '10px', textAlign: 'center', cursor: 'pointer',borderRadius: '5px',boxShadow: '0px 3px 6px rgba(0,0,0,0.15)',background: '#f9f9f9',transition: 'transform 0.2s ease-in-out'}} onClick={() => setTemplateSelected(item.id)}>
                  <img src={item.url} alt={item.name} style={{ width: '100%', height: '50%', objectFit: 'cover', borderRadius: "5px" }} />
                  <p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '5px' }}>{item.name}</p>
                </div>
              ))
            ) : (
              <p style={{ fontSize: '14px', color: '#DA4167' }}>No templates available</p>
            )}
          </div>
        </Dialog>



        </div>
    )

}

export default RevenueReport;