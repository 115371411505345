import React, {useState, useEffect, CSSProperties, useCallback, useRef } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './MyAgents.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider, StepsTitle } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Popover, Tablist, Tab} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline, MdOutlinePhoneIphone,MdCalendarMonth    } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Line, Bar, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const AgentsPerformance = () =>{

    const [taskLoader, setTaskLoader] = useState(false);

    const [allData, setAllData] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [agentData, setAgentData] = useState(null);
    const [showChart, setShowChart] = useState(false);
    
    const chartRef = useRef(null);


    const getPastDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() - days);
        return formatDate(date);
    };
    
    const getPreviousMonthStart = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        date.setDate(1);
        return formatDate(date);
    };
    
    const getPreviousMonthEnd = () => {
        const date = new Date();
        date.setDate(0);
        return formatDate(date);
    };

    const getStatusLabel = (status) => {
        const statusMap = {
            1: 'Awaiting Agent',
            2: 'Agent Alloted',
            3: 'Agent En-route',
            4: 'Agent at Site',
            5: 'Product Picked Up',
            6: 'Service Rendered',
            7: 'Service Complete',
            8: 'Under Dispute',
            9: 'Cancelled by Customer',
            10: 'Cancelled by Provider'
        };
        return statusMap[status] || 'Unknown';
    };
    
    const getStatusColor = (status) => {
        return status === 7 ? 'green' : status === 8 ? 'orange' : status >= 9 ? 'red' : 'blue';
    };
    
    const getCompType = (type) => {
        return type === 1 ? 'Complaint' : type === 2 ? 'Service Request' : 'Follow-up';
    };
    
    
    const formatDate = (date) => {
        return date.toISOString().split("T")[0];
    };
    

    const fetchAgentReport = async (start_date = null, end_date = null) => {
        const sp_unique = localStorage.getItem("sp_unique");
    
        setTaskLoader(true);
        try {
            const response = await fetch(
                "https://access.myservicecircle.app/wcapi/sp_data/00_agentReport_1.php",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ sp_unique, start_date, end_date }),
                }
            );
    
            const responseData = await response.json();
    
            if (responseData.success === 1) {
                setAllData(responseData.data);
            } else {
                toaster.danger(responseData.error);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTaskLoader(false);
        }
    };

    
    useEffect(()=>{
        fetchAgentReport();
    },[]);

    const themeColors = ['#993366', '#F4C430', '#2A52BE', '#DA4167', '#FCC100'];

    
    const agentNames = allData ? allData.map(agent => agent.agent_name) : [];
    const totalCases = allData ? allData.map(agent => agent.total_cases_handled) : [];
    const avgResolutionTime = allData ? allData.map(agent => agent.avg_resolution_time || 0) : [];
    const successRate = allData ? allData.map(agent => agent.success_rate || 0) : [];
    const avgRating = allData ? allData.map(agent => agent.avg_rating || 0) : [];
    const profilePics = allData ? allData.map(agent => agent.agent_profile_pic) : [];

    const chartData = {
        labels: agentNames,
        datasets: [
            {
                label: 'Total Cases Handled',
                data: totalCases,
                backgroundColor: themeColors[0],
                borderColor: '#7a2650',
                borderWidth: 1
            },
            {
                label: 'Avg Resolution Time (hrs)',
                data: avgResolutionTime.map(time => (time / 60).toFixed(2)),
                backgroundColor: themeColors[1],
                borderColor: '#CC8400',
                borderWidth: 1
            },
            {
                label: 'Success Rate (%)',
                data: successRate,
                backgroundColor: themeColors[2],
                borderColor: '#357A38',
                borderWidth: 1
            },
            {
                label: 'Avg Rating',
                data: avgRating,
                backgroundColor: themeColors[3],
                borderColor: '#0056b3',
                borderWidth: 1
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { 
                position: 'top',
                labels: { 
                    usePointStyle: true, 
                    generateLabels: (chart) => {
                        return chart.data.datasets.map((dataset) => ({
                            text: dataset.label,
                            fillStyle: dataset.backgroundColor,
                            hidden: false
                        }));
                    }
                }
            },
            title: { 
                display: true, 
                text: 'Agent Performance Overview' 
            },
            tooltip: {
                enabled: false, 
                external: function(context) {
                    let tooltipEl = document.getElementById('chart-tooltip');
    
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chart-tooltip';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.padding = '10px';
                        tooltipEl.style.borderRadius = '8px';
                        tooltipEl.style.backgroundColor = '#fff';
                        tooltipEl.style.boxShadow = '0px 4px 8px rgba(0,0,0,0.2)';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.opacity = 0;
                        tooltipEl.style.transition = 'opacity 0.2s ease-in-out';
                        tooltipEl.innerHTML = `
                            <img src="" style="width:50px; height:50px; border-radius:50%; margin-bottom:5px;">
                            <div style="text-align:center; font-size:12px; color:#333;"></div>
                            <strong style="display:block; margin-top:5px; font-size:10px; color:#000;"></strong>
                        `;
                        document.body.appendChild(tooltipEl);
                    }
    
                    const tooltipModel = context.tooltip;
    
                    // Hide tooltip if not active
                    if (!tooltipModel || tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
    
                    
                    const dataIndex = tooltipModel.dataPoints[0].dataIndex;
                    const datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
                    const metricLabel = chartData.datasets[datasetIndex].label;
                    const metricValue = tooltipModel.dataPoints[0].raw;   
                    
                    tooltipEl.querySelector('img').src = profilePics[dataIndex];
                    tooltipEl.querySelector('div').innerText = agentNames[dataIndex];
                    tooltipEl.querySelector('strong').innerText = `${metricLabel}: ${metricValue}`;
    
                    const chartCanvas = context.chart.canvas.getBoundingClientRect();
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = `${chartCanvas.left + tooltipModel.caretX}px`;
                    tooltipEl.style.top = `${chartCanvas.top + tooltipModel.caretY - 60}px`; 
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function(value, index) {
                        return agentNames[index]; 
                    },
                    font: {
                        size: 12,
                        weight: 'bold'
                    }
                }
            },
            y: { beginAtZero: true }
        }
    };
    
    
    
    const getAgentData = async(sa_unique) =>{
        const sp_unique = localStorage.getItem('sp_unique');
        
        setTaskLoader(true);
        try{
            const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/00_agentReport_2.php',{
                method : 'POST',
                headers:{
                    'Content-type' : 'application/json'
                },
                body:JSON.stringify({sp_unique,sa_unique})
            });

            const responseData = await response.json();
           console.log(responseData);
            if(responseData.success == 1){
                setAgentData(responseData.data);
                setShowChart(true);
            }else{
                toaster.danger(responseData.error);
            }
        }catch(error){
            console.log(error);
        }finally{
            setTaskLoader(false);
        }
    }

    const formatMonth = (monthString) => {
        const [year, month] = monthString.split('-');
        return new Date(year, month - 1).toLocaleString('default', { month: 'long', year: 'numeric' });
    };
    

    return(
    <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
        <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
            <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Agents > Performances'} />                                    
            </div>
            <div style={{ width: '90%', margin: 'auto', padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
                {allData ?

                    <>
                    <div style={{ display: "flex", padding: 5, justifyContent: "center", alignItems: "center", gap: 10, flexDirection: "row" }}>
                       
                        <Button intent="primary" onClick={() => fetchAgentReport()}>
                            All Time
                        </Button>
                        <Button intent="primary" onClick={() => fetchAgentReport(getPastDate(7), getPastDate(0))}>
                            Last 7 Days
                        </Button>
                        <Button intent="primary" onClick={() => fetchAgentReport(getPastDate(30), getPastDate(0))}>
                            Last 30 Days
                        </Button>
                        <Button intent="primary" onClick={() => fetchAgentReport(getPreviousMonthStart(), getPreviousMonthEnd())}>
                            Previous Month
                        </Button>

                       
                        <span style={{ fontSize: 12, fontWeight: "bold" }}>Custom Date Range</span>
                        <div className="date-picker-container">
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => setDateRange(update)}
                                isClearable={true}
                                placeholderText="📅 Select date range"
                                className="custom-input"
                                calendarClassName="custom-calendar"
                            />
                        </div>

                       
                        <Button 
                            style={{marginRight:-25}}
                            intent="success" 
                            onClick={() => startDate && endDate ? fetchAgentReport(formatDate(startDate), formatDate(endDate)) : toaster.danger("Please select a valid date range")}>
                            Fetch Report
                        </Button>
                    </div>

                    <Bar ref={chartRef} data={chartData} options={chartOptions} />
                   
                    </>
                    :
                    <>
                        <h4 style={{fontWeight:100}}>We couldn't find enough data to produce the results</h4>
                    </>
                
                }
            </div>
            <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',width: '100%',marginTop: 20,}}>
                
                <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',padding: '12px',width: '90%',backgroundColor: '#fff',borderRadius: 12,fontSize: '14px',fontWeight: 'bold',boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',textAlign: 'center',marginBottom: 10}}>Click on an agent’s picture to see their details
                </div>

                <Carousel
                    showArrows={true}
                    showThumbs={false}
                    showIndicators={false}
                    centerMode={true}
                    centerSlidePercentage={allData && allData.length > 2 ? 15 : 100}
                    infiniteLoop={allData && allData.length > 2}
                    emulateTouch={true}
                    autoPlay={false}

                >
                    {allData && allData.map((item, index) => (
                        <div key={index} style={{maxHeight: '180px',maxWidth: '120px',justifyContent: 'center',alignItems: 'center',backgroundColor: '#fff',borderRadius: 15,padding: '10px',boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',textAlign: 'center',cursor:'pointer'}} onClick={()=>getAgentData(item.sa_unique)}>
                            
                            <img style={{width: 80,height: 80,borderRadius: '50%',objectFit: 'cover',boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',marginBottom: 8}} src={item.agent_profile_pic} />

                            <div style={{fontSize: '12px',fontWeight: 'bold',color: '#333',whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis'}}>
                                {item.agent_name}
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>

        </div>
        <Dialog
            isShown={showChart}
            title={`Performance Report - ${agentData?.[0]?.month}`}
            intent="danger"
            onCloseComplete={() => {
                setShowChart(false);
                setAgentData(null);
            }}
            width="80vw"
            maxWidth="none"
            hasFooter={false}
        >
            {agentData ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    {/* Bar Chart - Total Cases Per Month */}
                    <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 10, boxShadow: '0 2px 6px rgba(0,0,0,0.1)' }}>
                        <h3 style={{ textAlign: 'center', color: '#993366', fontWeight: 'bold' }}>Total Cases Per Month</h3>
                        <Bar
                            data={{
                                labels: agentData.map(month => month.month),
                                datasets: [
                                    {
                                        label: 'Total Cases',
                                        data: agentData.map(month => month.summary.total_cases),
                                        backgroundColor: '#993366',
                                        borderColor: '#7a2650',
                                        borderWidth: 1
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                scales: { y: { beginAtZero: true } }
                            }}
                        />
                    </div>

                   {/* Pie Chart - Case Resolution Breakdown */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: 20 }}>
                            {agentData.map((monthData, index) => (
                                <div key={index} style={{ width: 250, textAlign: 'center' }}>
                                    <h4 style={{ color: '#993366', fontWeight: 'bold' }}>{formatMonth(monthData.month)}</h4>

                                    <Pie
                                        data={{
                                            labels: ['Resolved', 'Disputed', 'Cancelled'],
                                            datasets: [
                                                {
                                                    data: [monthData.summary.resolved, monthData.summary.disputed, monthData.summary.cancelled],
                                                    backgroundColor: ['#993366', '#F4C430', '#DA4167'], // Updated to match logo colors
                                                    borderColor: ['#7a2650', '#CC8400', '#9B1B30'], // Darker shades for contrast
                                                    borderWidth: 1
                                                }
                                            ]
                                        }}
                                        options={{
                                            plugins: {
                                                legend: {
                                                    labels: {
                                                        color: '#333', // Ensures text remains readable
                                                        font: {
                                                            size: 12,
                                                            weight: 'bold'
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                        style={{ width: 50, height: 50 }}
                                    />
                                </div>
                            ))}
                        </div>

                    {/* Line Chart - Monthly Case Trends */}
                    <div style={{ backgroundColor: '#fff', padding: 20, borderRadius: 10, boxShadow: '0 2px 6px rgba(0,0,0,0.1)' }}>
                        <h3 style={{ textAlign: 'center', color: '#993366', fontWeight: 'bold' }}>Monthly Trends</h3>
                        <Line
                            data={{
                                labels: agentData.map(month => month.month),
                                datasets: [
                                    {
                                        label: 'Total Cases',
                                        data: agentData.map(month => month.summary.total_cases),
                                        borderColor: '#DA4167',
                                        backgroundColor: 'rgba(218, 65, 103, 0.5)',
                                        fill: true,
                                    }
                                ]
                            }}
                            options={{
                                responsive: true,
                                scales: { y: { beginAtZero: true } }
                            }}
                        />
                    </div>

                    {/* Detailed Breakdown - Table */}
                    <div style={{ overflowX: 'auto', backgroundColor: '#fff', padding: 20, borderRadius: 10, boxShadow: '0 2px 6px rgba(0,0,0,0.1)' }}>
                        <h3 style={{ textAlign: 'center', color: '#2A52BE', fontWeight: 'bold' }}>Case Details</h3>
                        {agentData.map((monthData, index) => (
                            <div key={index} style={{ marginBottom: 20 }}>
                                <span style={{ color: '#993366', textDecoration: 'none',fontSize:12,fontWeight:'bold' }}>{formatMonth(monthData.month)}</span>
                                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr style={{ backgroundColor: '#993366', color: '#fff' }}>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Request ID</th>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Customer</th>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Description</th>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Status</th>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Type</th>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Rating</th>
                                            <th style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>Review</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {monthData.cases.map((caseItem, idx) => (
                                            <tr key={idx} style={{ borderBottom: '1px solid #ddd' }}>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>{caseItem.req_unique}</td>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>{caseItem.cus_unique}</td>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12}}>{caseItem.description}</td>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12, color: getStatusColor(caseItem.status) }}>
                                                    {getStatusLabel(caseItem.status)}
                                                </td>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>{getCompType(caseItem.comp_type)}</td>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>{caseItem.rating ? `${caseItem.rating} ★` : 'N/A'}</td>
                                                <td style={{ padding: 3, border: '1px solid #ddd',fontSize:12 }}>{caseItem.review && caseItem.review !== 'null' ? `"${caseItem.review}"` : 'No Review'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <h4 style={{ textAlign: 'center', color: '#dc3545' }}>No data available for this agent.</h4>
            )}
        </Dialog>


         {taskLoader &&
            <div className='loaderBox'>
                <BounceLoader color='#FCC100' size={30} />
            </div>          
                                           
        }
    </div>
    )

}

export default AgentsPerformance;