import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import { Pill,toaster } from 'evergreen-ui';
import { SliderThumb } from '@chakra-ui/react';
const SideBar = () =>{
    const [collapsed, setCollapsed] = useState(false);
    const [inviteCount, setInviteCount] = useState(0);
    const [currentPlan, setCurrentPlan] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
      fetchExpiry();
      fetchCount(); 
      const interval = setInterval(() => {
        fetchExpiry();
        fetchCount();  
            
    }, 30000);
    
      
      return () => clearInterval(interval);

     
    },[location]);


    const fetchCount = async() =>{
      const sp_unique = localStorage.getItem('sp_unique');
      try{
        const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/23_invitationCount.php',{
          method:'POST',
          headers:{
            'Content-type':'application/json'
          },
          body:JSON.stringify({sp_unique})
        });

        const responseData = await response.json();

        setInviteCount(responseData.count);
        
      }catch(error){
        console.log(error);
      }
    }

    const fetchExpiry = async () =>{
      
      const sp_unique = localStorage.getItem('sp_unique');
      try {
        const response = await fetch(
          'https://access.myservicecircle.app/sp/7a_fetchController.php',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sp_unique }), // Ensure sp_unique is defined
          }
        );
        
        //alert(JSON.stringify(response));
        if (!response.ok) {
          // Handle HTTP errors
          throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
        }
    
        const responseData = await response.json();

        setCurrentPlan(responseData.plan);
       
        if(responseData.is_active == 0){
          if (!location.pathname.startsWith("/Dashboard")) {
            navigate(`/Dashboard/${localStorage.getItem('token') || ''}`); 
          }
        }
        
      } catch (error) {
        // Log errors for debugging
        console.error('Error fetching controller:', error);
      }

    }

  
    function abbreviateNumber(value) {
      let newValue = value;
      if (value >= 1000 && value < 1000000) {
        newValue = Math.floor(value / 1000) + 'K';
      } else if (value >= 1000000 && value < 1000000000) {
        newValue = Math.floor(value / 1000000) + 'M';
      } else if (value >= 1000000000) {
        newValue = Math.floor(value / 1000000000) + 'B';
      }
      return newValue;
    }

    const checkReport = () =>{
      if(!currentPlan){       
        return;
      }

      if(currentPlan.reports == 1){
        navigate('/ClientReviews');
      }else{
        toaster.danger("Oops! Reviews are a part of advanced reports. Your current plan doesn't support advanced reports.")
      }
    }

    
    return(
<Sidebar
        collapsed={collapsed}
        style={{
          height: '100vh',
          width: collapsed ? '80px' : '250px',
          backgroundColor: '#fff',
          color: '#000',
          transition: 'width 0.3s ease',
        }}
      >
        <Menu>
          <MenuItem>
            <img src={collapsed ? logoSmall : logoLong} style={{width: collapsed? '100%' : '100%'}} />
          </MenuItem>
          <MenuItem
            icon={collapsed ? <AiOutlineArrowRight size={20}/> : <AiOutlineArrowLeft size={15}/>}
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? 'Open' : 'Close'} Panel
          </MenuItem>

         
          <MenuItem icon={<FaHome size={collapsed ? 20 : 15}/>} onClick={() => navigate('/Dashboard')}>Home</MenuItem>

         
          <MenuItem icon={<FaUserAlt size={collapsed ? 20 : 15}/>} onClick={() => navigate('/Profile')}>Profile</MenuItem>

          
          <MenuItem icon={<FiPackage size={collapsed ? 20 : 15} />} onClick={() => navigate('/Subscriptions')}>My Subscription</MenuItem>

         
          <SubMenu label="Clients" icon={<FaUsers size={collapsed ? 20 : 15}/>}>
            <MenuItem icon={<FaUserAlt />} onClick={()=> navigate('/MyClients')}>My Clients</MenuItem>
            <MenuItem icon={<FaRegFileAlt />} onClick={()=>navigate('/ClientsInvitation')}>Client Requests {inviteCount > 0 && <Pill display="inline-flex" margin={0} color="red">{inviteCount == 0 ? '' : abbreviateNumber(inviteCount)}</Pill> }</MenuItem>
            <MenuItem icon={<FaComments />} onClick={checkReport}>Client Feedback</MenuItem>
          </SubMenu>

          
            <MenuItem icon={<FaUserTie size={collapsed ? 20 : 15}/>} onClick={()=>navigate('/MyAgents')}>My Agents</MenuItem>
        

          
          <SubMenu label="Reports" icon={<FaClipboard size={collapsed ? 20 : 15}/>}>
            <MenuItem icon={<FaChartBar />} onClick={()=>navigate('/ComplaintReports')}>Complaints Reports</MenuItem>
            <MenuItem icon={<FaChartBar />} onClick={()=>navigate('/AgentsPerformance')}>Agent Performance</MenuItem>
            <MenuItem icon={<FaChartBar />} onClick={()=>navigate('/ContractReports')}>Service Contracts</MenuItem>
            <MenuItem icon={<FaTools />} onClick={()=>navigate('/RevenueReport')}>Revenue Reports</MenuItem>
            <MenuItem icon={<FaRegFileAlt />}>Subscription Analytics</MenuItem>
            <MenuItem icon={<FaChartBar />}>Client Interaction</MenuItem>
            <MenuItem icon={<FaTools />}>Product/Service Trends</MenuItem>
          </SubMenu>         
          
          <MenuItem icon={<FaComments size={collapsed ? 20 : 15}/>}>Chat & Communication</MenuItem>          
          <MenuItem icon={<FaShoppingCart size={collapsed ? 20 : 15}/>}>Marketplace</MenuItem>
          
          
        </Menu>
      </Sidebar>
    )

}


export default SideBar;