import React, {useState, useEffect, CSSProperties, useCallback } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";
import openLocationCode from "open-location-code";
import './MyClients.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table, Tooltip, Popover} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat, MdEventNote, MdImage, MdCheck, MdClose } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";
import { MdPerson, MdBuild, MdShoppingCart, MdLink, MdExpandMore } from "react-icons/md"
import { MdLinkOff, MdExpandLess } from "react-icons/md";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";


const ClientsInvitation = () =>{
        const [taskLoader, setTaskLoader] = useState(false);
        const [basicLoader, setBasicLoader] = useState(false);

        const [inviteCount, setInviteCount] = useState(0);

        const [clientDetails, setClientDetails] = useState(null);
        const [selfCord, setSelfCord] = useState(null);

        const [selectedImage, setSelectedImage] = useState(null);

        const [showConfirmDialog, setShowConfirmDialog] = useState(false);
        const [pendingAction, setPendingAction] = useState(null);

        useEffect(()=>{
            fetchCount();
        },[]);

        const fetchCount = async() =>{
            const sp_unique = localStorage.getItem('sp_unique');
            setTaskLoader(true);
            try{
              const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/23_invitationCount.php',{
                method:'POST',
                headers:{
                  'Content-type':'application/json'
                },
                body:JSON.stringify({sp_unique})
              });
      
              const responseData = await response.json();
      
              setClientDetails(responseData.client_details);
              setInviteCount(responseData.count);

              const setcord = {
                lat:responseData.selfcords.lat,
                lng:responseData.selfcords.lng
              }

              setSelfCord(setcord);

            }catch(error){
              console.log(error);
            }finally{
                setTaskLoader(false);
            }

          }
      
          const renderLocation = (loc) =>{

            return(
                <span style={{fontSize:10}}>{loc.loc_name ? `${loc.loc_name}, `:''} {loc.apartment ? `${loc.apartment}, `:''} {loc.apt_name ? `${loc.apt_name}, `:''}{loc.locality ? `${loc.locality}, `:''} {loc.pin_zip ? `${loc.pin_zip}` :''}</span>
            )
          }

          const inviteAction = async (linkid, action) => {
            setPendingAction({ linkid, action });
            setShowConfirmDialog(true); // Show confirmation dialog
        };
        
        // Function to execute after confirmation
        const confirmInviteAction = async () => {
            if (!pendingAction) return;
        
            const { linkid, action } = pendingAction;
            const sp_unique = localStorage.getItem('sp_unique');
        
            setTaskLoader(true);
        
            try {
                const dataString = {
                    linkid,
                    action,
                    sp_unique
                };
                
                // Make the API request
                const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/24_acceptDeclineInvite.php', {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify(dataString)
                });
        
                const responseData = await response.json();
                
                if (responseData.success == 1) {
                    toaster.success(action == 1 ? "Invitation accepted successfully!" : "Invitation rejected.");
                } else {
                    toaster.danger(responseData.message || "Something went wrong.");
                }
            } catch (error) {
                console.error("Error processing invite action:", error);
                toaster.danger("Failed to process request.");
            } finally {
                setTaskLoader(false);
                setShowConfirmDialog(false);
                setPendingAction(null);
                fetchCount();
            }
        };

          function plusCodeToLatLng(plusCode) {
            const decoded = new openLocationCode.OpenLocationCode().decode(plusCode);;
           // console.log(JSON.stringify(decoded));
            return { lat: decoded.latitudeCenter, lng: decoded.longitudeCenter };
        }


        function haversineDistance(lat1, lon1, lat2, lon2) {
            const R = 6371; // Radius of the Earth in km
            const dLat = (lat2 - lat1) * (Math.PI / 180);
            const dLon = (lon2 - lon1) * (Math.PI / 180);
            
            const a = 
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
                
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            return R * c; // Distance in km
        }
        
        const calculateDistance = (plus) => {
            if (!selfCord) return <span>N/A</span>; // Prevents errors if selfCord is not set
        
            try {
                const remoteCoords = plusCodeToLatLng(plus);
                const distance = haversineDistance(
                    selfCord.lat, selfCord.lng, 
                    remoteCoords.lat, remoteCoords.lng
                );
        
                const distanceColor = distance > 50 ? "red" : "black"; // Change text color
        
                return <span style={{ color: distanceColor,fontSize:12,fontWeight:'bold',padding:10 }}>{distance.toFixed(2)} km</span>;
            } catch (error) {
                console.error("Error calculating distance:", error);
                return <span>N/A</span>;
            }
        };
        
        
    return(
        <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
               <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Clients : Clients Requests / Invitations'} />                  
              </div>
              <div style={{ width: '90%', margin: 'auto', padding: 20, backgroundColor: '#ffffff', borderRadius: 10, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: 20, alignItems: 'stretch'}}>
                    {taskLoader ?
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',width:'100%'}}>
                        <BarLoader size={30} color='#FCC100' />
                        </div>
                        :
                        <>
                           {inviteCount && inviteCount > 0 ? 
                           <>
                            {clientDetails && clientDetails.map((item,index)=>(
                                <>
                                    <div key={index} className="client-card" style={{textAlign: 'left',boxShadow: '0px 0px 33px -6px rgba(0,0,0,0.48)',padding: 0,overflow: 'hidden',borderRadius: 10,}}>
                                    
                                    <div style={{display: 'flex',flexDirection: 'column',backgroundColor: '#6832a8',width: '100%',padding: 10,justifyContent: 'center',alignItems: 'center',borderTopLeftRadius: 10,borderTopRightRadius: 10,}}>
                                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center',padding: 5,backgroundColor: '#fff',borderRadius: '50%',width: 50,height: 50,}}>
                                            <img src={item.profile_pic} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                                        </div>

                                        <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',textAlign: 'center',marginTop: 5,}}>
                                        <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold' }}>{item.name}</span>
                                        <span style={{ fontSize: 10, color: '#fff' }}>{item.product.name}</span>
                                        </div>
                                    </div>

                                    <div style={{display: 'flex',flexDirection: 'column',backgroundColor: '#fff',width: '100%',padding: 10,justifyContent: 'center',alignItems: 'center',borderBottomLeftRadius: 10,borderBottomRightRadius: 10,}}>
                                        
                                        <div style={{display: 'flex',alignItems: 'flex-start',gap: 5,width: '100%',justifyContent: 'center',}}>
                                        <MdLocationOn size={16} color="#000" style={{ flexShrink: 0 }} />
                                        <span style={{ fontSize: 10, color: '#000' }}>{renderLocation(item.location)}</span>
                                        </div>

                                        <div style={{display: 'flex',alignItems: 'flex-start',gap: 5,marginTop: 10,width: '100%',justifyContent: 'center',}}>
                                        <MdEventNote size={16} color="#000" style={{ flexShrink: 0 }} />
                                        <span style={{ fontSize: 10, color: '#000' }}>{item.product.description}</span>
                                        </div>
                                        <div style={{display: 'flex',alignItems: 'flex-start',gap: 5,marginTop: 10,width: '100%',justifyContent: 'center',}}>
                                        
                                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'flex-start',gap: 10,flexWrap: 'wrap',}}>
                                            {item.album.map((pic, picIndex) => (
                                            <img key={picIndex} src={pic.prd_pic} style={{ width: 20, height: 20, borderRadius: 5 }} onClick={()=>setSelectedImage(pic.prd_pic)}/>
                                            ))}
                                        </div>
                                        
                                        </div>
                                        <div style={{display: 'flex',justifyContent: 'center',alignItems: 'flex-start',gap: 10,flexWrap: 'wrap',padding:2,borderRadius:10,backgroundColor:'#fff',boxShadow:'0px 0px 33px -6px rgba(0,0,0,0.48)',marginTop:10}}>
                                            {calculateDistance(item.location.gplus)}
                                        </div>
                                    </div>

                                    <div style={{width:'100%',padding:10,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row',gap:10}}>
                                        <Button onClick={()=>inviteAction(item.id,2)}><MdClose size={20} color='red'/></Button>
                                        <Button onClick={()=>inviteAction(item.id,1)}><MdCheck size={20} color='green'/></Button>
                                    </div>
                                    </div>

                                </>
                            ))}
                            </>
                            :
                            <>
                            <h5>You do not have any invitations pending</h5>
                            </>
                            }
                        </>
                    }
                    
                    
                
              </div>
            </div>

            <Dialog 
            isShown={!!selectedImage} 
            hasHeader={false} 
            hasFooter={false} 
            onCloseComplete={() => setSelectedImage(null)}
            width="auto"
            style={{ background: "transparent", padding: 0 }}
            >
            <Pane 
                style={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background: "rgba(0,0,0,0.8)",
                }}
            >
                {/* Close Button */}
                <Button 
                onClick={() => setSelectedImage(null)}
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,                    
                    borderRadius: "50%",
                    zIndex: 10,
                    cursor: "pointer",
                    width: 50,  // Increased button width
                    height: 50, // Increased button height
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.42)", 
                    border: "none",
                    boxShadow: "0px 0px 5px rgba(0,0,0,0.3)"
                }}
                >
                <MdClose size={30} color="#000" />
                </Button>


                {/* Full-Size Image */}
                <img 
                src={selectedImage} 
                style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: 5,
                }} 
                />
            </Pane>
            </Dialog>
            <Dialog
            isShown={showConfirmDialog}
            title="Confirm Action"
            onCloseComplete={() => setShowConfirmDialog(false)}
            confirmLabel="Yes, Proceed"
            cancelLabel="Cancel"
            intent="warning"
            onConfirm={confirmInviteAction}
        >
            {pendingAction?.action === 1 
                ? "Are you sure you want to accept this invitation?" 
                : "Are you sure you want to reject this invitation?"}
        </Dialog>

        </div>
    )
}

export default ClientsInvitation;