import React, {useState, useEffect, CSSProperties, useCallback } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import Cropper from "react-easy-crop";
import { useDropzone } from "react-dropzone";
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';
import moment from "moment-timezone";

import './Profile.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, HashLoader, PulseLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, Toaster, Table} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel, MdChat } from "react-icons/md";
import { MdEdit, MdEmail, MdPhone, MdLocationOn, MdWork, MdBusiness, MdStar, MdStarHalf, MdStarOutline } from "react-icons/md";

const Subscriptions = () =>{
    const navigate = useNavigate();
    const [myid, setMyid] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [taskLoader, setTaskLoader] = useState(false);
    const [selfProfile, setSelfProfile] = useState(null);

    const [subscriptions, setSubscriptions] = useState(null);
     useEffect(()=>{
            const sp_unique = localStorage.getItem('sp_unique');
            if(sp_unique){
                setMyid(sp_unique);
            }else{
                navigate('./Login');
            }
        },[]);
    
        useEffect(()=>{
            if(myid){
                fetchSelf(myid);
            }
        },[myid]);
    
        const fetchSelf = async(sp_unique) =>{
            setTaskLoader(true);
    
            try{
                const response = await fetch('https://access.myservicecircle.app/sp/1_selfData.php',{
                    method:'POST',
                    headers:{
                        'Content-Type' : 'application/json'
                    },
                    body: JSON.stringify({sp_unique})
                });
    
                const responseData = await response.json();
    
                setSelfProfile(responseData.details);
                
            }catch(error){
                console.log(error);
            }finally{   
                setTaskLoader(false);
            }
    
        }

        useEffect(()=>{
            if(selfProfile){
                fetchSubscription();
            }
        },[selfProfile]);
    
        const fetchSubscription = async ()=>{
            const sp_unique = myid;
            setShowLoader(true);
            try{
              const response = await fetch('https://access.myservicecircle.app/wcapi/sp_data/57_mySubscriptions.php',{
                method : 'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body : JSON.stringify({sp_unique})
              });
              const responseData = await response.json();
              setSubscriptions(responseData);             
              
            }catch(error){
                console.log(error);
            }finally{
                setShowLoader(false);
            }
        }

        const renderFeature = (key, name) => {
            if (name === 'in_app_interaction') {
                if (key == 1) {
                    return <MdChat size={20} color='#b8b8b8' />;
                } else if (key == 2) {
                    return (
                        <>
                            <MdChat size={20} color='#b8b8b8' /> <MdCall size={20} color='#b8b8b8' />
                        </>
                    );
                } else {
                    return (
                        <>
                            <MdChat size={20} color='#b8b8b8' /> <MdCall size={20} color='#b8b8b8' /> <MdOutlineVideoCameraFront size={20} color='#b8b8b8' />
                        </>
                    );
                }
            }
            return null;
        };
        
        const getFeatureIcon = (value) => (
            value ? <MdCheckCircle size={20} color='#28a745' /> : <MdCancel size={20} color='#bf3030' />
        );

        const formatDate = (dateString) => {
            if (!dateString) return "Invalid date"; // Handle empty input
        
            const inputDate = moment(dateString, "YYYY-MM-DD HH:mm:ss", true); // Parse correctly with strict mode
            const now = moment();
        
            if (!inputDate.isValid()) return "Invalid date format"; // Handle invalid dates
        
            // Format the date to "Mar 20, 2025"
            const formattedDate = inputDate.format("MMM DD, YYYY");
        
            // Calculate difference
            const diffInSeconds = now.diff(inputDate, "seconds");
            const diffInMinutes = now.diff(inputDate, "minutes");
            const diffInHours = now.diff(inputDate, "hours");
            const diffInDays = now.diff(inputDate, "days");
            const diffInWeeks = now.diff(inputDate, "weeks");
            const diffInMonths = now.diff(inputDate, "months");
            const diffInYears = now.diff(inputDate, "years");
        
            let timeDepth;
            if (Math.abs(diffInSeconds) < 60) {
                timeDepth = `${Math.abs(diffInSeconds)} sec${Math.abs(diffInSeconds) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInMinutes) < 60) {
                timeDepth = `${Math.abs(diffInMinutes)} min${Math.abs(diffInMinutes) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInHours) < 24) {
                timeDepth = `${Math.abs(diffInHours)} hour${Math.abs(diffInHours) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInDays) < 7) {
                timeDepth = `${Math.abs(diffInDays)} day${Math.abs(diffInDays) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInWeeks) < 4) {
                timeDepth = `${Math.abs(diffInWeeks)} week${Math.abs(diffInWeeks) !== 1 ? 's' : ''}`;
            } else if (Math.abs(diffInMonths) < 12) {
                timeDepth = `${Math.abs(diffInMonths)} month${Math.abs(diffInMonths) !== 1 ? 's' : ''}`;
            } else {
                timeDepth = `${Math.abs(diffInYears)} year${Math.abs(diffInYears) !== 1 ? 's' : ''}`;
            }
        
            return `${formattedDate} (${now.isAfter(inputDate) ? `${timeDepth} ago` : `${timeDepth} left`})`;
        };
        
        
        
        
    return(
        <div style={{ display: 'flex', height: '100vh'}}>
            <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
               <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Subscriptions'} />                  
              </div>
              <div style={{width:'100%',display:'flex', flexDirection:'row',justifyContent:'center',alignItems:'center',gap:10,flexWrap:'wrap'}}>
                <div style={{width:'65%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#fff',padding:10,borderRadius:20}}>
                    <div className="planHeader" style={{width:'100%',padding:3,borderBottomWidth:1,borderBottomColor:'#b8b8b8',textAlign:'center'}}>
                        <h5>Current plan</h5>
                        {showLoader ? 
                            <>
                                <PulseLoader color='#FCC100' size={10} />
                            </>
                            :
                            <>
                            {subscriptions &&                        
                                <>
                                <h1 style={{marginTop:-10,color:subscriptions.active_plan.color_code,fontWeight:100}}>{subscriptions.active_plan.name}</h1>
                                {subscriptions.active_plan.expired ?
                                    <>
                                    <h5 style={{color:'red'}}>This plan has expired</h5>
                                    </>
                                    :
                                    <>
                                    {subscriptions.active_plan.expiring_soon ? 
                                        <>
                                            <h5 style={{color:'red'}}>This plan is expiring soon</h5>
                                            <p style={{color:'red'}}>Expiring on {formatDate(subscriptions.active_plan.end_date)}</p>
                                        </>
                                        :
                                        <>
                                            <h5 style={{color:'green'}}>Active</h5>
                                            <p style={{color:'#000',fontSize:10,marginTop:-20}}>Expiring on {formatDate(subscriptions.active_plan.end_date)}</p>
                                        </>
                                    }
                                    </>

                                }
                                <div style={{width:'100%',padding:5,justifyContent:'center',alignItems:'center'}}>
                                <table style={{width: '100%',borderCollapse: 'collapse',backgroundColor: '#fff',borderRadius: '10px',overflow: 'hidden',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.08)',fontFamily: 'Arial, sans-serif',fontWeight: 300}} align="center">
                                  
                                    <tbody>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#f9f9f9' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Client Limit
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right', fontWeight:100, fontSize:12,color:'#b8b8b8' }}>
                                               {subscriptions.plan_features.client_limit == 0 ? 'Unlimited' : subscriptions.plan_features.client_limit} Clients
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#f9f9f9' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                In-App Communication
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {renderFeature(subscriptions.plan_features.in_app_interaction, 'in_app_interaction')}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#fff' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Billing & Invoicing
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {getFeatureIcon(subscriptions.plan_features.billing_invoicing)}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#f9f9f9' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Service Contracts
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {getFeatureIcon(subscriptions.plan_features.service_contracts)}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#fff' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Webclient Access
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {getFeatureIcon(subscriptions.plan_features.web_access)}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#f9f9f9' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Advanced reports
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {getFeatureIcon(subscriptions.plan_features.reports)}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#f9f9f9' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Product / Service catalog display
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {getFeatureIcon(subscriptions.plan_features.product_service_catalog)}
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #eaeaea', backgroundColor: '#fff' }}>
                                            <td style={{ padding: '10px 12px', color: '#666', fontSize: '14px', textAlign: 'left' }}>
                                                Priority Support
                                            </td>
                                            <td style={{ padding: '10px 12px', textAlign: 'right' }}>
                                                {getFeatureIcon(subscriptions.plan_features.priority_support)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>


                                </div>
                                </>
                            }
                            </>
                            }
                           {subscriptions?.additional_client_packs?.length > 0 && (
                                <>
                                    <h4 style={{ marginTop: 20,fontWeight:300 }}>Additional clients pack</h4>    
                                    <h2 style={{ fontWeight:100,marginTop:-10,color:subscriptions.additional_client_packs[0].color_code}}>{subscriptions.additional_client_packs[0].name}</h2> 
                                    <p>Allows you to have <strong>{subscriptions.additional_client_packs[0].client_pack}</strong> additional clients served through the app.</p>   

                                    {subscriptions.additional_client_packs[0].expired ?
                                    <>
                                    <h5 style={{color:'red'}}>This plan has expired</h5>
                                    </>
                                    :
                                    <>
                                    {subscriptions.additional_client_packs[0].expiring_soon ? 
                                        <>
                                            <h5 style={{color:'red'}}>This plan is expiring soon</h5>
                                            <p style={{color:'red'}}>Expiring on {formatDate(subscriptions.additional_client_packs[0].end_date)}</p>
                                        </>
                                        :
                                        <>
                                            <h5 style={{color:'green'}}>Active</h5>
                                            <p style={{color:'#000',fontSize:10,marginTop:-20}}>Expiring on {formatDate(subscriptions.additional_client_packs[0].end_date)}</p>
                                        </>
                                    }
                                    </>

                                }
                                </>
                            )}

                    </div>
                    


                </div>
              </div>
            </div>
        </div>
    )       
}

export default Subscriptions;