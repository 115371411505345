import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import InfoGraphic from '../../assets/images/infographic_center_bottom.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import Login from '../Login/Login';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart 
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';

import './Dashboard.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdChatBubbleOutline, MdVideoCameraFront, MdCheckCircle, MdCancel } from "react-icons/md";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);


const Dashboard = () =>{
  const { token } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [taskLoader, setTaskLoader] = useState(false);
  const [sp_unique, setSpUnique] = useState(null);
  const [basicError, setBasicError] = useState('');
  const [currentPlan, setCurrentPlan] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [allPlans, setAllPlans] = useState(null);
  const [planActive, setPlanActive] = useState(true);
  const [renewalPrices, setRenewalPrices] = useState(null);
  const [upgradePlans, setUpgradePlans] = useState(null);
  const [country, setCountry] = useState(null);
  const [showPlanExpiry, setShowPlanExpiry] = useState(false);
  const [showPlanSupport, setShowPlanSupport] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [secondUpgradeCard, setSecondUpgradeCard] = useState(false);
  const [selfProfile, setSelfProfie] = useState(null);

  const [totalServices, setTotalServices] = useState(null);
  const [statusChartData, setStatusChartData] = useState(null);
  const [paidFreeChart, setPaidFreeChart] = useState(null);

  const [boardLoader, setBoardLoader] = useState(false);

  //other dashboard data
  useEffect(() => {
   if(token){
    fetchDetails();
    
   }
  }, [token]);    
    const navigate = useNavigate();

    const fetchDetails = async() =>{
        setTaskLoader(true);

        try{
          const response = await fetch('https://access.myservicecircle.app/wcapi/3_verifyDashboard.php',{
            method:'POST',
            headers:{
              'Content-Type' : 'application/json'
            },
            body:JSON.stringify({token})
          });

          const responseData = await response.json();
          
          if(responseData.success == 1){
            setSpUnique(responseData.sp_unique); 
            localStorage.setItem('sp_unique',responseData.sp_unique);
          }else{
            navigate('/Login');
          }
        }catch(error){
          console.log(error);
        }finally{
          setTaskLoader(false);
        }
    }

    useEffect(()=>{
      if(sp_unique){
        fetchController();
        
      }
    },[sp_unique]);

    const fetchController = async () => {
      setTaskLoader(true); // Show loader
      try {
        const response = await fetch(
          'https://access.myservicecircle.app/sp/7a_fetchController.php',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sp_unique }), // Ensure sp_unique is defined
          }
        );
        
        //alert(JSON.stringify(response));
        if (!response.ok) {
          // Handle HTTP errors
          throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
        }
    
        const responseData = await response.json();
        //alert(JSON.stringify(responseData));
        // Update states with API response
        setCurrentPlan(responseData.plan);           
        setCountry(responseData.country);
        setPlanActive(responseData.is_active > 0 ? true : false); 
        setEndDate(responseData.end_date);
        fetchAllPlans();
        
      } catch (error) {
        // Log errors for debugging
        console.error('Error fetching controller:', error);
      } finally {
        setTaskLoader(false); // Hide loader
      }
    };

    useEffect(()=>{
      if(!planActive){
        setShowPlanExpiry(true);
        fetchPlanDetails();
      }
    },[planActive]);

    
    const fetchAllPlans = async () => {
      try {
          console.log("Debug - Starting API request...");
          
          const dataString = {
              sp_unique: "test_sp_unique", // Hardcoded test value
              test: 1
          };    
          
          console.log("Debug - Request Payload:", JSON.stringify(dataString));
  
          const response = await fetch('https://access.myservicecircle.app/sp/7b_fetchPlansAndController.php', 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ sp_unique }), // Ensure sp_unique is defined
            }
        );
  
          console.log("Debug - Response received:", response);
  
          const responseText = await response.text();
          console.log("Debug - Raw Response Text:", responseText);
  
          if (!response.ok) {
              throw new Error(`HTTP Error: ${response.status} ${response.statusText}`);
          }
  
          const responseData = JSON.parse(responseText);
          console.log("Debug - Response JSON:", responseData);
  
          setAllPlans(responseData.plans);
  
      } catch (error) {
          console.error("Fetch error:", error);
      } finally {
          setTaskLoader(false);
      }
  };
  
  
    
    const fetchDashBoardCount = async ()=>{
      
    }

    useEffect(()=>{
      if(!renewalPrices){
        fetchPlanDetails();
      }
    },[renewalPrices]);

    const fetchPlanDetails = () =>{

      if(currentPlan && allPlans){
        const planData = allPlans.filter(plan=> plan.plan_id === currentPlan.plan_id);
        console.log(JSON.stringify(planData));
        setRenewalPrices(planData[0]);
      }

    }

    const fetchUpgradeOptions = () =>{
      if(allPlans && currentPlan){
        const plan = allPlans.filter(plan=>plan.features.web_access>0 && plan.type > currentPlan.type);
        setUpgradePlans(plan);
      }
      
    }

     const RenewalPrices = ({days}) =>{

        let symbol;
        let currency;
        let color;

        if(country && country === 'India'){
          currency = 'inr';
          symbol = '₹';
        }else if(country && country === 'United States'){
          currency = 'usd';
          symbol = '$';
        }else{          
          currency = 'eur';
          symbol = '€';
        }

        if(days == 30){
          color = '#fe218b';
        }else if(days == 180){
          color = '#fed700';
        }else{
          color = '#21b0fe';
        }

        const price = renewalPrices[`price_${days}_${currency}`];

        const fakePricePerc = price*20/100;

        const fakePrice = parseInt(price)+parseInt(fakePricePerc);

      return(
        <div style={{width:200,padding:10,borderRadius:10,backgroundColor:color,justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column',boxShadow:'0px 4px 4px rgba(0,0,0,0.20)'}}>
          <p style={{fontSize:15,fontWeight:'bold',marginBottom:-10,color:'#fff'}}>Renew for {days} days</p>
          <div style={{width:'100%',padding:10,justifyContent:'center',alignItems:'center',display:'flex',marginBottom:-10,color:'#fff'}} className='fakePrice'>{symbol}{fakePrice}</div>
          <div style={{width:'100%',padding:10,justifyContent:'center',alignItems:'center',display:'flex',color:'#fff'}}>@ {symbol}{price}</div>
          <Button style={{backgroundImage: 'linear-gradient(#363636, #19191a)',color:'#fff'}} onClick={()=>sendToPay(days)}>Renew for {days} days</Button>
        </div>
      )
     }

     const sendToPay = async(days) =>{
     // alert(currentPlan.plan_id);
      navigate(`/PaymentPlanRenew/${currentPlan.plan_id}/${days}/`);
     }

    useEffect(()=>{
      //alert(allPlans);
      if(allPlans && currentPlan){        
        const activePlanDetail = allPlans.filter(plan => String(plan.plan_id) === String(currentPlan.plan_id));
          setActivePlan(activePlanDetail[0]);
          
          if(activePlanDetail[0].features.web_access > 0){
            fetchDashBoardCount();
            
          }else{
            setShowPlanSupport(true);
            
          }
      }
      fetchUpgradeOptions();
    },[allPlans, currentPlan]);

    useEffect(()=>{
      if(!planActive){
        toaster.warning(`One or more of your subscriptions have expired`, {
          duration: 5, 
          description: 'Please check out the renewal option below',
          intent: 'success',           
        });
      }
    },[planActive]);

    const openUpgradeOptions = (data) =>{
      setSelectedPlan(data);
    }

    useEffect(()=>{
      if(selectedPlan){
        setUpgradeModal(true);                
      }else{
        setUpgradeModal(false);
      }
    },[selectedPlan]);

   
    const calculateDiscountedPrice = (days) => {
      //alert(activePlan);
      if (!activePlan || !country || !endDate || !selectedPlan) {
        console.error("Required details are missing: activePlan, country, endDate, or selectedPlan.");
        return null;
      }
    
      // Set color and symbol based on the selected days
      let color, txtColor, symbol;
      if (days === 30) {
        color = '#2b2d42';
        txtColor = '#fff';
      } else if (days === 180) {
        color = '#8d99ae';
        txtColor = '#fff';
      } else {
        color = '#edf2f4';
        txtColor = '#000';
      }
    
      // Determine currency and symbol
      let priceKey;
      if (country === 'India') {
        priceKey = `price_${days}_inr`;
        symbol = '₹';
      } else if (country === 'United States') {
        priceKey = `price_${days}_usd`;
        symbol = '$';
      } else {
        priceKey = `price_${days}_eur`;
        symbol = '€';
      }
    
      // Extract 30-day pricing for the current plan
      const currentPlan30DayPriceKey = `price_30_${priceKey.split('_')[2]}`;
      const currentPlan30DayPrice = parseFloat(activePlan[currentPlan30DayPriceKey]);
    
      if (isNaN(currentPlan30DayPrice)) {
        console.error(`Invalid or missing price for key: ${currentPlan30DayPriceKey}`);
        return null;
      }
    
      // Extract the pricing for the new plan
      const newPlanPrice = parseFloat(selectedPlan[priceKey]);
    
      if (isNaN(newPlanPrice)) {
        console.error(`Invalid or missing price for key: ${priceKey}`);
        return null;
      }
    
      // Calculate the remaining days in the current plan
      const today = new Date();
      const planEndDate = new Date(endDate.replace(' ', 'T'));
      const remainingDays = Math.max(0, Math.ceil((planEndDate - today) / (1000 * 60 * 60 * 24)));
    
      if (remainingDays === 0) {
        console.warn("Plan has already expired. No discount applicable.");
      }
    
      // Calculate the per-day cost based on the 30-day pricing and discount value
      const perDayCost = currentPlan30DayPrice / 30;
      const discountValue = remainingDays * perDayCost;
    
      // Inflate the fake price for display purposes
      const fakePrice = Math.ceil(newPlanPrice * 1.2);
    
      return (
        <div
          style={{
            width: 150,
            padding: 10,
            borderRadius: 10,
            backgroundColor: color,
            boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.20)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: 10,
              color: txtColor,
              fontWeight: 'bold',
              justifyContent: 'center',
              fontSize: 12,
              padding: 10,
              textAlign: 'center',
            }}
          >
            Upgrade for {days} days
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              color: txtColor,
              flexDirection: 'row',
              gap: 10,
              textDecoration: 'line-through',
              color: '#b8b8b8',
              justifyContent: 'center',
            }}
          >
            {symbol}{fakePrice.toFixed(2)}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              color: txtColor,
              flexDirection: 'row',
              gap: 10,
              fontWeight: 'bold',
              justifyContent: 'center',
            }}
          >
            {symbol}{newPlanPrice.toFixed(2)}
          </div>
          {discountValue > 0 && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                color: txtColor,
                gap: 10,
                fontSize: 10,
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              An additional discount of {symbol}{discountValue.toFixed(2)} will be applied for the remaining days in your
              current plan.
            </div>
          )}
          <Button
            style={{
              width: 100,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              gap: 5,
              padding: 10,
              borderRadius: 5,
              backgroundImage: 'linear-gradient(#363636, #19191a)',
              color: '#fff',
              fontWeight: 200,
              border: '0px solid #fff',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.20)',
              marginTop: 20,
            }}
            onClick={() => paymentGatewayRedirect(days)}
          >
            Upgrade
          </Button>
        </div>
      );
    };
    
    

    const paymentGatewayRedirect = (days) => {
      if (selectedPlan.plan_id) {
        const discount = calculateDiscountedPrice();
        let finalDiscount;
        if(discount){
          finalDiscount = discount;
        }else{
          finalDiscount = 0;
        }
       navigate(`/PaymentPlanUpgrade/${selectedPlan.plan_id}/${selectedPlan.name}/${days}/${finalDiscount}`);

            
      } else {
        toaster.warning("Please select a plan before proceeding.");
      }
    };
    
    useEffect(()=>{
      fetchDashboardCharts();
    },[]);
    
    const fetchDashboardCharts = async () => {
      const sp_unique = localStorage.getItem("sp_unique");
    
      if (!sp_unique) {
        navigate("./Login");
        return;
      }
      setBoardLoader(true);
    
      try {
        const response = await fetch(
          "https://access.myservicecircle.app/wcapi/4_dashboardCharts.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ sp_unique }),
          }
        );
    
        const responseData = await response.json();
    
        if (responseData.success === 1) {
          const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
          ];
    
          // Total Services Bar Chart
          const labels = responseData.bar_chart.map((item) => {
            const [year, month] = item.month.split("-");
            return `${year}-${monthNames[parseInt(month, 10) - 1]}`;
          });
    
          const data = responseData.bar_chart.map((item) =>
            parseInt(item.total_services, 10)
          );
    
          const backgroundColors = data.map((count, index) => {
            const baseColor = index % 2 === 0 ? "hsl(280, 65%, 60%)" : "hsl(45, 100%, 60%)";
            const lightnessAdjustment = Math.max(30, 70 - count * 3);
            const [hue, saturation] = baseColor.match(/\d+/g);
            return `hsl(${hue}, ${saturation}%, ${lightnessAdjustment}%)`;
          });
    
          const formattedBarChart = {
            labels,
            datasets: [
              {
                label: "Total Services Per Month",
                data,
                backgroundColor: backgroundColors,
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderWidth: 1,
              },
            ],
          };
    
          // Status Mapping
          const statusMap = {
            1: "Awaiting Agent",
            2: "Agent Allotted",
            3: "Agent En-Route",
            4: "Agent at Site",
            5: "Product Picked Up",
            6: "Service Rendered",
            7: "Service Complete",
            8: "Service Under Dispute",
            9: "Cancelled by Customer",
            10: "Cancelled by You"
          };
    
          // Status Chart Data (Pie Chart)
          const statusLabels = responseData.status_chart.map((item) => statusMap[item.status] || `Unknown Status ${item.status}`);
          const statusData = responseData.status_chart.map((item) => parseInt(item.count, 10));
    
          const statusPieChart = {
            labels: statusLabels,
            datasets: [
              {
                label: "Service Status",
                data: statusData,
                backgroundColor: ["#AE52E0", "#FCC100", "#FF6384", "#36A2EB", "#4BC0C0", "#FF9F40", "#9966FF", "#9c160c", "#4BC0C0", "#FF6384"],
              },
            ],
          };
    
          // Paid/Free Chart Data (Pie Chart)
          const paidFreeLabels = responseData.paid_free_chart.map((item) => item.type);
          const paidFreeData = responseData.paid_free_chart.map((item) => parseInt(item.count, 10));
    
          const paidFreePieChart = {
            labels: paidFreeLabels,
            datasets: [
              {
                label: "Paid vs Free Services",
                data: paidFreeData,
                backgroundColor: ["#AE52E0", "#FCC100"],
              },
            ],
          };
    
          // Set all states
          setTotalServices(formattedBarChart);
          setStatusChartData(statusPieChart);
          setPaidFreeChart(paidFreePieChart);
        }
      } catch (error) {
        console.log("Error fetching charts:", error);
      } finally {
        setBoardLoader(false);
      }
    };
    
    
    

    const barOptions = {
      responsive: true,
      plugins: {
        legend: { display: true, position: 'top' },
        title: { display: true, text: 'Monthly Total Services' },
      },
      scales: {
        x: { title: { display: true, text: 'Month' } },
        y: { title: { display: true, text: 'Total Services' }, beginAtZero: true },
      },
      
    };

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return (
      <>
        <div style={{ display: 'flex', height: '100vh'}}>
          <SideBar />
            <div style={{flex: 1,overflowY: 'auto',backgroundColor:'#e6e6e6'}}>
              <div style={{width:'90%',justifyContent:'flex-start',alignItems:'flex-start',padding:10}}>
                  <HeaderBar page_name={'Dashboard'} />                  
              </div>
              {/* primary center chart */}
              <div style={{width:'100%',display:'flex', flexDirection:'row',justifyContent:'center',alignItems:'center',gap:10,flexWrap:'wrap'}}>
                  <div style={{width:'65%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#fff',padding:10,borderRadius:20}}>
                      {boardLoader ? (
                        <CircleLoader style={{ fontSize: 15, color: "#554694" }} />
                      ) : totalServices ? (
                        <Bar data={totalServices} options={barOptions} />
                      ) : (
                        <p>No data available</p>
                      )}
                  </div>
                  <div style={{width:'25%',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#fff',padding:10,borderRadius:20,flexDirection:'column'}}>
                  {boardLoader ?
                        <>
                          <CircleLoader style={{fontSize:15,color:'#554694'}}/>
                        </>
                        :
                        <> 
                        {statusChartData && (
                          <div style={{ width: "50%", marginBottom: 20 }}>
                            <h4 style={{ textAlign: "center", marginBottom: 10 }}>Service Status</h4>
                            <Pie data={statusChartData} />
                          </div>
                        )}

                        {/* Paid/Free Pie Chart */}
                        {paidFreeChart && (
                          <div style={{ width: "50%" }}>
                            <h4 style={{ textAlign: "center", marginBottom: 10 }}>Paid vs Free Services</h4>
                            <Pie data={paidFreeChart} />
                          </div>
                        )}
                        </>
                      }
                  </div>
              </div>
            </div>
          
        </div>

        {secondUpgradeCard &&
          <div className='secondCard'>

          </div>
        }

          {showPlanExpiry && !planActive &&
            <div className='loaderMask'>
                <div style={{width:'100%',padding:10,justifyContent:'center',alignItems:'center',display:'flex',height:'auto',flexDirection:'column'}}>
                    <h3 style={{color:'#000'}}>On or more of your plans/packs have expired</h3>
                    
                    <div style={{width:300,padding:5,borderRadius:5,backgroundImage: 'linear-gradient(#363636, #19191a)',boxShadow:'0px 4px 4px rgba(0,0,0,0.20)',textAlign:'center',flexDirection:'column'}}>                        
                        <p style={{fontSize:15,color:'#fff'}}>Please open the mobile app and manage the same under "My Subscriptions" on the main menu</p>    
                        
                    </div>
                    

                    
                </div>
            </div>
          } 
          <Dialog           
            isShown={upgradeModal}
            title= {selectedPlan ? selectedPlan.name :''}
            onCloseComplete={() => {setSelectedPlan(null);setUpgradeModal(false)}}
            hasFooter={false} 
            width={'50%'}
          >
           <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'row',gap:20}}>
              <div>{calculateDiscountedPrice(30)}</div>
              <div>{calculateDiscountedPrice(180)}</div>
              <div>{calculateDiscountedPrice(365)}</div>
           </div>
          </Dialog>
          {showPlanSupport &&
          <div className='loaderMask'>
               <div style={{width:'100%',padding:10,justifyContent:'center',alignItems:'center',display:'flex',height:'auto',flexDirection:'column'}}>  
               <h3 style={{color:'#19191a'}}>Your current plan {currentPlan.name} does not support Web Client. But there is nothing to worry about!</h3>                  
                    <div style={{width:300,padding:10,borderRadius:5,backgroundImage: 'linear-gradient(#363636, #19191a)',boxShadow:'0px 4px 4px rgba(0,0,0,0.20)',textAlign:'center',flexDirection:'column'}}>
                        
                        <p style={{fontSize:12,color:'#fff'}}>We got some exclusive upgrade offers for you !!!</p>                        
                    </div>
                    
                    {upgradePlans &&
                      <>
                        <div style={{width:'70%',padding:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-start'}}>
                            <div style={{width:200,display:'flex',flexDirection:'column',backgroundColor:'#fff',borderTopLeftRadius:20,borderBottomLeftRadius:20,padding:10,boxShadow:'0px 1px 2px rgba(0,0,0,0.5)'}}>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>In-app communication</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>Invoicing</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>Service Contracts</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>Custom invoice declaration</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>Web client access</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>Advanced reports</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'left'}} className='planTitles'>Product/Service catalog</div>
                                <div style={{width:'90%',display:'flex',padding:10, textAlign:'left'}} className='planTitles'>Priority support</div>                                
                            </div>
                            {upgradePlans.map((item,index)=>(                           
                            <div key={index} style={{width:120,display:'flex',flexDirection:'column',backgroundColor:'#fff',padding:10,boxShadow:'0px 1px 2px rgba(0,0,0,0.5)',justifyContent:'center',alignItems:'center',borderTopRightRadius: index === upgradePlans.length - 1 ? 20 : 0,borderBottomRightRadius: index === upgradePlans.length - 1 ? 20 : 0,borderBottomLeftRadius: index == 0 ? 20 : 0}}>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                {item.features.in_app_interaction == 1? 
                                  <><MdOutlineChat className='iconStyle'/> </>
                                  : item.features.in_app_interaction == 2 ?
                                  <><MdOutlineChat className='iconStyle' /> <MdCall className='iconStyle' /></>
                                  :
                                  <><MdOutlineChat className='iconStyle' /> <MdCall className='iconStyle' /> <MdOutlineVideoCameraFront className='iconStyle' /></>
                                }
                                </div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                  {item.features.billing_invoicing > 0 ?
                                    <MdOutlineCheck className='iconCheck' />
                                    :
                                    <MdOutlineCancel className='iconCancel' />
                                  }
                                </div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                {item.features.service_contracts > 0 ?
                                    <MdOutlineCheck className='iconCheck' />
                                    :
                                    <MdOutlineCancel className='iconCancel' />
                                  }
                                </div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                {item.features.custom_invoice_declaration > 0 ?
                                    <MdOutlineCheck className='iconCheck' />
                                    :
                                    <MdOutlineCancel className='iconCancel' />
                                  }
                                </div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                {item.features.web_access > 0 ?
                                    <MdOutlineCheck className='iconCheck' />
                                    :
                                    <MdOutlineCancel className='iconCancel' />
                                  }
                                </div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'> {item.features.reports > 0 ?
                                    <MdOutlineCheck className='iconCheck' />
                                    :
                                    <MdOutlineCancel className='iconCancel' />
                                  }</div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                  {item.features.product_service_catalog > 0 ?
                                      <MdOutlineCheck className='iconCheck' />
                                      :
                                      <MdOutlineCancel className='iconCancel' />
                                    }
                                </div>
                                <div style={{width:'90%',display:'flex',borderBottom: '1px solid rgb(224, 224, 224)',padding:10, textAlign:'center'}} className='planTitles'>
                                  {item.features.priority_support > 0 ?
                                    <MdOutlineCheck className='iconCheck' />
                                    :
                                    <MdOutlineCancel className='iconCancel' />
                                  }
                                  </div>                                
                                <div style={{width:'90%',display:'flex',backgroundColor:'#fff',padding:10, textAlign:'left',color:'#fff',borderRadius:10,flexDirection:'column',justifyContent:'center',alignItems:'center'}} className='planTitles'>
                                  <Button style={{backgroundColor:item.color_code,color:'#fff'}} onClick={()=>openUpgradeOptions(item)}>{item.name}</Button>
                                  <p style={{color:'#000',textAlign:'center',fontSize:8}} >Click to see upgrade options</p>
                                </div>                                
                                                             
                            </div>
                          ))}
                        </div>
                        
                      </>
                    }
              </div>


          </div>
          }
          
          {taskLoader &&
           
            <div className='loaderBox'>
              <CircleLoader color='#FCC100' size={30} />
            </div>          
           
          }
          {basicError &&
          <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '10px',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          <p style={{ color: '#000', marginBottom: '20px' }}>{basicError}</p>
          <button
            onClick={()=>setBasicError('')}
            style={{
              padding: '10px 20px',
              backgroundColor: '#400982',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            Close
          </button>
        </div>
          }
        </>
      );
}


export default Dashboard;