import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './BottomNavigation.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

const MobileRestriction = () =>{

    return(
        <div style={{width:'100%',height:'100%',top:'0px',left:'0px',position:'absolute',display:'flex',justifyContent:'center',alignItems:'center',}}>
            <div style={{display:'flex',padding:'20px',fontSize:'25px',fontWeight:100,color:'#fff',textAlign:'center'}}>
                Oops! <br></br>WebClient cannot be accessed through on-the-go devices, except for laptops.<br></br><br></br>Please use a laptop or a desktop.
            </div>
        </div>
    )
}

export default MobileRestriction;