import React, {useState, useEffect, CSSProperties } from 'react';
import logoLong from '../../assets/images/logo_full_length.png';
import logoSmall from '../../assets/images/logo.png';
import upscaledImage from '../../assets/images/upscaled.png';
import BottomNavigation from '../Common/BottomNavigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import './PaymentUpgradeMain.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import { motion } from "framer-motion";
import { useNavigate,useLocation, data} from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { 
  FaHome, FaUserAlt, FaCog, FaClipboard, FaChartBar, FaTools, FaRegFileAlt, 
  FaUsers, FaUserTie, FaComments, FaBell, FaShoppingCart, 
  FaFontAwesome
} from 'react-icons/fa';
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FiPackage } from "react-icons/fi";
import HeaderBar from '../Common/HeaderBar';
import SideBar from '../Common/SideBar';

import '../Dashboard/Dashboard.css';

import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader } from 'react-spinners';
import { EditablePropsProvider } from '@chakra-ui/react';
import { Pane, Heading, Text, Card, Button, Dialog, TextInput} from 'evergreen-ui';
import { toaster } from 'evergreen-ui';
import { MdOutlineChat, MdCall, MdOutlineVideoCameraFront, MdOutlineCheck, MdOutlineCancel, MdOutlineAddShoppingCart, MdBorderRight, MdCheck, MdClose} from "react-icons/md";
import { LuBicepsFlexed } from 'react-icons/lu';
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";

const PaymentPlanUpgrade = () =>{

    const [allPlans, setAllPlans] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selfProfile, setSelfProfile] = useState(null);
    const [essentials, setEssentials] = useState(null);
    const [taskLoader, setTaskLoader] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [basePrice, setBasePrice] = useState(null);
    
    const {planId, planName, days, discount} = useParams();
    //const location = useLocation();
    //const { planId, planName, days, discount } = location.state || {};

    const [calculatedDiscount, setCalculatedDiscount] = useState(null);

    const [finalPrice, setFinalPrice] = useState('0.00');


    const [couponBox, setCouponBox] = useState(false);
    const [couponCode, setCouponCode] = useState(null);
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponDiscount, setCouponDiscount] = useState(null);

    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentFailure, setPaymentFailure] = useState(false);

    const navigate = useNavigate();
    useEffect(()=>{
        fetchAllPlans();
        fetchCurrentPlan();
        fetchSelfProfile();
    },[]);

    useEffect(() => {
        const allowedDays = ['30', '180', '365'];
        if (!allowedDays.includes(days)) {
            alert("Oops! you have messed up with the payment URL");
            navigate('./Login'); // Navigate to the entry point
        }
    }, [days, navigate]);
    

    const fetchAllPlans = async() =>{
        const sp_unique = localStorage.getItem('sp_unique');
        
        if(!sp_unique){
            alert("Something isn't right!");
            navigate('./Login');
            return;
        }
        setTaskLoader(true);
        try{
            const response = await fetch('https://access.myservicecircle.app/sp/7b_fetchPlansAndController.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body:JSON.stringify({sp_unique})
            });

            const responseData = await response.json();
            setAllPlans(responseData.plans);

        }catch(error){
            console.log(error)
        }finally{
            
        }
    }

    const fetchCurrentPlan = async () =>{
        const sp_unique = localStorage.getItem('sp_unique');
        if(!sp_unique){
            alert("Something isn't right!");
            navigate('./Login');
            return;
        }
        setTaskLoader(true);
        try{
            const response = await fetch('https://access.myservicecircle.app/sp/7a_fetchController.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({sp_unique})
            });

            const responseData = await response.json();
            setCurrentPlan(responseData.plan);
            setEndDate(responseData.end_date);            

        }catch(error){
            console.log(error);
        }finally{
        setTaskLoader(false);
        }
    }

    const fetchSelfProfile = async()=>{
        const sp_unique = localStorage.getItem('sp_unique');
        
        if(!sp_unique){
            alert("Something isn't right!");
            navigate('./Login');
            return;
        }
        setTaskLoader(true);
        try{
            const response = await fetch('https://access.myservicecircle.app/sp/1_selfData.php',{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json'
                },
                body:JSON.stringify({sp_unique})
            });

            const responseData = await response.json();
            setSelfProfile(responseData.details);

        }catch(error){
            console.log(error)
        }finally{
            setTaskLoader(false);
        }
    }

    useEffect(()=>{
        if(allPlans && planId && selfProfile && days && currentPlan){
            const plan = allPlans.filter(plan=> plan.plan_id === planId);
            if(!planId || !plan){
                alert("Oops! there was a modification in the url.");
                navigate('./Login');
            }
            setSelectedPlan(plan[0]);
        }
    },[allPlans,planId, selfProfile, days, currentPlan]);

   useEffect(()=>{
    if(selectedPlan){
      if(days){
        if(selfProfile){
          let symbol, currency, newPriceKey;
      
          // Determine the currency and symbol based on the user's country
          if (selfProfile.country === 'India') {      
            symbol = '₹';
            currency = 'inr';
          } else if (selfProfile.country === 'United States') {        
            symbol = '$';
            currency = 'usd';
          } else {                 
            symbol = '€';
            currency = 'eur';
          }
        
          // Create the dynamic price key
          newPriceKey = `price_${days}_${currency}`;
        
          // Extract the price from the selected plan
          const price = selectedPlan[newPriceKey];
          setBasePrice(price);
        }
      }
    }
   },[selectedPlan,days,selfProfile]);

    const calculateBasePrice = () => {
        if (!selectedPlan || !days || !selfProfile) {
          console.warn("Missing required inputs: selectedPlan, days, or selfProfile.");
          return null; // Return null if any of the required values are missing
        }
      
        let symbol, currency, newPriceKey;
      
        // Determine the currency and symbol based on the user's country
        if (selfProfile.country === 'India') {      
          symbol = '₹';
          currency = 'inr';
        } else if (selfProfile.country === 'United States') {        
          symbol = '$';
          currency = 'usd';
        } else {                 
          symbol = '€';
          currency = 'eur';
        }
      
        // Create the dynamic price key
        newPriceKey = `price_${days}_${currency}`;
      
        // Extract the price from the selected plan
        const price = selectedPlan[newPriceKey];
        
        if (!price || isNaN(price)) {
          console.error(`Invalid or missing price for key: ${newPriceKey}`);
          return null; // Return null if the price is invalid
        }
        
        // Format the price and return
        return `${symbol} ${parseFloat(price).toFixed(2)}`;
      };

      const TaxInfo = () => {
        if (!selfProfile || !selectedPlan || !days) {
          console.warn('Required dependencies are missing in TaxInfo');
          return null;
        }
      
        const { essentials, country, state } = selfProfile;
      
        if (!essentials || !essentials.cgst || !essentials.sgst || !essentials.igst) {
          console.warn('Tax percentages missing in essentials');
          return null;
        }
      
        const { cgst, sgst, igst } = essentials;
      
        let symbol, currency, newPriceKey;
        if (country === 'India') {      
          symbol = '₹';
          currency = 'inr';
        } else if (country === 'United States') {        
          symbol = '$';
          currency = 'usd';
        } else {                 
          symbol = '€';
          currency = 'eur';
        }
      
        newPriceKey = `price_${days}_${currency}`;
        const price = selectedPlan[newPriceKey];
        let newPrice;
        newPrice = price;
        if(calculatedDiscount){
          const discountedBase = parseFloat(newPrice) - parseFloat(calculatedDiscount.value);
          newPrice = discountedBase;
          console.log("NEW PRICE", newPrice);
        }

        if(couponCode && couponApplied && couponDiscount){
          const newp = parseFloat(newPrice)*parseFloat(couponDiscount)/100;
          const newCalc = parseFloat(newPrice)-parseFloat(newp);
          newPrice = newCalc;
        }
      
        if (selfProfile.state === 'Maharashtra') {
          const cgstAmount = (parseFloat(newPrice) * parseFloat(cgst)) / 100;
          const sgstAmount = (parseFloat(newPrice) * parseFloat(sgst)) / 100;
          console.log("CGST CALC", cgstAmount)
          return (
            <>
              <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, borderBottom: '1px solid #b8b8b8', padding: 10 }}>
                <div style={{ width: 200, textAlign: 'left', fontSize: 14, color: '#000' }}>Tax : CGST @ {cgst}%</div>
                <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 14, color: '#000', paddingRight: 20 }}>{symbol} {parseFloat(cgstAmount).toFixed(2)}</div>
              </div>
              <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, borderBottom: '1px solid #b8b8b8', padding: 10 }}>
                <div style={{ width: 200, textAlign: 'left', fontSize: 14, color: '#000' }}>Tax : SGST @ {sgst}%</div>
                <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 14, color: '#000', paddingRight: 20 }}>{symbol} {parseFloat(sgstAmount).toFixed(2)}</div>
              </div>
            </>
          );
        } else {
          const igstAmount = (parseFloat(newPrice) * parseFloat(igst)) / 100;
      
          return (
            <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, borderBottom: '1px solid #b8b8b8', padding: 10 }}>
              <div style={{ width: 200, textAlign: 'left', fontSize: 14, color: '#000' }}>Tax : IGST @ {igst}%</div>
              <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 14, color: '#000', paddingRight: 20 }}>{symbol} {parseFloat(igstAmount).toFixed(2)}</div>
            </div>
          );
        }
      };

      useEffect(() => {
        if (selectedPlan && currentPlan && endDate && days) {
          calculateDiscount(days); 
        }
      }, [selectedPlan, currentPlan, endDate, days]);

      const calculateDiscount = (days) => {
        if (!allPlans || !currentPlan || !selectedPlan || !endDate) {
          console.error("Missing required state: allPlans, currentPlan, selectedPlan, or endDate.");
          setCalculatedDiscount(null);
          return;
        }
      
        // Determine the price key and symbol based on the user's country
        let symbol, currency, priceKey;
        if (selfProfile.country === 'India') {
          symbol = '₹';
          currency = 'inr';
        } else if (selfProfile.country === 'United States') {
          symbol = '$';
          currency = 'usd';
        } else {
          symbol = '€';
          currency = 'eur';
        }
        
        const c_plan = allPlans.filter(c_plan=> String(c_plan.plan_id) === String(currentPlan.plan_id));
        
        // Always use the 30-day price for discount calculation
        //alert(JSON.stringify(c_plan));
        const currentPlanPriceKey = `price_30_${currency}`;
        const currentPlan30DayPrice = parseFloat(c_plan[0][currentPlanPriceKey]);

        if (isNaN(currentPlan30DayPrice)) {
          console.log(`Invalid or missing price for key: ${currentPlanPriceKey}`);
          setCalculatedDiscount(null);
          return;
        }

        priceKey = `price_${days}_${currency}`;
      
        // Extract the price for the selected plan
        const totalPlanPrice = parseFloat(selectedPlan[priceKey]);
        if (isNaN(totalPlanPrice)) {
          console.error(`Invalid or missing price for key: ${priceKey}`);
          setCalculatedDiscount(null);
          return;
        }
      
        // Calculate the remaining days from the current plan
        const today = new Date();
        const planEndDate = new Date(endDate.replace(' ', 'T')); // ISO-compatible format
        const remainingDays = Math.max(0, Math.ceil((planEndDate - today) / (1000 * 60 * 60 * 24)));
      
        if (remainingDays === 0) {
          console.warn("No remaining days in the current plan. No discount applicable.");
          setCalculatedDiscount('');
          return;
        }
      
        // Calculate the per-day cost for the current plan using the 30-day price
        const perDayCost = currentPlan30DayPrice / 30;
      
        // Calculate the discount value
        const discountValue = remainingDays * perDayCost;
      
        //console.log("-----------------------------------------------------------------------------------------------------",discountValue);
        setCalculatedDiscount({
          symbol,
          value: discountValue, // Rounded to 2 decimal places
        });
      };


   
      
      const Discount = () =>{
        if(calculatedDiscount){

          return(
            <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, borderBottom: '1px solid #b8b8b8', padding: 10 }}>
              <div style={{ width: 300, textAlign: 'left', fontSize: 14, color: '#000' }}> Discount (Current plan remaining days) </div>
              <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 14, color: '#000',paddingRight:20 }}> -{calculatedDiscount.symbol} {parseFloat(calculatedDiscount.value).toFixed(2)} </div>
            </div>
          )
        }else{
          return(
            <></>
          )
        }

      }
      

      useEffect(()=>{

        let price;
        let newPrice;
        //baseprice
        if(basePrice){
          price = basePrice;
          
          if(calculatedDiscount){
            newPrice = parseFloat(price)-parseFloat(calculatedDiscount.value);
            price = newPrice;
           // console.log(price)
          }

          if(couponApplied && couponDiscount){
            const newp = parseFloat(price)*parseFloat(couponDiscount)/100;
            const newCalc = parseFloat(price)-parseFloat(newp);
            price = newCalc;
          }
            if(selfProfile){
              if(selfProfile.country === 'India'){
            
                if(selfProfile.state === 'Maharashtra'){
                  const cgstPerc = selfProfile.essentials.cgst;
                  const sgstPerc = selfProfile.essentials.sgst;
                  //console.log("cgst",JSON.stringify(selfProfile.essentials.cgst));
                  const cgst = parseFloat(price)*parseFloat(cgstPerc)/100;
                  const sgst = parseFloat(price)*parseFloat(sgstPerc)/100;
                  newPrice = parseFloat(price)+parseFloat(cgst)+parseFloat(sgst);
                  price = newPrice;
                }else{
                  const igstPerc = selfProfile.essentials.igst;
                  const igst = parseFloat(price)*parseFloat(igstPerc)/100;
                  newPrice = parseFloat(price)+parseFloat(igst);
                  price = newPrice;
                }

              }
            }
            if(parseFloat(price) < parseFloat(0)){
              setFinalPrice(0);              
            }else{
              setFinalPrice(parseFloat(price).toFixed(2));
            }
        }

      },[allPlans,currentPlan,selectedPlan,basePrice,selfProfile,couponDiscount]);

      const GrandTotal = () =>{
        let symbol, currency, priceKey;
        if (selfProfile.country === 'India') {
          symbol = '₹';
          currency = 'inr';
        } else if (selfProfile.country === 'United States') {
          symbol = '$';
          currency = 'usd';
        } else {
          symbol = '€';
          currency = 'eur';
        }

        return(
        <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, padding: 10 }}>
            <div style={{ width: 100, textAlign: 'left', fontSize: 18, color: '#000',fontWeight:'bold'}}>Total cost </div>
            <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 18, color: '#000',paddingRight:20,fontWeight:'bold' }}>{symbol} {finalPrice ? parseFloat(finalPrice).toFixed(2) : '0.00'} </div>
        </div>
        )
      }
      const handleCouponCode = (event) =>{        
        setCouponCode(event.target.value);
      }

      const verifyCouponCode = async ()=>{
        if(!couponCode){
          toaster.warning("Please type a coupon code to proceed", 2000);
          return;
        }
        const sp_unique = localStorage.getItem('sp_unique');
        const type = 1;
        const coupon = couponCode;
        const plan_id = planId;
        setTaskLoader(true);
        try{
          const dataString = {
            sp_unique,
            type,
            coupon,
            plan_id
          }
          console.log(dataString);
          const response = await fetch('https://access.myservicecircle.app/payment_gateway/verifyCouponCode.php',{
            method:'POST',
            headers:{
              'Content-Type':'application/json'
            },
            body:JSON.stringify(dataString)
          });


          const responseData = await response.json();
          console.log(responseData);

          if(responseData.success == 1){
            setCouponApplied(true);
            setCouponDiscount(responseData.discount);
          }else{
            toaster.danger(`Coupon code is invalid`, 2000);
          }
        }catch(error){

        }finally{
          setTaskLoader(false);
        }
      }

      const CouponDiscount = () =>{
        if(couponDiscount){
          let symbol, currency, priceKey;
          if (selfProfile.country === 'India') {
            symbol = '₹';
            currency = 'inr';
          } else if (selfProfile.country === 'United States') {
            symbol = '$';
            currency = 'usd';
          } else {
            symbol = '€';
            currency = 'eur';
          }
          
          let price;

          if(basePrice){
            const newP = parseFloat(basePrice)*parseFloat(couponDiscount)/100;
            price = newP;  
          }

          if(calculatedDiscount){
            const newpd = parseFloat(basePrice)-parseFloat(calculatedDiscount.value);
            const newCalc = parseFloat(newpd)*parseFloat(couponDiscount)/100;
            price = newCalc;
          }

          return(
            <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, borderBottom: '1px solid #b8b8b8', padding: 10 }}>
            <div style={{ width: 300, textAlign: 'left', fontSize: 14, color: '#000' }}> {couponDiscount}% Coupon code discount </div>
            <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 14, color: '#000',paddingRight:20 }}> -{symbol} {parseFloat(price).toFixed(2)} </div>
            </div>
          )

        }
      }

      const Razorpay = useRazorpay(); // Hook from react-razorpay
      const initiatePayment = async () => {
        const amount = finalPrice; // Convert to the smallest unit (paise for INR)
        const purpose = `Plan upgrade from ${currentPlan.name} to ${selectedPlan.name} for ${days} days`;
        const name = selfProfile.entity_name || 'no name';
        const email = selfProfile.email;
        const phone = selfProfile.phone;
        const user_id = localStorage.getItem('sp_unique');
        const plan_id = selectedPlan.plan_id;
        const currency = selfProfile.country === 'India' ? 'INR' : selfProfile.country === 'United States' ? 'USD' : 'EUR';
        const coupon = couponCode || null;
        const addDiscount = calculatedDiscount?.value || null;

        setTaskLoader(true);

        try {
            const dataString = {
                amount,
                purpose,
                name,
                email,
                phone,
                user_id,
                plan_id,
                currency,
                days,
                coupon,
                addDiscount
            };
            //alert(JSON.stringify(dataString));

            // Call backend to create order
            const response = await fetch('https://access.myservicecircle.app/payment_gateway/paymentUpgradeMain.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataString),
            });

            const result = await response.json();

            if (result.success == 1) {
              
                const options = {
                    key: result.razorpay_key, // Get Razorpay API Key from backend
                    amount: amount, // Amount in the smallest unit
                    currency: currency,
                    name: "MyServiceCircle.App",
                    description: purpose,
                    order_id: result.order_id, // Order ID from backend
                    handler: (response) => {
                        //console.log("Payment Success:", response);
                        //alert("Payment Successful!");
                        verifyPayment(user_id,result.order_id);
                    },
                    prefill: {
                        name: name,
                        email: email,
                        contact: phone,
                    },
                    theme: {
                        color: "#993366",
                    },
                };

                // **Using Razorpay correctly with `useRazorpay` Hook**
                const razorpayInstance = new window.Razorpay(options);
                new Promise((resolve, reject) => {
                  razorpayInstance.on("payment.success", (response) => {
                      console.log("Payment Success:", response);
                      resolve(response); // Resolve Promise on success
                  });
  
                  razorpayInstance.on("payment.failed", (response) => {
                      console.error("Payment Failed:", response);
                      reject(response); // Reject Promise on failure
                  });
  
                  razorpayInstance.open();
              })
              .then((response) => {
                  alert("Payment Successful!");
                  verifyPayment(user_id, response.order_id);
              })
              .catch((error) => {
                  alert("Payment Failed! Reason: " + error.error.description);
                  console.error("Payment Error:", error);
              });
  
         
            } else {
                console.error("Error in initiating payment:", result.error);
                alert(result.error || "Failed to initiate payment. Please try again.");
            }
        } catch (error) {
            console.error("Payment initiation error:", error);
        } finally {
            setTaskLoader(false);
        }
    };

    // Function to verify payment after success
    const verifyPayment = async (user_id, orderId) => {
        try {

          const dataString = {      
            status:'success',
            transaction_id: orderId,
            sp_unique:user_id,
            type: selectedPlan.type
        }
        const response = await fetch('https://access.myservicecircle.app/payment_gateway/paymentRedirectUpgrade.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(dataString),
            });

            const result = await response.json();

            if (result.success == 1) {
                
            } else {
                alert("Payment verification failed. Contact support.");
            }
        } catch (error) {
            console.error("Payment verification error:", error);
        }
    };

    

    return(
        <>
            <div style={{ display: 'flex', height: '100vh',backgroundColor:'rgba(255,255,255,0.20)'}}>
                    <div style={{width:'50%',height:'70vh',backgroundColor:'#fff',display:'flex',flexDirection:'column',padding:10,borderRadius:10,boxShadow:'0px 10px 5px rgba(0, 0, 0, 0.2'}} className='billDetails'>
                        {selectedPlan ?                        
                        <>

                          {!couponApplied ?
                          <>
                          <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
                          <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:12,color:'#000',textAlign:'center',flexDirection:'row',gap:10}}>
                          
                          <div style={{width:'auto',justifyContent:'center',alignItems:'center',display:'flex'}}>Got a coupon code?</div>
                          <a style={{width:'auto',justifyContent:'center',alignItems:'center',display:'flex',cursor:'pointer'}} onClick={()=>setCouponBox(true)}>Click here to apply</a>  
                          </div>
                          {couponBox &&
                          <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:12,color:'#000',textAlign:'center',flexDirection:'row',gap:10}}>
                            <TextInput name="text-input-name" placeholder="Type or pase your coupon code" style={{width:150,textAlign:'center'}} value={couponCode} onChange={handleCouponCode}/>
                            <span
                              onClick={verifyCouponCode}
                              style={{
                                cursor: 'pointer',
                                color: '#000',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <MdCheck style={{ color: '#000', fontSize: 20 }} />
                            </span>
                            <span
                              onClick={() => {setCouponBox(false);setCouponCode(null)}} // Clear the input field
                              style={{
                                cursor: 'pointer',
                                color: '#000',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <MdClose style={{ color: '#000', fontSize: 20 }} />
                            </span>
                          </div>
                          }
                          </div>
                          </>
                          :
                          <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',fontSize:12,color:'#000',textAlign:'center',flexDirection:'row',gap:10}}>
                            <div style={{width:'auto',justifyContent:'center',alignItems:'center',display:'flex'}}>Coupon code applied</div>
                            <MdCheck style={{fontSize:20,color:'green'}} />
                          </div>
                          }
                        
                        <div style={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',fontSize:12}}>Upgrading for {days ? `${days} days` : '...'} to</div>
                        <div style={{width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'center',fontSize:25,color:selectedPlan.color_code}}>{selectedPlan.name ? selectedPlan.name : 'loading'}</div>

                        <div style={{width: '95%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 12, borderBottom: '1px solid #b8b8b8', padding: 10 }}>
                            <div style={{ width: 100, textAlign: 'left', fontSize: 14, color: '#000' }}> Plan price </div>
                            <div style={{ width: 'auto',  textAlign: 'right',  fontSize: 14, color: '#000',paddingRight:20 }}> {calculateBasePrice()} </div>
                        </div>

                        {calculatedDiscount && calculatedDiscount.value !== '0.00' &&
                            <>
                              <Discount />
                            </>
                        }
                        {couponDiscount &&
                          <>
                            <CouponDiscount />
                          </>
                        }

                        {selfProfile && selfProfile.country === 'India' &&
                            <TaxInfo />
                        }

                        {finalPrice &&
                          <GrandTotal />
                        }

                        {finalPrice && finalPrice > 0 ?
                          <>
                            <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}} onClick={initiatePayment}>
                              <Button style={{backgroundImage:'linear-gradient(#363636, #19191a)',width:150,borderRadius:5,color:'#fff'}}>Make payment</Button>
                            </div>
                          </>
                          :
                          <>
                           <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                              <Button style={{backgroundImage:'linear-gradient(#363636, #19191a)',width:150,borderRadius:5,color:'#fff'}}>Upgrade for free!</Button>
                            </div>
                          </>
                        }
                        </>
                        :
                        <>
                        <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',height:'100%'}}>
                        <BeatLoader size={30} color={'#FCC100'} />
                        </div>
                        </>
                        }

                    </div>
            </div>
                 {taskLoader &&
                       
                        <div className='loaderBox'>
                          <CircleLoader color='#FCC100' size={30} />
                        </div>          
                       
                      }

                      <Dialog           
                        isShown={paymentSuccess}
                        title= {selectedPlan ? selectedPlan.name :''}
                        onCloseComplete={() => setPaymentSuccess(false)}
                        hasFooter={false} 
                        hasHeader={false}
                        width={'50%'}
                      >
                        <h3>Payment successful!.. You are being redirected.</h3>
                      </Dialog>
        </>
    )
}

export default PaymentPlanUpgrade;